import React from "react";
import Modal from "react-responsive-modal";
import rocket from "../assets/Image/rocket.png";

const VersionUpdateModal = ({ openModal, onCloseModal, updateVersion }) => {
  return (
    <>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "versionUpdation",
        }}
      >
        <div className="versionWrapper">
          <section>
            <img src={rocket} alt="rocket icon" />
          </section>
          <div className="textUpdate">
            <h4>Version Update Required?</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
          <div className="btnSection">
            <button className="btnUpdate" onClick={updateVersion}>
              Update
            </button>
            <p onClick={onCloseModal}>Skip</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VersionUpdateModal;
