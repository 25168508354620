import React, { useState } from "react";
import Modal from "react-responsive-modal";
import ResponsiveDateTimePickers from "../rightTabCommon/DateTimePicker";

const DatePicker = ({
  onClicks,
  item,
  opens,
  closeDataModal,
  keys,
  datetimeOnChange,
  selectedFolder,
  isDraftPreview,
  readOnly,
}) => {
  let dateData = new Date(item?.dateData);
  let formattedDate = !isNaN(dateData) ? dateData.toLocaleDateString() : "";
  let formattedTime = !isNaN(dateData) ? dateData.toLocaleTimeString() : "";

  const marginStyle = item.margin
    ? {
      margin: item.margin.all + "px",
      marginLeft: item.margin.left + "px",
      marginTop: item.margin.top + "px",
      marginRight: item.margin.right + "px",
      marginBottom: item.margin.bottom + "px",
    }
    : {};
  const paddingStyle = item.padding
    ? {
      padding: item.padding.all + "px",
      paddingLeft: item.padding.left + "px",
      paddingTop: item.padding.top + "px",
      paddingRight: item.padding.right + "px",
      paddingBottom: item.padding.bottom + "px",
    }
    : {};
  const radiusStyle = item.borderRadius
    ? {
      borderTopLeftRadius:
        item.borderRadius.topLeft + "px",
      borderTopRightRadius:
        item.borderRadius.topRight + "px",
      borderBottomLeftRadius:
        item.borderRadius.bottomLeft + "px",
      borderBottomRightRadius:
        item.borderRadius.bottomRight + "px",
    }
    : {};
  const borderWidthStyle = item.borderWidth
    ? {
      borderLeftWidth: item.borderWidth.left + "px",
      borderTopWidth: item.borderWidth.top + "px",
      borderRightWidth: item.borderWidth.right + "px",
      borderBottomWidth:
        item.borderWidth.bottom + "px",
    }
    : {};

  const width = parseInt(item.size.width);
  const height = parseInt(item.size.height);
  const min_height = parseInt(item.size?.min_height);
  const min_width = parseInt(item.size?.min_width);
  const max_height = parseInt(item.size?.max_height);
  const max_width = parseInt(item.size?.max_width);
  const widthUnit = "px";
  const heightUnit = "px";
  const min_heightunit = "px";
  const min_widthunit = "px";
  const max_heightunit = "px";
  const max_widthunit = "px";

  return (
    <>
      <div
        className="datepicker_wrapper"
        onClick={() => onClicks(item)}
        key={keys}
        style={{
          zIndex: "10",
          width: `${width}${widthUnit}`,
          height: `${height}${heightUnit}`,
          minHeight: `${min_height}${min_heightunit}`,
          minWidth: `${min_width}${min_widthunit}`,
          maxHeight: `${max_height}${max_heightunit}`,
          maxWidth: `${max_width}${max_widthunit}`,
          cursor: isDraftPreview ? "pointer" : "default", // Set cursor style dynamically
          borderColor: item.id
            ? opens === true
              ? item?.activeBorderColor
              : item.borderColor
            : "#000",
          borderStyle: item.id ? item.borderStyle : "solid",
          ...marginStyle,
          ...paddingStyle,
          ...borderWidthStyle,
          borderWidth:
            item?.borderWidth.all > 0
              ? item.borderWidth.all + "px"
              : borderWidthStyle,
          borderRadius:
            item?.borderRadius.all > 0
              ? item.borderRadius.all + "px"
              : radiusStyle,
        }}
      >
        <div className="datepicker_main">
          {item?.labelShowHide === "show" && (
            <label
              htmlFor=""
              className="datepicker_label"
              style={{
                color: item?.labelColor,
                fontSize: `${item?.labelSize}px`,
              }}
            >
              Date
            </label>
          )}
          <div className="datepicker_datetime">
            <span
              className="datepicker_time "
              style={{
                zIndex: "10",
                cursor: isDraftPreview ? "pointer" : "default",
                color: item.id ? item.color : null,
                fontSize: item.id ? `${item.fontSize}px` : "12px",
                lineHeight: item.id ? `${item.lineHeight}px` : "12px",
                letterSpacing: item.id ? `${item.letterSpacing}px` : null,
                fontFamily: item.id ? item.fontFamily : null,
                textAlign: item.id ? item.textAlign : null,
                fontWeight: item.id ? item.fontWeight : null,
                textDecoration: item.id ? item.textdecoration : null,
                textTransform: item.id ? item.textTransform : null,
              }}
            >
              {item?.showType && item?.showType === "date" && formattedDate}
              {item?.showType && item?.showType === "time" && formattedTime}
              {item?.showType &&
                item?.showType === "dateTime" &&
                `${formattedDate},${formattedTime}`}
            </span>
          </div>
        </div>
      </div>

      <>
        <ResponsiveDateTimePickers
          data={datetimeOnChange}
          open={opens}
          onClose={closeDataModal}
          item={item}
          selectedFolder={selectedFolder}
        />
      </>
    </>
  );
};

export default DatePicker;
