import React, { useState } from "react";

const SaveBlockPopup = ({ onSave, onClose, listItemRef, popupPosition }) => {
  const [blockName, setBlockName] = useState("");

  const handleSave = () => {
    onSave(blockName);
    setBlockName("");
  };
  // Function to check if the input field is empty
  const isInputEmpty = blockName.trim() === "";

  return (
    <div
      className="save_custom_popup"
      style={{
        position: "absolute",
        top: popupPosition.top,
        zIndex: 1000,
        marginTop: "1.2rem",
        marginRight: "16px",
        width: "250px",
        right: "0",
      }}
    >
      <h5>Custom Block</h5>
      <p>Save this element and its children as a custom block</p>
      <input
        type="text"
        value={blockName}
        onChange={(e) => setBlockName(e.target.value)}
        placeholder="Type a name..."
      />
      <div className="view_btn_sec">
        <button
          onClick={handleSave}
          disabled={isInputEmpty}
          className={`save_input_data ${isInputEmpty ? "disabled" : ""}`}
        >
          Save
        </button>
        <button onClick={onClose} className="cancel_popup">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SaveBlockPopup;
