import {
  ADD_NEW_SCREEN,
  GET_MY_SCREEN,
  SET_DEFAULT_VALUES,
  SET_DELETE_MY_NEW_ASSETS,
  SET_MY_ASSETS,
  SET_MY_NEW_ASSETS,
  SET_SELECTED_CANVAS,
  SET_SAVE_BLOCK,
  SET_NEW_SAVE_BLOCK,
  SET_DELETE_NEW_SAVE_BLOCK,
  SET_DATA_BLOCK,
  RESET_CANVAS_DATA,
  SAVE_CANVAS_DATA,
  SET_PAGE_LOADING,
  GET_MY_SCREEN_DATA,
  DELETE_MY_SCREEN,
  EDIT_MY_SCREEN_DATA,
  SET_MYNEWDATA2,
  SET_MYNEWDATA,
  SET_DUPLICATED_FOLDER,
  SET_DIV_WIDTH_HEIGHT,
  SET_FOLDER_WIDTH_HEIGHT,
  UPDATE_CANVAS_BACKGROUND,
  SET_GET_STYLE_DATA,
  SET_NEW_STYLE_DATA,
  SET_GET_VERSION_LIST,
  SET_ADD_PUBLISH_SCREEN,
  SET_DELETE_VERSION,
  SET_SDK_VERSION_LIST,
  SET_MY_SDK_VERSION_DATA,
  SET_SDK_VERSION_RESOURCE_LIST,
  GET_MY_SCREENMODAL_DATA,
  SET_MY_SDK_VERSION_DATA_DASHBOARD,
} from "../types";

import { toast } from "react-toastify";
import { axiosInstance, axiosInstance2 } from "../axiosInstance";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
};

export const setPageLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_PAGE_LOADING,
    payload: data,
  });
};
export const setFolderStructure = (data) => (dispatch) => {
  dispatch({
    type: SET_MYNEWDATA,
    payload: data,
  });
};
export const setSelectedFolder = (data) => (dispatch) => {
  dispatch({
    type: SET_MYNEWDATA2,
    payload: data,
  });
};

export const setDefaultValues = (data) => (dispatch) => {
  dispatch({
    type: SET_DEFAULT_VALUES,
    payload: data,
  });
};

export const saveCanvasData = (canvasId, data) => (dispatch) => {
  dispatch({
    type: SAVE_CANVAS_DATA,
    payload: { canvasId, data },
  });
};
export const setSelectedCanvas = (canvas) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CANVAS,
    payload: canvas,
  });
};

// export const setSelectedCanvas = (canvas) => ({
//   type: SET_SELECTED_CANVAS,
//   payload: canvas,
// });

export const updateCanvasBackground = (color) => ({
  type: UPDATE_CANVAS_BACKGROUND,
  payload: color,
});

// export const addScreenList = (id, data) => (dispatch) => {
//   dispatch(setPageLoading(true));
//   axiosInstance
//     .post(`addscreendata/${id}`, data, config)
//     .then((res) => {
//       if (res.status) {
//         toast.success("Screen Added Successfully");
//         dispatch({
//           type: ADD_NEW_SCREEN,
//           payload: res.data,
//         });
//         dispatch(setPageLoading(false));
//       }
//     })
//     .catch((error) => {
//       if (error.response) {
//         console.error("Error sending data to API:", error);
//         toast.error(error.response.data.message);
//       }
//     });
// };

export const addScreenList = (id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setPageLoading(true));
    axiosInstance
      .post(`addscreendata/${id}`, data, config)
      .then((res) => {
        if (res.status) {
          toast.success("Screen Added Successfully");
          dispatch({
            type: ADD_NEW_SCREEN,
            payload: res.data,
          });
          dispatch(setPageLoading(false));

          resolve(res.data); // Resolve with the response data
        } else {
          reject(new Error("Failed to add screen")); // Reject with an error
        }
      })
      .catch((error) => {
        dispatch(setPageLoading(false));
        console.error("Error sending data to API:", error);
        toast.error(error.response?.data?.message || "Failed to add screen");
        reject(error); // Reject with the error
      });
  });
};

export const editScreenData =
  (projectId, screen_Id, data2, data) => (dispatch) => {
    axiosInstance
      .patch(`editscreen/${projectId}/${screen_Id}/${data2}`, data, config)
      .then((res) => {
        if (res.status) {
          toast.success(res?.data?.message);
          dispatch({
            type: EDIT_MY_SCREEN_DATA,
            payload: res.data,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error sending data to API:", error);
          toast.error(error.response.data.message);
        }
      });
  };

export const getScreenData = (projectId, screen_Id, id) => (dispatch) => {
  axiosInstance
    .get(
      id
        ? `screen/${projectId}/${screen_Id}/${id}`
        : `screen/${projectId}/${screen_Id}`,
      config
    )

    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_MY_SCREEN_DATA,
          payload: res.data.data.screendata,
        });
        dispatch({
          type: SET_MYNEWDATA,
          payload: res.data.data.screendata,
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching screen data:", error);
    });
};
export const getScreenModal = (projectId, screen_Id) => (dispatch) => {
  axiosInstance
    .get(`screen/${projectId}/${screen_Id}`, config)

    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_MY_SCREENMODAL_DATA,
          payload: res.data.data.screendata,
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching screen data:", error);
    });
};

export const deleteScreen =
  (projectId, screen_Id, setFolderStructure) => (dispatch) => {
    dispatch(setPageLoading(true));
    axiosInstance
      .delete(`screen/${projectId}/${screen_Id}`)
      .then((res) => {
        if (res) {
          dispatch({
            type: DELETE_MY_SCREEN,
            payload: res.data,
          });
          setFolderStructure([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching screen data:", error);
      });
  };

export const getMyScreenList = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  axiosInstance
    .get(`project/${id}`, config)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: GET_MY_SCREEN,
          payload: res.data,
        });
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      console.error("Error fetching screen data:", error.message);
    });
};

export const getMyAllAssets = (id) => (dispatch) => {
  axiosInstance.get(`project/assets/${id}`, config).then((res) => {
    if (res.status) {
      dispatch({
        type: SET_MY_ASSETS,
        payload: res.data.images,
      });
    }
  });
};

export const addMyAllAssests = (data) => (dispatch) => {
  axiosInstance.post("project/assets", data, config).then((res) => {
    if (res.status) {
      toast.success(res.data.message);
      dispatch({
        type: SET_MY_NEW_ASSETS,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

export const deleteMyAllAssests = (project, data) => (dispatch) => {
  axiosInstance
    .delete(`project/assets/${project}/${data}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DELETE_MY_NEW_ASSETS,
          payload: res.data,
        });
      } else {
        toast.error(res.data.message);
      }
    });
};

// API integration for Save Custom Block

export const getSaveBlock = (id) => (dispatch) => {
  axiosInstance
    .get(`blocks/${id}`, config)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: SET_SAVE_BLOCK,
          payload: res.data.data.customBlocks,
        });
      }
    })
    .catch((error) => {
      toast.error("Error occurred while getting blocks");
      dispatch(setPageLoading(false));
    });
};

export const AddSaveBlock = (id, data) => (dispatch) => {
  axiosInstance
    .post(`blocks/${id}`, data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_NEW_SAVE_BLOCK,
          payload: res.data,
        });
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      toast.error("Error occurred while adding blocks");
      dispatch(setPageLoading(false));
    });
};

export const deleteMySaveBlock = (data, id) => (dispatch) => {
  axiosInstance
    .delete(`blocks/${data}/${id}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DELETE_NEW_SAVE_BLOCK,
          payload: res.data,
        });
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      console.error("Error in deleting blocks:", error);
      toast.error("Error occurred while deleting blocks");
      dispatch(setPageLoading(false));
    });
};

export const DataBlockMap = (res) => (dispatch) => {
  dispatch({
    type: SET_DATA_BLOCK,
    payload: res.data,
  });
};

export const setDuplicatedFolder = (duplicatedFolder) => ({
  type: SET_DUPLICATED_FOLDER,
  payload: duplicatedFolder,
});

export const setFolderWidthHeight = (folderId, width, height) => {
  return {
    type: "SET_FOLDER_WIDTH_HEIGHT",
    payload: { folderId, width, height },
  };
};

export const addMyStyleData = (id, data) => (dispatch) => {
  axiosInstance
    .post(`/addstyles/${id}`, data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_NEW_STYLE_DATA,
          payload: res.data,
        });
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      toast.error("Error occurred while Saving Styles");
    });
};

export const getMySavedStyles = (id) => (dispatch) => {
  axiosInstance
    .get(`/styles/${id}`, config)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: SET_GET_STYLE_DATA,
          payload: res.data.data.styles,
        });
      }
    })
    .catch((error) => {
      dispatch(setPageLoading(false));
    });
};

export const getVersionList = (data) => (dispatch) => {
  axiosInstance
    .get(`/screenversion/${data?.p_id}/${data?.s_id}`, config)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: SET_GET_VERSION_LIST,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      dispatch(setPageLoading(false));
    });
};

export const publishMyScreen = (item, data) => (dispatch) => {
  axiosInstance
    .post(`/publishscreen/${item?.p_id}/${item?.s_id}`, data, config)
    .then((res) => {
      if (res.status) {
        toast.success("New version published");

        dispatch({
          type: SET_ADD_PUBLISH_SCREEN,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      dispatch(setPageLoading(false));
    });
};

export const deleteVersion = (data, id, v_id) => (dispatch) => {
  axiosInstance
    .delete(`deletescreen/${data}/${id}/${v_id}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DELETE_VERSION,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      console.log(error.message);
    });
};

// below are SDK version Apis//

export const getSdkVersionList = (id) => (dispatch) => {
  axiosInstance
    .get(`sdkversions/${id}`, config)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: SET_SDK_VERSION_LIST,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      dispatch(setPageLoading(false));
    });
};

export const getSdkVersionResourceList = (id) => (dispatch) => {
  axiosInstance
    .get(id ? `sdkversionresource/${id}` : `sdkversionresource`, config)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: SET_SDK_VERSION_RESOURCE_LIST,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      dispatch(setPageLoading(false));
    });
};

export const updateMySdkVersion = (id, version) => (dispatch) => {
  axiosInstance
    .patch(`updateprojectsdkversion/${id}/${version}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res?.data?.message);
        dispatch({
          type: SET_MY_SDK_VERSION_DATA,
          payload: res?.data?.message,
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
      }
    });
};
export const updateMySdkVersionDashboard =
  (data, setOpenModal) => (dispatch) => {
    axiosInstance
      .patch(`updateappsdk?sdkversion=${data}`, data, config)
      .then((res) => {
        if (res.status) {
          toast.success(res?.data?.message);
          dispatch({
            type: SET_MY_SDK_VERSION_DATA_DASHBOARD,
            payload: res?.data?.message,
          });

          localStorage.setItem("userSdkVersion", res?.data?.version);
          setOpenModal(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error sending data to API:", error);
        }
      });
  };
