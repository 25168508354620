import {
  SET_BTN_LOADING,
  SET_DELETE_MY_PROJECT,
  SET_MY_NEW_PROJECT,
  SET_MY_PROJECTS,
  SET_PAGE_LOADING,
  SET_NEW_SIGNUP_USER,
  SET_CANVAS_DATA,
  SET_NEW_LOGIN_USER,
  SET_PROFILE_USER,
  FONT_ICON_TYPE,
  UPDATE_PROFILE_USER,
  DELETE_PROFILE_USER,
  SET_DUPLICATE_PROJECT,
  SET_MY_PROJECT_SCREEN_LIST,
} from "../types";

const initialState = {
  btnLoading: false,
  pageLoading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BTN_LOADING:
      return {
        ...state,
        btnLoading: action.payload,
      };
    case SET_PAGE_LOADING:
      return {
        ...state,
        pageLoading: action.payload,
      };
    case SET_MY_PROJECTS:
      return {
        ...state,
        myAllProjectList: action.payload,
      };
    case SET_MY_NEW_PROJECT:
      return {
        ...state,
        newProjectCreated: action.payload,
      };
    case SET_DELETE_MY_PROJECT:
      return {
        ...state,
        deleteMyProject: action.payload,
      };
    case SET_NEW_SIGNUP_USER:
      return {
        ...state,
        addNewUser: action.payload,
      };
    case SET_NEW_LOGIN_USER:
      return {
        ...state,
        loginDetails: action.payload,
      };

    case SET_CANVAS_DATA:
      return {
        ...state,
        addCanvasText: action.payload,
      };
    case SET_PROFILE_USER:
      return {
        ...state,
        getProfile: action.payload,
      };
    case UPDATE_PROFILE_USER:
      return {
        ...state,
        updateProfileData: action.payload,
      };
    case DELETE_PROFILE_USER:
      return {
        ...state,
        deleteProfile: action.payload,
      };
    case FONT_ICON_TYPE:
      return {
        ...state,
        getMyFontList: action.payload,
      };
    case SET_DUPLICATE_PROJECT:
      return {
        ...state,
        addDuplicateProject: action.payload,
      };
    case SET_MY_PROJECT_SCREEN_LIST:
      return {
        ...state,
        showAllMyScrrens: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
