import React from "react";

const TextFieldSvg = ({ change }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.1 9.45625C4.27723 9.45625 3.6125 10.121 3.6125 10.9438V22.8438C3.6125 23.6665 4.27723 24.3313 5.1 24.3313H28.9C29.7228 24.3313 30.3875 23.6665 30.3875 22.8438V10.9438C30.3875 10.121 29.7228 9.45625 28.9 9.45625H5.1ZM2.125 10.9438C2.125 9.30285 3.4591 7.96875 5.1 7.96875H28.9C30.5409 7.96875 31.875 9.30285 31.875 10.9438V22.8438C31.875 24.4846 30.5409 25.8188 28.9 25.8188H5.1C3.4591 25.8188 2.125 24.4846 2.125 22.8438L2.125 10.9438ZM10.3062 12.4313C10.6084 12.4313 10.8827 12.6172 10.9989 12.9007L13.9739 20.3382C14.1273 20.7194 13.9413 21.1517 13.5602 21.3051C13.179 21.4585 12.7467 21.2726 12.5933 20.8914L12.1842 19.8688H12.1656H8.44687H8.42828L8.01922 20.8868C7.86582 21.2679 7.43352 21.4539 7.05234 21.3005C6.67117 21.1471 6.48523 20.7148 6.63863 20.3336L9.61363 12.8961C9.72519 12.6125 9.99945 12.4266 10.3062 12.4266V12.4313ZM10.3062 15.1785L9.02328 18.3813H11.5846L10.3062 15.1785ZM15.5125 13.175C15.5125 12.7659 15.8472 12.4313 16.2563 12.4313H18.8594C20.2957 12.4313 21.4625 13.598 21.4625 15.0344C21.4625 15.5968 21.2859 16.1175 20.9791 16.5451C21.7182 17.0053 22.2062 17.8234 22.2062 18.7531C22.2062 20.1895 21.0395 21.3563 19.6031 21.3563H16.2563C15.8472 21.3563 15.5125 21.0216 15.5125 20.6125V19.8688V16.8938V13.9188V13.175ZM18.8594 17.6375H17V19.8688H19.6031C20.2214 19.8688 20.7188 19.3714 20.7188 18.7531C20.7188 18.1349 20.2214 17.6375 19.6031 17.6375H18.8594ZM18.8594 16.15C19.4776 16.15 19.975 15.6526 19.975 15.0344C19.975 14.4161 19.4776 13.9188 18.8594 13.9188H17V16.15H18.8594Z"
          fill={change ? "#fff" : "#3c4c6e"}
        />
      </svg>
    </div>
  );
};

export default TextFieldSvg;
