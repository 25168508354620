import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import Modal from "react-responsive-modal";

const StyleSheet = ({ styleSheetModal, closeDataModal }) => {
  const { selectedFolder } = useSelector((state) => state.default);

  const keysToShowText = [
    "name",
    "color",
    "fontFamily",
    "fontSize",
    "textdecoration",
    "textTransform",
    "lineHeight",
    "letterSpacing",
    "borderStyle",
    "borderColor",
  ];
  const keysToShowImage = ["name", "borderStyle", "borderColor"];
  const keysToShowView = [
    "direction",
    "justify",
    "align",
    "alignContent",
    "alignitem",
    "background",
    "display",
    "flexWrap",
  ];
  return (
    <div>
      <div>
        <Modal
          open={styleSheetModal}
          onClose={closeDataModal}
          center
          classNames={{
            modal: "api_cloud_assets_modal",
          }}
        >
          <div className="top_head">
            <h3>Stylesheets</h3>
          </div>
          <div className="body_sec_stylesheet">
            <div className="left_bar_sheet">Stylesheet</div>
            <div className="main_bar_sheet">
              <div className="api_duplicateBtns">
                <div className="api_services">
                  <h5>
                    {selectedFolder?.name}
                    <span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="pen"
                        className="svg-inline--fa fa-pen "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="15px"
                        height="15px"
                      >
                        <path
                          fill="currentColor"
                          d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"
                        ></path>
                      </svg>
                    </span>
                  </h5>
                  <h6>Stylesheet for Text components.</h6>
                  <p>This stylesheet is used by 1 component on 1 screen</p>
                </div>
                <div className="dupliBtns">
                  <Button>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="clone"
                      className="svg-inline--fa fa-clone "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="15px"
                      height="15px"
                    >
                      <path
                        fill="currentColor"
                        d="M288 448H64V224h64V160H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64zm-64-96H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64z"
                      ></path>
                    </svg>
                    Duplicate
                  </Button>
                  <Button>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="trash"
                      className="svg-inline--fa fa-trash "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="15px"
                      height="15px"
                    >
                      <path
                        fill="currentColor"
                        d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                      ></path>
                    </svg>
                    Delete
                  </Button>
                </div>
              </div>
              <div className="tableData">
                <table className="table custom-table custom-table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Style Name</th>
                      <th scope="col">Value</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedFolder.type === "text" &&
                      keysToShowText.map((key, index) => (
                        <tr key={key}>
                          <td>{index + 1}</td>
                          <td>{key}</td>
                          <td>{JSON.stringify(selectedFolder[key])}</td>
                          <td></td>
                        </tr>
                      ))}
                    {selectedFolder.type === "image" &&
                      keysToShowImage.map((key, index) => (
                        <tr key={key}>
                          <td>{index + 1}</td>
                          <td>{key}</td>
                          <td>{JSON.stringify(selectedFolder[key])}</td>
                          <td></td>
                        </tr>
                      ))}
                    {selectedFolder.type === "view" &&
                      keysToShowView.map((key, index) => (
                        <tr key={key}>
                          <td>{index + 1}</td>
                          <td>{key}</td>
                          <td>{JSON.stringify(selectedFolder[key])}</td>
                          <td></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="done_btn">
            <div className="main_btn">
              <button className="btn_close" onClick={closeDataModal}>
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default StyleSheet;
