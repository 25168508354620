import React from "react";

const AccountFuture = () => {
  return (
    <div className="coustom_container">
      <div className="f_section">
        <div className="icon_box">
          <img src="/assets/Image/circleicon.svg" alt="" />
        </div>
        <div className="f_box">
          <div className="f_max_withd">
            <span>Future</span>
            <h2>All Your Accounts Are In One Place</h2>
            <p>
              But I must explain to you how all this mistaken idea of denouncing
              pleasure and praising pain was born and I will give you a complete
              account of the system, and expound great explorer of the truth,
              the master-builder of human pleasure, but because those who do
              not.
            </p>
            <p>
              But I must explain to you how all this mistaken idea of denouncing
              pleasure and praising pain was born and I will give you a complete
              account
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountFuture;
