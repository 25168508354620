import React, { useState } from "react";
import info_icon from "../../../../assets/Icons/info_icon.svg";
import Switch from "react-switch";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import InformationIcon from "../../../../assets/Iconcomponent/InformationIcon";

const BasicComponent = ({
  folderValue,
  handleChange,
  handleSave,
  selectedFolder,
  estSizeChangeHandler,
  scrollableChangeHandler,
  setChecked,
  checked,
  handleColumnChange,
  gridColumValue,
  handleme,
  handleDisplayGrid,
  handleDisplayGridRow,
  dateTpye,
  buttonType,
  typeIcon,
  dateTimeTypeChangeHandler,
  selectedCategorie,
  setSelectedCategorie,
  showTooltipData,
  handleIconClick,
  tooltipContent,
  setTooltipContent,
  ToolTipBox,
}) => {
  const styleWidth = {
    control: (styles) => ({
      ...styles,
      backgroundColor: " #1A237E",
      borderRadius: "5px",
      width: "100%",
      border: "none",
      color: "#fff",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1A237E",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  #1A237E" : "#1A237E",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "White",
      fontSize: "12px",
    }),
  };

  const options = [
    { value: "Flashlist", label: "Flashlist" },
    { value: "Flashlist", label: "Flashlist" },
    { value: "Flashlist", label: "Flashlist" },
  ];

  const ReverseSwitch = ({ checked, handleColumnChange, setChecked }) => {
    return (
      <label>
        <Switch
          checked={checked}
          onColor="#3366ff"
          onHandleColor="#2693e6"
          handleDiameter={29}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          className="react-switch"
          id="material-switch"
          onChange={handleColumnChange}
        />
      </label>
    );
  };

  const handleColumnChanges = () => { };
  return (
    <div className="scroll-sec">
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Basic</Accordion.Header>
          <Accordion.Body>
            <div className="comp-1">
              <div className="flex-p-img">
                <p>Component Name</p>

                <ToolTipBox
                  type="Component Name"
                  iconType="green"
                  handleIconClick={handleIconClick}
                  showTooltipData={showTooltipData}
                  tooltipContent={tooltipContent}
                />
              </div>

              <input
                type="text"
                value={folderValue?.name || ""}
                onChange={handleChange}
                onBlur={handleSave}
                name="name"
              // onBlur={"handleSave"}
              />
            </div>

            {selectedFolder?.type === "datepicker" && (
              <>
                <div className="Basic-1">
                  <div className="p-info-flex">
                    <p>Mode</p>

                    <ToolTipBox
                      type="Mode"
                      iconType="green"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </div>
                  <div className="drop-time">
                    <select onChange={dateTimeTypeChangeHandler}>
                      <option value="date">date</option>
                      <option value="time">time</option>
                      <option value="dateTime">dateTime</option>
                    </select>
                  </div>
                </div>

                <div className="Basic-2">
                  <div className="p-icon">
                    <p>Assistive text</p>

                    <ToolTipBox
                      type="Assistive text"
                      iconType="green"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </div>
                  <div className="input-basic">
                    <input
                      type="text"
                      value={selectedFolder?.est_item_size || ""}
                      name="est_item_size"
                    />
                  </div>
                </div>

                <div className="Basic-3">
                  <div className="p-auto">
                    <p>Auto dismiss KeyBoard</p>
                    <ToolTipBox
                      type="Auto dismiss KeyBoard"
                      iconType="green"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />{" "}
                  </div>
                  <div>
                    <Switch
                      onColor="#3366ff"
                      onHandleColor="#2693e6"
                      handleDiameter={29}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>

                <div className="Basic-2">
                  <div className="p-icon">
                    <p>Format</p>
                    <ToolTipBox
                      type="Format"
                      iconType="green"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </div>
                  <div className="input-basic">
                    <input
                      type="text"
                      value={selectedFolder?.est_item_size || ""}
                      name="est_item_size"
                    />
                  </div>
                </div>
              </>
            )}

            { }

            {selectedFolder?.type === "image" && (
              <>
                <div className="Basic-1">
                  <div className="p-info-flex">
                    <p>Resize Mode</p>

                    <ToolTipBox
                      type="Mode"
                      iconType="green"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </div>
                  <div className="drop-time">
                    <select

                    // onChange={dateTimeTypeChangeHandler}
                    >
                      <option value="date">cover</option>
                      <option value="time">contain</option>
                      <option value="dateTime">stretch</option>
                      <option value="dateTime">repeat</option>
                      <option value="dateTime">center</option>
                    </select>
                  </div>
                </div>
              </>
            )}

            {selectedFolder && selectedFolder?.type === "flashlist" && (
              <>
                { }
                <div className="comp-1">
                  <div className="flex-p-img">
                    <p>Horizontal</p>

                    <ToolTipBox
                      iconType="green"
                      type="Horizontal"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </div>
                  <div className="toggle-btn">
                    <Switch
                      checked={checked}
                      onChange={(e) => {
                        setChecked(!checked);
                        handleColumnChange(e, "row");
                      }}
                      onColor="#3366ff"
                      onHandleColor="#2693e6"
                      handleDiameter={29}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>
                { }
                <div className="comp-1">
                  <div className="flex-p-img">
                    <p>Number of Column</p>

                    <ToolTipBox
                      type="Number of Column"
                      iconType="green"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </div>
                  <input
                    type="number"
                    value={gridColumValue}
                    onFocus={handleme}
                    onChange={handleDisplayGrid}
                  />
                </div>
                { }
              </>
            )}

            {selectedFolder && selectedFolder?.type === "view" && (
              <>
                { }
                <div className="comp-1">
                  <div className="flex-p-img">
                    <p>Scrollable</p>

                    <ToolTipBox
                      iconType="green"
                      type="Scrollable"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </div>
                  <div className="toggle-btn">
                    <Switch
                      checked={checked}
                      value={selectedFolder?.scrollable || false}
                      name="scrollable"
                      onChange={(e) => {
                        setChecked(!checked);
                        scrollableChangeHandler(e);
                      }}
                      onColor="#3366ff"
                      onHandleColor="#2693e6"
                      handleDiameter={29}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>
                { }

                { }
              </>
            )}

            {selectedFolder?.type === "button" && (
              <div className="buttons-1">
                <div className="long-press-img">
                  <p>Delay Long Press </p>

                  <ToolTipBox
                    iconType="green"
                    type="Delay Long Press"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />
                </div>
                <div className="input-button">
                  <input
                    type="text"
                    value={""}
                    name="est_item_size"
                    placeholder="auto"
                  />
                </div>
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <div className="tlg-btn-tab-2">
      <ReverseSwitch />
      <p>Log action in this chain</p>
    </div> */}
    </div>
  );
};

export default BasicComponent;
