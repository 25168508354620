import React from "react";

const Duplicate = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.83317 15.5824H11.3332C11.7089 15.5824 12.0692 15.4331 12.3349 15.1674C12.6006 14.9017 12.7498 14.5414 12.7498 14.1657V5.66569C12.7498 5.28997 12.6006 4.92963 12.3349 4.66396C12.0692 4.39828 11.7089 4.24902 11.3332 4.24902H2.83317C2.45745 4.24902 2.09711 4.39828 1.83144 4.66396C1.56576 4.92963 1.4165 5.28997 1.4165 5.66569V14.1657C1.4165 14.5414 1.56576 14.9017 1.83144 15.1674C2.09711 15.4331 2.45745 15.5824 2.83317 15.5824ZM4.24984 9.20736H6.37484V7.08236H7.7915V9.20736H9.9165V10.624H7.7915V12.749H6.37484V10.624H4.24984V9.20736Z"
        fill="#283249"
      />
      <path
        d="M14.1665 1.41602H5.6665V2.83268H14.1665V11.3327H15.5832V2.83268C15.5832 2.05139 14.9478 1.41602 14.1665 1.41602Z"
        fill="#283249"
      />
    </svg>
  );
};

export default Duplicate;
