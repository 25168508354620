import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Route, Routes } from "react-router-dom";
const FigmaCloneDoc = () => {
  return (
    <>
      <div>
        {" "}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Welcome to Figma Clone</h1>
        </div>
        <div>
          <p className="clone_para">
            Welcome! There's a lot you can accomplish inside of Figma Clone and
            there's also a lot to learn about it. Our documentation, guides, and
            team are all here to help you as you build your app. Let's get
            started.
          </p>
          <h2 id="before-you-build" className="clone_head">
            Before you Build
          </h2>
          <h5 className="clone_heading">Get acquainted with the Dashboard</h5>
          <p className="clone_para">
            Check out the Dashboard&nbsp;<a href="#">Overview page</a>&nbsp;to
            get yourself familiarized with Figma Clone's Dashboard interface.
          </p>
          <h5 className="clone_heading">
            Make sure you have Live Preview app on your phone
          </h5>
          <p className="clone_para">
            The in-browser Web Preview is a great way to get an idea of what
            your app looks like but Live Preview app will give you the most
            accurate look (and several features/components require it). For more
            guidance on getting set up, make sure to check out the&nbsp;
            <a href="#">Live Preview doc</a>.
          </p>
          <h5 className="clone_heading">Join the Community</h5>
          <p className="clone_para">
            Use your Figma Clone account to create an account in our&nbsp;
            <a href="#">Community</a>. No additional sign-up is required. The
            Community is the place to ask questions, learn from tutorials and
            receive product updates.
          </p>
          <h2 className="clone_head" id="start-building">
            Start Building!
          </h2>
          <h5 className="clone_heading">
            &nbsp;Get acquainted with the Builder
          </h5>
          <p className="clone_para">
            Take a look at the <a href="#">Introduction to the Builder</a>{" "}
            &nbsp;article to familiarize yourself with the main sections of the
            Builder and learn more about what you can do inside of Figma Clone.
          </p>
          <h5 className="clone_heading">Learn about Flexbox</h5>
          <p className="clone_para">
            Using Flexbox is important in creating the perfect, responsive
            layout for your app. If you're not familiar with Flexbox, be sure to
            read up on all the different properties in the{" "}
            <a href="#">Layout</a>article.
          </p>
          <h5 className="clone_heading">
            {" "}
            Learn what you can do with Components
          </h5>
          <p className="clone_para">
            In the pages under the
            <a href="#">Components</a>section, you can read about all the
            different ways you can add and customize your components (and
            <a href="#">&nbsp;even create your own</a>).
          </p>
          <h5 className="clone_heading">Add advanced functionality</h5>
          <p className="clone_para">
            Once your UI is built, you can connect your screens with&nbsp;
            <a href="#">Navigation</a>&nbsp;and add a&nbsp;<a href="#">Data</a>
            &nbsp;source to your app. Reference the&nbsp;
            <a href="#">Integrations</a>&nbsp;page for instructions on setting
            up specific APIs. To enable experiences Expo/React Native supports
            but Draftbit doesn't quite yet, try out our
            <a>Custom Code</a>
            features
            <a href="#">Layout</a>article.
          </p>
          <h2 className="clone_head" id="add-advanced-functionality">
            Add Advanced Functionality
          </h2>
          <p className="clone_para">
            Learn about Flexbox Using Flexbox is important in creating the
            perfect, responsive layout for your app. If you're not familiar with
            Flexbox, be sure to read up on all the different properties in the{" "}
            &nbsp;<a href="#">Layout</a>&nbsp;article.
          </p>
        </div>
      </div>
    </>
  );
};

export default FigmaCloneDoc;
