import React from "react";
import {
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  Copyright,
} from "@mui/icons-material";
import facebook from "../../assets/Image/facebook.svg";
import linkined from "../../assets/Image/linkined.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="main-footer">
        <div className="coustom_container">
          <div className="inner">
            <div className="left-footer">
              <div className="logo-sec">
                <h3>Stay in Touch</h3>
                <p>
                  Lorem ipsum dolor sit amet. Sit rerum molestias et expedita
                  omnis id rerum enim sit dolores sunt.
                  <br /> Vel esse veritatisnt.
                </p>
                <div className="social-sec">
                  <ul className="social-list">
                    <li>
                      <Link>
                        <img src={facebook} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <Twitter />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <Instagram />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <img src={linkined} alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right-footer">
              <div className="comp-sec">
                <h4>Company</h4>
                <div className="list">
                  <ul>
                    <li>
                      <Link>About Us</Link>
                    </li>
                    <li>
                      <Link>Jobs</Link>
                    </li>
                    <li>
                      <Link>Blog</Link>
                    </li>
                    <li>
                      <Link>Terms</Link>
                    </li>
                    <li>
                      <Link>Privacy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="supp-sec">
                <h4>Support</h4>
                <div className="list">
                  <ul>
                    <li>
                      <Link>Community</Link>
                    </li>
                    <li>
                      <Link>Recourses</Link>
                    </li>
                    <li>
                      <Link>Docs</Link>
                    </li>
                    <li>
                      <Link>Affiliates</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="start-sec">
                <h4>Get Started</h4>
                <div className="list">
                  <ul>
                    <li>
                      <Link>Create Account</Link>
                    </li>
                    <li>
                      <Link>Request Demo</Link>
                    </li>
                    <li>
                      <Link>Sign In</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prod-sec">
                <h4>Product</h4>
                <div className="list">
                  <ul>
                    <li>
                      <Link>Features</Link>
                    </li>
                    <li>
                      <Link>Why Figma Clone</Link>
                    </li>
                    <li>
                      <Link>Pricing</Link>
                    </li>
                    <li>
                      <Link>Experts</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rights-sec">
          <p>
            <Copyright /> 2023 averta. All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
