import axios from "axios";
import { toast } from "react-toastify";

// Create two axios instances
const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_SERVER_LIVE_URL,
  baseURL: process.env.REACT_APP_COMPANY_SERVER_LIVE_URL,
});

const axiosInstance2 = axios.create({
  baseURL: process.env.REACT_APP_SERVER_LIVE_URL_V2,
});

const logoutUser = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refreshtoken");
  window.location.href = "#/login";
};

// Request interceptor
const requestInterceptor = (config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

// Response interceptor
const responseInterceptor = async (error, axiosInstance) => {
  let originalRequest = error.config;

  if (
    error.response?.data?.status === 500 &&
    error.response?.data?.message === "jwt expired" &&
    !originalRequest._retry
  ) {
    originalRequest._retry = true;

    try {
      const refreshToken = localStorage.getItem("refreshtoken");
      const response = await axios.post(
        "http://34.229.194.55:8888/api/v1/refresh",
        // "https://figmaclone.virtualdemo.tech/api/v1/refresh",
        {
          refreshtoken: refreshToken,
        }
      );

      localStorage.setItem("access_token", response?.data?.access_token);
      localStorage.setItem("refreshtoken", response?.data?.refreshtoken);

      originalRequest.headers.Authorization = `Bearer ${response?.data?.access_token}`;

      return axiosInstance(originalRequest);
    } catch (refreshError) {
      console.log(refreshError.message);
      logoutUser();
    }
  }

  return Promise.reject(error);
};

// Attach interceptors to axios instances
[axiosInstance, axiosInstance2].forEach((axiosInstance) => {
  axiosInstance.interceptors.request.use(requestInterceptor, (error) =>
    Promise.reject(error)
  );
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => responseInterceptor(error, axiosInstance)
  );
});

export { axiosInstance, axiosInstance2 };
