import React, { useState } from "react";
import thumb_icon from "../../../../assets/Icons/thumb_icon.svg";
import SvgComponent from "./SvgComponent";
import outPut from "../../../../../src/assets/Icons/outPut.svg";
import Snap_icon from "../../../../assets/Icons/Snap_icon.svg";
import OnDataChange from "../../../../assets/Iconcomponent/OnDataChange";
import OnFocus from "../../../../assets/Iconcomponent/OnFocus";
import OnBlur from "../../../../assets/Iconcomponent/OnBlur";
import OnTextDelay from "../../../../assets/Iconcomponent/OnTextDelay";
import OnText from "../../../../assets/Iconcomponent/OnText";

const SelectDateTrigger = ({ selectedFolder }) => {
  return (
    <>
      {selectedFolder?.type === "datepicker" && (
        <div className="sec-1" accordion>
          <div className="flex-icon-btn-2">
            <div className="icon-txt">
              <OnDataChange />
              <h5>On Date Change</h5>
            </div>
            <div className="btn-2">
              <button>1</button>
            </div>
          </div>
          <p>Runs when the element is passed</p>
        </div>
      )}

      {/* <div className="sec-1" accordion>
        <div className="flex-icon-btn-2">
          <div className="icon-txt">
            <img src={outPut} alt="" />
            <h5>On Value Change</h5>
          </div>
          <div className="btn-2">
            <button>1</button>
          </div>
        </div>
        <p>Runs when the value is changed</p>
      </div>
      {
        <>
          <div className="sec-1">
            <div className="flex-icon-btn-2">
              <div className="icon-txt">
                <OnText />
                <h5>On Change Text</h5>
              </div>
              <div className="btn-2">
                <button>1</button>
              </div>
            </div>
            <p>Runs when the Text is Changed </p>
          </div>
          <div className="sec-1">
            <div className="flex-icon-btn-2">
              <div className="icon-txt">
                <OnFocus />
                <h5>On Focus</h5>
              </div>
              <div className="btn-2">
                <button>0</button>
              </div>
            </div>
            <p>Runs when the element is focused </p>
          </div>
          <div className="sec-1">
            <div className="flex-icon-btn-2">
              <div className="icon-txt">
                <OnBlur />
                <h5>On Blur</h5>
              </div>
              <div className="btn-2">
                <button>0</button>
              </div>
            </div>
            <p>Runs when the element is blured/unfocused </p>
          </div>
          <div className="sec-1">
            <div className="flex-icon-btn-2">
              <div className="icon-txt">
                <OnTextDelay />
                <h5>On Changed Text Delayed</h5>
              </div>
              <div className="btn-2">
                <button>1</button>
              </div>
            </div>
            <p>
              Runs after a given delay from when editing has stopped
              (debouncing){" "}
            </p>
          </div>
        </>
      }
      <div className="sec-1" accordion>
        <div className="flex-icon-btn-2">
          <div className="icon-txt">
            <img src={Snap_icon} alt="" />
            <h5>On Settle</h5>
          </div>
          <div className="btn-2">
            <button>0</button>
          </div>
        </div>
        <p>Runs when bottom sheets settles on snap point</p>
      </div> */}
    </>
  );
};

export default SelectDateTrigger;
