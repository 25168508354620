import React from "react";

const TabBarsSvg = ({ change }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.375 5.625H10.125C8.88236 5.625 7.875 6.63236 7.875 7.875V10.125C7.875 11.3676 8.88236 12.375 10.125 12.375H12.375C13.6176 12.375 14.625 11.3676 14.625 10.125V7.875C14.625 6.63236 13.6176 5.625 12.375 5.625Z"
          fill={change ? "#fff" : "#3c4c6e"}
          stroke={change ? "#fff" : "#3c4c6e"}
        />
        <path
          d="M28.125 12.375H7.875C6.63236 12.375 5.625 13.3824 5.625 14.625V28.125C5.625 29.3676 6.63236 30.375 7.875 30.375H28.125C29.3676 30.375 30.375 29.3676 30.375 28.125V14.625C30.375 13.3824 29.3676 12.375 28.125 12.375Z"
          fill="#33405E"
          stroke={change ? "#fff" : "#3c4c6e"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.125 16.875H25.875V25.875H10.125V16.875Z"
          fill="#fff"
          stroke="#94A3B8"
        />
      </svg>
    </div>
  );
};

export default TabBarsSvg;
