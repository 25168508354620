import React, { useEffect, useState } from "react";
import CrossSvg from "../svgComponents/CrossSvg";
import AddScreenArrow from "../svgComponents/AddScreenArrow";

import { getMyProjects } from "../../redux/actions/projectsActions";
import { useDispatch, useSelector } from "react-redux";

const AddScreenPopup = ({
  closeModelPopup,
  titleChangeHandler,
  handleAddScreen,
  defaultValues,
  inputValue,
  setInputValue,
  popupRef,
  selectExampleScreen,
  setSameProject,
  selectBlankScreen,
  initialData,
}) => {
  const dispatch = useDispatch();

  // const [showModal, setShowModal] = useState(false);

  // const [projects, setProjects] = useState();
  // const [screens, setScreens] = useState();

  // const handleAddScreenClick = () => {
  //   handleAddScreen();
  //   setInputValue("");
  // };

  return (
    <>
      <div className="popupDashboard-Wrapper" ref={popupRef}>
        <div className="initialSection">
          <AddScreenArrow />
          <div className="blankScreen" onClick={selectBlankScreen}>
            {" "}
            Add New Wdiget
          </div>
          <div className="exampleScreen" onClick={selectExampleScreen}>
            Add Screen From Project
          </div>
        </div>

        {/* {sameProject && (
          <>
            <div className="head_main">
              <AddScreenArrow />
              <div className="headSection">
                Add New Screen <CrossSvg closeModelPopup={closeModelPopup} />
              </div>
            </div>

            <div className="add_section">
              <input
                type="text"
                className="add_input"
                onChange={(e) => {
                  setInputValue(e.target.value);
                  titleChangeHandler(e);
                }}
                name="name"
                value={inputValue}
              />
              <button
                className="add_button"
                onClick={handleAddScreenClick}
                disabled={!inputValue.trim()}
              >
                ADD
              </button>
            </div>
          </>
        )} */}
      </div>
    </>
  );
};

export default AddScreenPopup;
