import React from "react";
import { useState } from "react";
// import "./setPassword.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserPassword } from "../../redux/actions/authActions";

export const SetPassword = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);

  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const isValid = validatePassword(newPassword);
    setIsPasswordValid(isValid);

    // Also validate confirm password

    // if (confirmPassword !== newPassword) {
    //   setIsConfirmPasswordValid(false);
    // } else {
    //   setIsConfirmPasswordValid(true);
    // }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    // Validate confirm password
    if (!newConfirmPassword) {
      setIsConfirmPasswordValid(true);
    } else if (newConfirmPassword !== password) {
      setIsConfirmPasswordValid(true);
    } else {
      setIsConfirmPasswordValid(false);
    }
  };

  const validatePassword = (password) => {
    // Customize your password validation criteria here
    // For example, requiring a minimum length of 8 characters
    return password.length >= 8;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const storedValue = localStorage.getItem("myKey");

    const data = {
      password: password,
      email: storedValue,
    };

    dispatch(setUserPassword(data, navigate));
    setPassword(false);

    if (isPasswordValid && isConfirmPasswordValid) {
      // Passwords are valid, you can proceed with further actions
      console.log("Password and Confirm Password are valid:", password);
      // Add your logic here, such as sending the password to a server or updating state
    } else {
      console.log("Invalid password or confirm password");
    }
  };
  return (
    <>
      <div className="main-set-password">
        <div className="coustom_container">
          <div className="inner">
            <form onSubmit={handleSubmit}>
              <div className="main-form">
                <h3>Set Password</h3>
                <div className="input-form">
                  <label>
                    New Password
                    <input
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="New Password"
                    />
                  </label>
                  {!isPasswordValid && (
                    <p style={{ color: "red" }}>
                      Password must be at least 8 characters long.
                    </p>
                  )}
                </div>
                <div className="input-form">
                  <label>
                    Confirm New Password
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      placeholder="Confirm New Password"
                    />
                  </label>

                  {isConfirmPasswordValid ? (
                    <p style={{ color: "red" }}>Passwords do not match.</p>
                  ) : null}

                  {/* {!isConfirmPasswordValid && (
                    
                  )&&()} */}
                </div>
              </div>
              <div className="submit-btn">
                <button type="submit">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
