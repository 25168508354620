import React from "react";
import Rightimg from "../../../assets/Image/Rightimg.svg";
export const MoreBiller = () => {
  return (
    <>
      <div className="bg_sky">
        <div className="coustom_container">
          <div className="inner">
            <div className="box-1">
              <div>
                <span>About Us</span>
              </div>
              <div>
                <h2>More Billers Than Any Other App</h2>
              </div>
              <div>
                <p>
                  But I must explain to you how all this mistaken idea of
                  denouncing pleasure and praising pain was born and I will give
                  you a complete account of the system, and expound great
                  explorer of the truth, the master-builder of human pleasure,
                  but because those who do not.
                </p>
              </div>
              <div>
                <p>
                  But I must explain to you how all this mistaken idea of
                  denouncing pleasure and praising pain was born and I will give
                  you a complete account
                </p>
              </div>
            </div>
            <div className="bg-img">
              <img src={Rightimg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
