import React from "react";
import FigmaCloneDoc from "./pages/FigmaCloneDoc";
import DashboardDoc from "./pages/DashboardDoc";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";

const Content = ({
  tabs,
  navigateBackward,
  navigateForward,
  listItems,
  docId,
}) => {
  const currentIndex = listItems?.findIndex((item) => item.url === docId);

  const nextItem =
    currentIndex < listItems?.length - 1 ? listItems[currentIndex + 1] : null;
  const prevItem = currentIndex > 0 ? listItems[currentIndex - 1] : null;
  return (
    <div>
      <main className="content">
        {tabs === "welcome-to-figma-clone" && <FigmaCloneDoc />}
        {tabs === "dashboard" && <DashboardDoc />}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"></div>
        <div className="button_group ">
          {nextItem && (
            <div className="button_forward" onClick={navigateForward}>
              <WestIcon />
              {nextItem.name}
            </div>
          )}
          {prevItem && (
            <div className="button_backward" onClick={navigateBackward}>
              {prevItem.name}
              <EastIcon />
            </div>
          )}
        </div>{" "}
      </main>
      {/* <div className="button_group">
        <div className="button_forward">
          <button>starting new project</button>
        </div>
        <div className="button_backward">
          <button>Workspaces</button>
        </div>
      </div> */}
    </div>
  );
};

export default Content;
