import React, { useState, useEffect } from "react";
import "./verifyOTP.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../redux/axiosInstance";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { verifyUserOtp } from "../../redux/actions/authActions";

export const VerifyOTP = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState();
  const [time, setTime] = useState(60);
  const [disableResend, setDisableResend] = useState(false);
  const [reOtp, setReOtp] = useState(true);

  useEffect(() => {
    setReOtp(false);
    let i = 1;
    const timeout2 = setInterval(() => {
      setTime(time - i);
      i = i + 1;
    }, 1000);

    setTimeout(() => {
      setReOtp(true);
      clearInterval(timeout2);
      setTime(60);
    }, 60000);
  }, []);

  const resendOtp = async () => {
    setOtp();

    setReOtp(false);

    let i = 1;

    const timeout2 = setInterval(() => {
      setTime(time - i);
      i = i + 1;
    }, 1000);

    setTimeout(() => {
      setReOtp(true);
      clearInterval(timeout2);
      setTime(60);
    }, 60000);

    const storedValue = localStorage.getItem("myKey");

    try {
      const response = await axiosInstance.post("/resendotp", {
        email: storedValue,
      });

      setDisableResend(true);

      toast.info(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error("Failed to resend OTP", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onInputChange = (value) => {
    setOtp(value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const storedValue = localStorage.getItem("myKey");

    const number = otp;
    const data = {
      otp: number,
      email: storedValue,
    };
    if (!otp) {
      toast.error("Please enter an otp");
    } else if (otp && otp.length < 4) {
      toast.error("The OTP must be 4 digit");
    } else {
      try {
        const response = await axiosInstance.post("/otpverify", data);
        if (response.status === 200) {
          toast.success(response?.data?.result?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate("/setPassword");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
      }

      console.log("otp api");
    }
  };
  return (
    <>
      <form action="" onSubmit={handleFormSubmit}>
        <div className="main-verify">
          <div className="input_text">
            <div className="h2-bold">
              <h2>Verify Otp</h2>
            </div>

            <div className="opt-input">
              <OtpInput
                value={otp}
                onChange={onInputChange}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            <div className="p-mas">
              <p>Didn't you receive OTP</p>
            </div>

            <div className="p-opt">
              {reOtp ? (
                <h5 onClick={resendOtp}>
                  {" "}
                  <span onClick={resendOtp}>
                    <p>Resend OTP</p>
                  </span>
                </h5>
              ) : (
                <h6>
                  {" "}
                  OTP Expire in {Math.floor(time / 60)}:
                  {(time % 60).toString().padStart(2, "0")}{" "}
                </h6>
              )}
            </div>

            <div className="div-btn">
              <button>VERIFY</button>
            </div>
            <div className="p-row">
              <p>
                By creating an account, you agree to accept and abide by our
                <br /> terms of services and privacy policy
              </p>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
