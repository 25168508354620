import React from "react";

const DeleteAlign = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3084_6469)">
        <path
          d="M4.24984 13.4574C4.24984 14.2401 4.8838 14.874 5.6665 14.874H11.3332C12.1159 14.874 12.7498 14.2401 12.7498 13.4574V4.95736H4.24984V13.4574ZM13.4582 2.83236H10.979L10.2707 2.12402H6.729L6.02067 2.83236H3.5415V4.24902H13.4582V2.83236Z"
          fill="#283249"
        />
      </g>
      <defs>
        <clipPath id="clip0_3084_6469">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(0 -0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteAlign;
