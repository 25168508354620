import React from "react";

const DropDown = ({
  options,
  onSelect,
  className,
  uploadDropdownHandler,
  item,
  isDraftPreview,
}) => {
  const handleOptionSelect = (value) => {
    onSelect(value);
  };

  const width = parseInt(item.size.width);
  const height = parseInt(item.size.height);
  const min_height = parseInt(item.size?.min_height);
  const min_width = parseInt(item.size?.min_width);
  const max_height = parseInt(item.size?.max_height);
  const max_width = parseInt(item.size?.max_width);
  const widthUnit = "px";
  const heightUnit = "px";
  const min_heightunit = "px";
  const min_widthunit = "px";
  const max_heightunit = "px";
  const max_widthunit = "px";

  const marginStyle = item.margin
    ? {
      margin: item.margin.all + "px",
      marginLeft: item.margin.left + "px",
      marginTop: item.margin.top + "px",
      marginRight: item.margin.right + "px",
      marginBottom: item.margin.bottom + "px",
    }
    : {};
  const paddingStyle = item.padding
    ? {
      padding: item.padding.all + "px",
      paddingLeft: item.padding.left + "px",
      paddingTop: item.padding.top + "px",
      paddingRight: item.padding.right + "px",
      paddingBottom: item.padding.bottom + "px",
    }
    : {};
  const RadiusStyle = item.borderRadius
    ? {
      borderRadius: item.borderRadius.all + "px",
      borderRadiusLeft:
        item.borderRadius.topLeft + "px",
      borderRadiusTop:
        item.borderRadius.topRight + "px",
      borderRadiusRight:
        item.borderRadius.bottomLeft + "px",
      borderRadiusBottom:
        item.borderRadius.bottomRight + "px",
    }
    : {};
  const borderWidthStyle = item.borderWidth
    ? {
      borderWidth: item.borderWidth.all + "px",
      borderLeftWidth: item.borderWidth.left + "px",
      borderTopWidth: item.borderWidth.top + "px",
      borderRightWidth: item.borderWidth.right + "px",
      borderBottomWidth:
        item.borderWidth.bottom + "px",
    }
    : {};

  return (
    <>
      <div
        className={`dropdown-options ${className}`}
        style={{
          backgroundColor: item.id ? item?.background : null,
          resize: "none",
          zIndex: "10",
          cursor: isDraftPreview ? "pointer" : "default", // Set cursor style dynamically
          width: `${width}${widthUnit}`,
          height: `${height}${heightUnit}`,
          minHeight: `${min_height}${min_heightunit}`,
          minWidth: `${min_width}${min_widthunit}`,
          maxHeight: `${max_height}${max_heightunit}`,
          maxWidth: `${max_width}${max_widthunit}`,

          // color: "red !important",

          borderColor: item.id ? item.borderColor : null,
          borderStyle: item.id ? item.borderStyle : null,

          ...marginStyle,
          ...paddingStyle,
          ...borderWidthStyle,
          ...RadiusStyle,
          cursor: "pointer",
        }}
      >
        <select
          name="dropdown"
          id="dropdown"
          onClick={(e) => uploadDropdownHandler(e, item)}
          style={{
            color: item.id ? item.color : null,
            fontSize: item.id ? `${item.fosntSize}px` : "12px",
            lineHeight: item.id ? `${item.lineHeight}px` : "12px",
            letterSpacing: item.id ? `${item.letterSpacing}px` : null,
            fontFamily: item.id ? item.fontFamily : null,
            textAlign: item.id ? item.textAlign : null,
            fontWeight: item.id ? item.fontWeight : null,
            textDecoration: item.id ? item.textdecoration : null,
            textTransform: item.id ? item.textTransform : null,
          }}
        >
          <option value="volvo">Select an option</option>
          <option value="volvo">Select an option</option>
          <option value="volvo">Select an option</option>
        </select>
      </div>
    </>
  );
};

export default DropDown;
