import React from "react";
import SliderComp from "./slider/SliderComp";

export const ScreenCom = () => {
  return (
    <>
      <div className="coustom_container">
        <div className="srcoll_section">
          <div className="md_text">
            <p>What We Do</p>
            <h3>All Screen Design</h3>
            <SliderComp />
          </div>
        </div>
      </div>
    </>
  );
};
