import React from "react";

const TextAlignRight = ({ selectedFolder }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="rticon"
      // style={{
      //   background: selectedFolder?.textAlign === "Right" && "#0AABE6",
      // }}
    >
      <path
        d="M16.5 13.5042C16.5 13.9164 16.1658 14.2506 15.7536 14.2506H9.7464C9.3342 14.2506 9 13.9164 9 13.5042C9 13.092 9.3342 12.7578 9.7464 12.7578H15.7536C16.1658 12.7578 16.5 13.092 16.5 13.5042Z"
        fill="#fff"
      />
      <path
        d="M16.5 9.00023C16.5 9.41251 16.1658 9.74663 15.7536 9.74663H2.24639C1.83417 9.74663 1.5 9.41251 1.5 9.00023C1.5 8.58803 1.83417 8.25391 2.24639 8.25391H15.7536C16.1658 8.25391 16.5 8.58803 16.5 9.00023Z"
        fill="#fff"
      />
      <path
        d="M15.7536 5.24279C16.1658 5.24279 16.5 4.90861 16.5 4.49639C16.5 4.08417 16.1658 3.75 15.7536 3.75H6.74639C6.33417 3.75 6 4.08417 6 4.49639C6 4.90861 6.33417 5.24279 6.74639 5.24279H15.7536Z"
        fill="#fff"
      />
    </svg>
  );
};

export default TextAlignRight;
