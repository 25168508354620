import React, { useState } from "react";
// import "./verifyOTP.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../redux/axiosInstance";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { verifyOTP } from "../../../redux/actions/authActions";

export const SignUpVerifyOtp = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [otp, setOTP] = useState("");

  const handleOTPChange = (otpValue) => {
    setOTP(otpValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate OTP input
    if (!otp) {
      toast.error("Please enter the OTP", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const storedValue = localStorage.getItem("email");

    const number = otp;
    const data = {
      otp: number,
      email: storedValue,
    };

    dispatch(verifyOTP(data, navigate));
    setOTP("");
    // }
    setTimeout(() => {
      setOTP(false);
    }, 4000);
  };
  return (
    <>
      <form action="" onSubmit={handleSubmit}>
        <div className="main-verify">
          <div className="input_text">
            <div className="h1-bold">
              <h2>Verify Otp</h2>
            </div>

            <div className="opt-input">
              <OtpInput
                value={otp}
                onChange={handleOTPChange}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            <div className="p-mas">
              <p>Didn't you receive the OTP?</p>
            </div>

            {/* <div className="p-opt">
              <p> Resend OTP</p>
            </div> */}

            <div className="div-btn">
              <button>Verify</button>
            </div>
            <div className="p-row">
              <p>
                By creating an account, you agree to accept and abide by our
                <br /> terms of services and privacy policy
              </p>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
