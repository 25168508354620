import React, { useState, useEffect, useRef } from "react";
import UpdateMyValues from "../../../../common/customHooks/UpdateMyValues";

const TextArea = ({
  item,
  selectedFolder,
  setTextValue,
  textValue,
  handleKeyDown,
  setSelectedFolderAction,
  isDraftPreview,
  readOnly,
  myApiKeys,
  index,
  setFolderStructureAction,
  folderStructure,
}) => {
  const inputRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const [value, setValue] = useState(item.canvas_text || "Enter your Text");

  useEffect(() => {
    adjustRows();
  }, [value]);

  const { handleStyleUpdate } = UpdateMyValues(
    setFolderStructureAction,
    setSelectedFolderAction
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("paste", handlePaste);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("paste", handlePaste);
      }
    };
  }, []);

  const handlePaste = (event) => {
    // Delay needed to ensure the value has been updated after paste
    setTimeout(adjustRows, 0);
  };

  const handleTextChange = (event) => {
    const newTextValue = event.target.value;
    setValue(newTextValue);
  };

  const onblurHandle = (e) => {
    const newTextValue = e.target.value;
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "canvas_text",
      newTextValue
    );
  };

  const adjustRows = () => {
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto to calculate the new height
      textarea.style.height = textarea.scrollHeight + "px"; // Set the height to the scrollHeight
    }
  };

  const onDataSelectHandler = (e) => {
    e.stopPropagation(); // Prevent event propagation
    inputRef.current.focus(); // Ensure textarea retains focus
    // setSelectedFolderAction(item);
  };

  const marginStyle = item.margin
    ? {
      margin: item.margin.all + "px",
      marginLeft: item.margin.left + "px",
      marginTop: item.margin.top + "px",
      marginRight: item.margin.right + "px",
      marginBottom: item.margin.bottom + "px",
    }
    : {};
  const paddingStyle = item.padding
    ? {
      padding: item.padding.all + "px",
      paddingLeft: item.padding.left + "px",
      paddingTop: item.padding.top + "px",
      paddingRight: item.padding.right + "px",
      paddingBottom: item.padding.bottom + "px",
    }
    : {};
  const radiusStyle = item.borderRadius
    ? {
      borderRadius: item.borderRadius.all + "px",
      borderTopLeftRadius:
        item.borderRadius.topLeft + "px",
      borderTopRightRadius:
        item.borderRadius.topRight + "px",
      borderBottomLeftRadius:
        item.borderRadius.bottomLeft + "px",
      borderBottomRightRadius:
        item.borderRadius.bottomRight + "px",
    }
    : {};
  const borderWidthStyle = item.borderWidth
    ? {
      borderWidth: item.borderWidth.all + "px",
      borderLeftWidth: item.borderWidth.left + "px",
      borderTopWidth: item.borderWidth.top + "px",
      borderRightWidth: item.borderWidth.right + "px",
      borderBottomWidth:
        item.borderWidth.bottom + "px",
    }
    : {};

  const handleTextareaClick = (e) => {
    e.stopPropagation();
    inputRef.current.focus();
    setShowTooltip(true);
    if (selectedFolder?.id !== item?.id) {
      setSelectedFolderAction(item);
    }
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleOutsideClick = (e) => {
    if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const inputRect = inputRef.current?.getBoundingClientRect();
  const tooltipLeft = inputRect ? inputRect.left : "50%";

  return (
    <div style={{ position: "relative" }} key={`items-${index}`}>
      <input
        id={item.id}
        name="canvas_text"
        ref={inputRef}
        onKeyDown={(e) => {
          handleKeyDown(e);
          if (e.key === "Enter") {
            adjustRows();
          }
        }}
        className={
          selectedFolder?.id === item?.id ? "selectInput-box" : "input-box"
        }
        style={{
          resize: "none",
          zIndex: "10",
          width: "347px",
          cursor: isDraftPreview ? "pointer" : "default",
          color: item.id ? item.color : null,
          fontSize: item.id ? `${item.fontSize}px` : "12px",
          lineHeight: item.id ? `${item.lineHeight}px` : "12px",
          letterSpacing: item.id ? `${item.letterSpacing}px` : null,
          fontFamily: item.id ? item.fontFamily : null,
          textAlign: item.id ? item.textAlign : null,
          fontWeight: item.id ? item.fontWeight : null,
          textDecoration: item.id ? item.textdecoration : null,
          borderColor: item.id ? item.borderColor : null,
          borderStyle: item.id ? item.borderStyle : null,
          textTransform: item.id ? item.textTransform : null,
          ...marginStyle,
          ...paddingStyle,
          ...borderWidthStyle,
          ...radiusStyle,
          width: value?.length > 15 ? "auto" : `${value?.length * 14}px`,
        }}
        rows={1}
        onChange={handleTextChange}
        value={value}
        onBlur={(e) => onblurHandle(e)}
        onClick={handleTextareaClick}
        readOnly={readOnly}
      />
    </div>
  );
};

export default TextArea;
