import React, { useState, useEffect } from "react";
import DateInputsandVariables from "../CommonRightbarTab/DateInputsandVariables";

const JsonTextEditor = () => {
  let currentEditObj;
  let input = {
    example: {
      id: "json",
      age: 99,
      working: true,
      problem: null,
      "more info": {
        car: {
          brand: "a-brand",
          year: 2020,
          owners: ["father", "brother"],
        },
      },
    },
  };

  const myStyle = {
    theme: {
      color: "#00A170",
      hoverColor: "#f7f7f7",
    },
    bannerStyle: {
      hoverColor: "#006e4d",
      fontColor: "white",
      font: '20px/30px "Times New Roman", Times, serif',
    },
    keyStyle: {
      color: "black",
      font: '14px/20px "Times New Roman", Times, serif',
    },
    valueStyle: {
      font: "14px/20px Arial, Helvetica, sans-serif",
      null: "#939597",
      boolean: "#939597",
      number: "#939597",
      string: "#939597",
    },
    buttonStyle: {
      add: "#006e4d",
      delete: "#939597",
      update: "#006e4d",
      cancel: "#bb0039",
    },
  };
  const [jsonInput, setJsonInput] = useState(JSON.stringify(input, null, " "));
  function textSave(e) {
    setJsonInput(e.target.value);
  }

  function saveJsonString() {
    if (currentEditObj === undefined) {
      setJsonInput("");
    } else {
      setJsonInput(currentEditObj);
    }
  }
  return (
    <div>
      {" "}
      <div className="screenContainer">
        <div className="mainContainer">
          <div className="topContainer">
            <span> Json Editor (Demo) </span>
          </div>

          <div className="viwContainer">
            <div className="jsonContainer">
              <textarea value={jsonInput} onChange={textSave} />
            </div>

            <div className="middleContainer">
              <div style={{ textAlign: "center" }}>
                <button type={"button"} onClick={saveJsonString}>
                  <span>
                    <i className="arrowLeft" />
                    String
                  </span>
                </button>
              </div>
            </div>

            <div className="output">
              <div className="jsonContainer">
                <div
                  style={{
                    height: "100%",
                    border: "1px solid lightgray",
                    borderRadius: "6px",
                    backgroundColor: "white",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JsonTextEditor;
