import React from "react";

const DatePickerSvg = ({ change }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.625 7.4375V27.625L7.4375 28.6875L6.375 7.4375H27.625Z"
          fill="#fff"
          stroke="#94A3B8"
        />
        <path
          d="M27.625 5.3125H6.375C5.20139 5.3125 4.25 6.26389 4.25 7.4375V11.6875C4.25 12.8611 5.20139 13.8125 6.375 13.8125H27.625C28.7986 13.8125 29.75 12.8611 29.75 11.6875V7.4375C29.75 6.26389 28.7986 5.3125 27.625 5.3125Z"
          stroke="#94A3B8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.6308 8.1033L24.4403 9.18478L26.2499 8.09761C26.6824 7.84147 27.2401 7.97808 27.4962 8.41067C27.7522 8.84326 27.6157 9.40107 27.1832 9.6629L24.907 11.029C24.6168 11.1997 24.2582 11.1997 23.968 11.029L21.6918 9.6629C21.2593 9.40676 21.1228 8.84326 21.3788 8.41067C21.6349 7.97808 22.1983 7.84147 22.6251 8.09761L22.6308 8.1033Z"
          fill="#94A3B8"
        />
      </svg>
    </div>
  );
};

export default DatePickerSvg;
