import React, { useEffect, useLayoutEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import RESTGeneric from "../assets/Icons/RESTGeneric.svg";

import bracket_icon from "../assets/Icons/bracket_icon.svg";
import white_bracket from "../assets/Icons/white_bracket.svg";
import backendless from "../assets/Icons/backendless.svg";
import supabase from "../assets/Icons/supabase.svg";
import contentful from "../assets/Icons/contentful.svg";
import bubble from "../assets/Icons/bubble.svg";
import airtable from "../assets/Icons/airtable.svg";
import xano from "../assets/Icons/xano.png";
import sheety from "../assets/Icons/sheety.svg";
import zapier from "../assets/Icons/zapier.svg";
import unsplash from "../assets/Icons/unsplash.svg";
import wordpress from "../assets/Icons/wordpress.svg";
import webflow from "../assets/Icons/webflow.svg";
import RestApi from "../assets/Icons/RestApi.svg";
import link_arror from "../assets/Icons/link_arror.svg";
import question from "../assets/Icons/question.svg";

import Select from "react-select";
import {
  Add,
  DeleteOutline,
  FolderCopy,
  MoreHoriz,
  OpenInNew,
  ArrowBack,
  KeyboardArrowDown,
  Info,
  Remove,
  Keyboard,
  Cancel,
  BuildCircle,
  Update,
} from "@mui/icons-material";
import ModalDelete from "./svgComponents/ModalDelete";
import { Link, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Accordion from "react-bootstrap/Accordion";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import {
  addEndpoint,
  addEndpoints,
  addFlashList,
  deleteEndpointdata,
  deleteFlashList,
  editEndpoint,
  editFlashlist,
  getEndpointsData,
  getFlashlistData,
  myApiData,
} from "../redux/actions/flashListActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactJson from "react-json-view";
import InformationIcon from "../assets/Iconcomponent/InformationIcon";

const options = [
  { value: "get", label: "GET" },
  { value: "post", label: "POST" },
  { value: "put", label: "PUT" },
  { value: "patch", label: "PATCH" },
  { value: "delete", label: "DELETE" },
];

const role = [
  { value: "get-one", label: "Get One" },
  { value: "get-many", label: "Get Many" },
  { value: "create", label: "Create" },
  { value: "update", label: "Update" },
  { value: "delete", label: "Delete" },
  { value: "login", label: "Login" },
  { value: "register", label: "Register" },
];

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#F8F8FF",
    borderRadius: "5px",
    width: "296px",
    border: "1px solid #B8B8B8",
    height: "30px",
    minHeight: "30px",
    fontSize: "12px",
    outline: "none",
    color: "#141a29",
  }),
  indicatorContainer: (styles) => ({
    ...styles,
    padding: "4px",
    height: "30px",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#F8F8FF",
    width: "296px",
    color: "#141a29",
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? "  rgb(88 103 138 / 56%)" : "#F8F8FF",
    color: isFocused ? "#fff" : "#141a29",
    cursor: "pointer",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#141a29",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#141a29",
  }),
};

const ApiCloudServicesModal = ({
  openModalData,
  closeDataModal,
  openDataModal,
  setOpenModalData,
  flashlistData,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [restApiModal, setRestApiModal] = useState(false);
  const [restApiEndpointModal, setRestApiEndpointModal] = useState(false);
  const [inputFields, setInputFields] = useState([{ key: "", value: "" }]);
  const [createEndpointModal, setCreateEndpointModal] = useState(false);
  const [updateEnpoint, setUpdateEndpoint] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [editRestApi, setEditRestApi] = useState(false);
  const [openOptionsIndex, setOpenOptionsIndex] = useState(null);
  const [selectedFlashlistData, setSelectedFlashlistData] = useState(null);
  const [selectedEndpointData, setSelecteEndpointData] = useState(null);
  const [editedFlashlistData, setEditedFlashlistData] = useState({
    name: "",
    dev_url: "",
    prod_url: "",
    headers_data: "",
  });
  const [endpointData, setEndpointData] = useState({
    name: "",
    method: "",
    objectRole: "",
    pathParameters: "",
    headers: [],
  });

  const {
    allFlashlist,
    deleteFlashlist,
    addFlashlist,
    getEndpoints,
    addEndpoint,
    deleteEndpoint,
    myJsonData,
  } = useSelector((state) => state?.flashlist);

  const dispatch = useDispatch();

  const projectname = localStorage.getItem("project_name");

  const projectid = localStorage.getItem("project_id");

  const flashlistId = localStorage.getItem("flashlist_id");

  useLayoutEffect(() => {
    if (projectid) {
      dispatch(getFlashlistData(projectid));
    }
    if (flashlistId) {
      dispatch(getEndpointsData(flashlistId));
    }
  }, [
    projectid,
    addFlashlist,
    deleteFlashlist,
    flashlistId,
    addEndpoint,
    deleteEndpoint,
    ,
  ]);

  useEffect(() => {
    const flashlist = allFlashlist?.find((item) => item._id === flashlistId);
    setSelectedFlashlistData(flashlist);
    localStorage.setItem("endpoint_id", null);
  }, [allFlashlist]);

  const handleToggleOptions = (index) => {
    setOpenOptionsIndex((prevIndex) => (prevIndex === index ? null : index));
    setShowOptions(true);
  };

  const handleOptionClick = (option, data) => {
    if (option === "delete") {
      setDeleteConfirmation(true);
    }
    if (option === "update") {
      localStorage.setItem("endpoint_id", data?._id);
      setUpdateEndpoint(true);
      setEndpointData(data);
    }
    if (option === "update_flashlist") {
      setEditRestApi(true);
    }
    setShowOptions(false);
  };

  const handleConfirmDelete = (data) => {
    // Handle delete confirmation here
    dispatch(deleteFlashList(projectid, data?._id));
    setDeleteConfirmation(false);
  };
  const handleConfirmDelete2 = (data) => {
    // Handle delete confirmation here
    dispatch(deleteEndpointdata(flashlistId, data?._id));
    setDeleteConfirmation(false);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmation(false);
  };

  const onOpenRestApiModal = () => {
    setRestApiModal(true);
    setTimeout(() => {
      closeDataModal();
    }, 1000);
  };
  const onCloseRestApiModal = () => {
    setRestApiModal(false);
    openDataModal();
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { key: "", value: "" }]);
  };

  const handleRemoveFields = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const handleChangeInput = (index, event) => {
    const newInputFields = [...inputFields];
    if (event.target.name === "key") {
      newInputFields[index].key = event.target.value;
    } else {
      newInputFields[index].value = event.target.value;
    }
    setInputFields(newInputFields);
  };

  useEffect(() => {
    if (selectedFlashlistData) {
      setEditedFlashlistData(selectedFlashlistData);
    }
    if (selectedEndpointData) {
      setEndpointData(selectedEndpointData);
    }
  }, [selectedFlashlistData, selectedEndpointData]);

  const handleChangeInputEdit = (field, value) => {
    setEditedFlashlistData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setEndpointData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSave = () => {
    const { name, dev_url, prod_url } = editedFlashlistData;
    const flashlistId = localStorage.getItem("flashlist_id");
    const projectId = localStorage.getItem("project_id");

    if (!name || !dev_url || !prod_url || !flashlistId) {
      return;
    }

    dispatch(editFlashlist(projectId, flashlistId, editedFlashlistData));
    closeEditRestApiModal();
  };

  const handleSaveEndpoint = () => {
    const endpointId = localStorage.getItem("endpoint_id");

    const {
      name,
      method,
      Object_role,
      path_parameters,
      headers: {},
    } = endpointData;

    if (!name || !method || !Object_role || !path_parameters) {
      return;
    }

    dispatch(editEndpoint(flashlistId, endpointId, endpointData));
    // closeUpdateEndpointModal();
  };

  const endpointsOpenModal = (flashlistItem) => {
    setRestApiEndpointModal(true);
    localStorage.setItem("flashlist_id", flashlistItem._id);
    localStorage.setItem("flashlist_name", flashlistItem.prod_url);
  };

  const endpointsCloseModal = () => {
    setRestApiEndpointModal(false);
  };

  const createEndpointOpenModal = () => {
    setCreateEndpointModal(true);
    setApiData(null);
  };

  const createEndpointCloseModal = () => {
    setCreateEndpointModal(false);
  };

  const openUpdateEndpointModal = (data, dataName, all) => {
    // const {endpoint._id} = dataName

    // setUpdateEndpoint(true);
    dispatch(myApiData(dataName));
    setRestApiModal(false);
    setRestApiEndpointModal(false);
    closeDataModal();
  };

  const closeUpdateEndpointModal = () => {
    setUpdateEndpoint(false);
  };

  const openEditRestApiModal = () => {
    setEditRestApi(true);
  };

  const closeEditRestApiModal = () => {
    setEditRestApi(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Service name is required"),
    devUrl: Yup.string()
      .url("Invalid URL format")
      .required("Dev URL is required"),
    prodUrl: Yup.string().url("Invalid URL format"),
    headers_data: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required("Header keys cannot be empty"),
        value: Yup.string().required("Header values cannot be empty"),
      })
    ),
  });

  const validationSchema2 = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    method: Yup.string().required("Method is required"),
    object: Yup.string().required("Object is required"),
    role: Yup.string().required("Role is required"),
    path: Yup.string()
      .matches(/^\//, 'Path must start with a "/"')
      .required("Path is required"),
    // headers: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       key: Yup.string().required("Key is required"),
    //       value: Yup.string().required("Value is required"),
    //     })
    //   )
    //   .required("Headers are required"),
  });

  const [values, setValues] = useState({ path: "" });
  const [apiData, setApiData] = useState(null);
  // const [pathValue, setPathValue] = useState("");

  const storedPath = localStorage.getItem("flashlist_name");
  const pathValue = localStorage.getItem("prod_url");

  const handleChangeTest = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const fullUrl = storedPath + pathValue;

  const handleTestClick = (e) => {
    // e.preventDefault();
    fetch(fullUrl)
      .then((response) => response.json())
      .then((data) => {
        setApiData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // useEffect(() => {
  //   handleTestClick();
  // }, [fullUrl]);

  const handleSkipClick = (e) => {
    e.preventDefault();
    setApiData(null);
  };

  return (
    <>
      {/* Api and Cloud Service Modal  */}
      <Modal
        open={openModalData}
        onClose={closeDataModal}
        center
        classNames={{
          modal: "api_cloud_assets_modal",
        }}
      >
        <div className="top_head">
          <h3>API & Cloud Services </h3>
          <span>
            API Integration Docs
            <img src={link_arror} alt="" />
          </span>
        </div>

        <div className="body_sec">
          <div className="left_bar">
            {/* <p>
              Add data to your app and connect your backend, APIs and cloud
              services to bring your app to life.
            </p> */}
            <h5>ADD YOUR CUSTOM API</h5>
            <div className="add_custom_api" onClick={onOpenRestApiModal}>
              <div className="api_btn">
                <div className="rest_api_logo">
                  <img src={RESTGeneric} alt="fdfd" />
                </div>
                <div className="title">
                  <p>Rest API</p>
                </div>
              </div>
              <div className="hvr_bg">
                <div className="circle">
                  <Add />
                </div>
              </div>
            </div>
            <div className="connect_service">
              <h5>CONNECT A SERVICE (REST)</h5>
              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={backendless} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Backendless</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={supabase} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Supabase</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={contentful} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Contentful</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={bubble} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Bubble</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={airtable} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Airtable</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={xano} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Xano</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={sheety} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Sheety</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
                {/* <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img
                        src={zapier}
                        alt="fdfd"
                        style={{ width: "32px", height: "32px" }}
                      />
                    </div>
                    <div className="title">
                      <p>Zapier</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img
                        src={zapier}
                        alt="fdfd"
                        style={{ width: "32px", height: "32px" }}
                      />
                    </div>
                    <div className="title">
                      <p>Zapier</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={unsplash} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Unsplash</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={wordpress} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Wordpress</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner">
                <div className="add_custom_api">
                  <div className="api_btn">
                    <div className="rest_api_logo">
                      <img src={webflow} alt="fdfd" />
                    </div>
                    <div className="title">
                      <p>Webflow</p>
                    </div>
                  </div>
                  <div className="hvr_bg">
                    <div className="circle">
                      <Add />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="import_sec">
              <h5>IMPORT</h5>
              <div className="add_custom_api_2 import_api">
                <div className="api_btn">
                  <div className="rest_api_logo">
                    <FolderCopy />
                  </div>
                  <div className="title">
                    <p>Copy from another app</p>
                  </div>
                </div>
                <div className="hvr_bg">
                  <div className="circle">
                    <Add />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="main_bar">
            <div className="api_services">
              <h5>API SERVICES</h5>
              <div className="inner_blocks">
                {allFlashlist &&
                  allFlashlist?.map((flashlistItem, index) => (
                    <div className="api_blocks" key={index}>
                      <div
                        className="left_data"
                        onClick={() => endpointsOpenModal(flashlistItem)}
                      >
                        <div className="logo_Sec">
                          <img src={bracket_icon} alt="rest_api" />
                        </div>
                        <div className="txt_sec">
                          <div className="top_sec">
                            <span>{flashlistItem.name}</span>
                            <span className="bg_change">
                              {/* {getEndpoints?.[0]?.Endpointdata.length} endpoints */}
                              {/* {getEndpoints?.[0]?.Endpointdata.length} endpoints */}
                            </span>
                          </div>
                          <div className="btm_sec">
                            <p>{flashlistItem.dev_url}</p>
                            {/* <p>{flashlistItem._id}</p>s */}
                          </div>
                        </div>
                      </div>
                      <div className="right_data">
                        <div className="popup_sec">
                          <MoreHoriz
                            onClick={() => handleToggleOptions(flashlistItem)}
                          />

                          {showOptions &&
                            openOptionsIndex === flashlistItem && (
                              <div
                                className="options"
                                style={{
                                  position: "absolute",
                                }}
                              >
                                <button
                                  onClick={() => handleOptionClick("duplicate")}
                                >
                                  <FolderCopy />
                                  Duplicate
                                </button>
                                <button
                                  onClick={() => handleOptionClick("delete")}
                                >
                                  <DeleteOutline />
                                  Delete
                                </button>
                                <button
                                  onClick={() =>
                                    handleOptionClick("update_flashlist")
                                  }
                                >
                                  <Update />
                                  Update
                                </button>
                              </div>
                            )}

                          {deleteConfirmation &&
                            openOptionsIndex === flashlistItem && (
                              <div className="delete-confirmation">
                                <div className="svg_trashg">
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 70 80"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_3067_2481)">
                                      <path
                                        d="M67.5 12.5H54.6281L49.3156 3.63281C47.8281 1.37922 45.5312 0 42.9062 0H27.0938C24.4688 0 22.0312 1.37922 20.6875 3.63281L15.3719 12.5H2.5C1.11328 12.5 0 13.6141 0 15V17.5C0 18.8906 1.11328 20 2.5 20H5V70C5 75.5234 9.47656 80 15 80H55C60.5234 80 65 75.5234 65 70V20H67.5C68.8906 20 70 18.8906 70 17.5V15C70 13.6141 68.8906 12.5 67.5 12.5ZM26.8594 7.95C27.0156 7.67656 27.3281 7.5 27.6562 7.5H42.3438C42.6758 7.5 42.9883 7.67578 43.1445 7.94922L45.875 12.5H24.125L26.8594 7.95ZM55 72.5H15C13.6192 72.5 12.5 71.3808 12.5 70V20H57.5V70C57.5 71.375 56.375 72.5 55 72.5ZM35 65C36.3819 65 37.5 63.8819 37.5 62.5V30C37.5 28.6181 36.3819 27.5 35 27.5C33.6181 27.5 32.5 28.625 32.5 30V62.5C32.5 63.875 33.625 65 35 65ZM22.5 65C23.875 65 25 63.875 25 62.5V30C25 28.6181 23.8819 27.5 22.5 27.5C21.1181 27.5 20 28.625 20 30V62.5C20 63.875 21.125 65 22.5 65ZM47.5 65C48.8819 65 50 63.8819 50 62.5V30C50 28.6181 48.8819 27.5 47.5 27.5C46.1181 27.5 45 28.625 45 30V62.5C45 63.875 46.125 65 47.5 65Z"
                                        fill="#F32028"
                                        fill-opacity="0.74"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_3067_2481">
                                        <rect
                                          width="70"
                                          height="80"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                                <h5>Are you sure?</h5>
                                <p>This item will be permanently deleted?</p>
                                <div className="btns_sec_delete">
                                  <button
                                    onClick={() =>
                                      handleConfirmDelete(flashlistItem)
                                    }
                                    className="btn_delete"
                                  >
                                    Delete
                                  </button>
                                  <button
                                    onClick={handleCancelDelete}
                                    className="btn_save"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>

                      {/* REST API ENPOINTS MODAL  */}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="done_btn">
          <div className="main_btn">
            <button className="btn_close" onClick={closeDataModal}>
              Close
            </button>
          </div>
        </div> */}
      </Modal>

      {/* Add Rest API Modal */}
      <Modal
        open={restApiModal}
        onClose={onCloseRestApiModal}
        center
        classNames={{
          modal: "api_cloud_assets_modal",
        }}
      >
        <Formik
          initialValues={{
            name: "",
            devUrl: "",
            prodUrl: "",
            headers_data: [{ key: "", value: "" }],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const flashlistdata = [
              {
                name: values.name,
                dev_url: values.devUrl,
                prod_url: values.prodUrl,
                headers_data: Object.fromEntries(
                  values.headers_data.map((header) => [
                    header.key,
                    header.value,
                  ])
                ),
              },
            ];

            const data = {
              projectname: projectname,
              projectid: projectid,
              flashlistdata: flashlistdata,
            };

            dispatch(addFlashList(data));
            onCloseRestApiModal();
            setSubmitting(false);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, isValid, dirty }) => (
            <Form>
              <div className="top_head">
                <h3>Add Rest API</h3>
                <img src={question} alt="" className="question_icon" />
              </div>

              <div className="body_sec">
                <div className="left_bar">
                  <img
                    src={RESTGeneric}
                    alt="rest_api"
                    width="31px"
                    height="31px"
                  />{" "}
                  <br />
                  <h6>Rest API</h6>
                  <p style={{ fontSize: "14px", lineHeight: "24px" }}>
                    Add data to your app and connect your backend, API's, and
                    cloud services to bring your app to life.
                  </p>
                  <hr></hr>
                  <div className="connect_api">
                    <h6 style={{ fontWeight: "600", fontSize: "14px" }}>
                      Connecting Rest API to Draftbit
                    </h6>
                    <p>
                      To get started using a REST API service with Draftbit,
                      open the guide below and follow the steps.
                    </p>
                    <Link className="guide_btn">
                      REST API and FigmaClone Guide <OpenInNew />
                    </Link>
                  </div>
                  <div className="resources">
                    <h6 style={{ fontWeight: "600", fontSize: "14px" }}>
                      Available Resources
                    </h6>

                    <Link className="guide_btn">
                      Fetching Data <OpenInNew />
                    </Link>
                    <Link className="guide_btn">
                      Submitting Data <OpenInNew />
                    </Link>
                    <Link className="guide_btn">
                      Example Data <OpenInNew />
                    </Link>
                  </div>
                </div>
                <div className="main_bar steps_sec">
                  <div className="step_1">
                    <div className="title_sec">
                      <KeyboardArrowDown /> Step 1 - API Basics
                    </div>

                    <div className="body_sec_2">
                      <div className="input_sec">
                        <div className="title">
                          <p>Name</p>
                        </div>
                        <div className="input_field_new">
                          <input
                            type="text"
                            name="name"
                            placeholder="Service name"
                            onChange={handleChange}
                            value={values.name}
                          />
                          {errors.name && (
                            <div className="error">{errors.name}</div>
                          )}
                        </div>
                      </div>
                      <div className="input_sec">
                        <div className="title">
                          <p>Dev URL</p>
                        </div>
                        <div className="input_field_new">
                          <input
                            type="text"
                            name="devUrl"
                            placeholder="https://api-staging.example.com"
                            onChange={handleChange}
                            value={values?.devUrl}
                          />
                          {errors.devUrl && (
                            <div className="error">{errors.devUrl}</div>
                          )}
                        </div>
                      </div>
                      <div className="input_sec">
                        <div className="title">
                          <p>
                            Prod URL{" "}
                            <Tooltip
                              title="Production URL 

                        If this value is set, this URL will be used when publishing in the Production environment. If unset or the environment is Development, the Dev URL will be used."
                              arrow
                            >
                              <InformationIcon />
                            </Tooltip>
                          </p>
                        </div>
                        <div className="input_field_new">
                          <input
                            type="text"
                            name="prodUrl"
                            placeholder="https://api.example.com"
                            onChange={handleChange}
                            value={values.prodUrl}
                          />
                          {errors.prodUrl && (
                            <div className="error">{errors.prodUrl}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="step_1 step_2">
                    <div className="title_sec">
                      <KeyboardArrowDown /> Step 2 - Headers & Authentication
                    </div>
                    <div className="mid_sec">
                      <p>
                        HTTP Headers to include with all requests that use this
                        API.
                      </p>
                    </div>
                    <div className="body_sec_2">
                      <div className="add_row">
                        <span>Key</span>
                        <span className="val">Value</span>
                        <button
                          type="button"
                          onClick={() => {
                            values.headers_data.push({ key: "", value: "" });
                            handleChange({
                              target: {
                                name: "headers_data",
                                value: values.headers_data,
                              },
                            });
                          }}
                        >
                          Add Row
                        </button>
                      </div>
                      <FieldArray
                        name="headers_data"
                        render={({ push, remove }) => (
                          <div className="key_value_sec">
                            {values.headers_data.map((header, index) => (
                              <div key={index} className="key_value_sec">
                                <div className="inner">
                                  <div className="input_sec">
                                    <input
                                      type="text"
                                      name={`headers_data[${index}].key`}
                                      value={header.key}
                                      onChange={handleChange}
                                      placeholder="Accept"
                                    />
                                    {errors.headers_data &&
                                      errors.headers_data[index] &&
                                      errors.headers_data[index].key && (
                                        <div className="error">
                                          {errors.headers_data[index].key}
                                        </div>
                                      )}
                                  </div>
                                  <div className="input_sec">
                                    <input
                                      type="text"
                                      name={`headers_data[${index}].value`}
                                      value={header.value}
                                      onChange={handleChange}
                                      placeholder="application/json"
                                    />
                                    {errors.headers_data &&
                                      errors.headers_data[index] &&
                                      errors.headers_data[index].value && (
                                        <div className="error">
                                          {errors.headers_data[index].value}
                                        </div>
                                      )}
                                  </div>
                                  <div className="remove_btn">
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                        color: "#141a29",
                                      }}
                                    >
                                      <Remove />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="done_btn2">
                <div className="main_btn">
                  <button
                    className="btn_close"
                    type="submit"
                    // onClick={onCloseRestApiModal}
                  >
                    Save
                  </button>
                  <button
                    className="btn_save"
                    // disabled={!isValid || !dirty}
                    onClick={onCloseRestApiModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Rest Api Modal  */}
      <Modal
        open={restApiEndpointModal}
        onClose={endpointsCloseModal}
        center
        classNames={{
          modal: "api_cloud_assets_modal",
        }}
      >
        <div className="top_head">
          <h3>REST API</h3>

          <img src={question} alt="" className="question_icon" />
        </div>

        <div className="body_sec">
          <div className="left_bar">
            <img src={RestApi} alt="rest_api" width="31px" height="31px" />{" "}
            <br />
            <h6>Example Data</h6>
            <p style={{ fontSize: "14px", lineHeight: "24px" }}>
              https://example-data.draftbit.com
            </p>
            <h6>Header</h6>
            <p style={{ fontSize: "14px", lineHeight: "24px" }}>
              Accept, Content-Type
            </p>
            <Link className="guide_btn" onClick={openEditRestApiModal}>
              Edit API Service
            </Link>
            <hr style={{ marginTop: "30px" }}></hr>
            <div className="connect_api">
              <h6 style={{ fontWeight: "600", fontSize: "14px" }}>
                Connecting Rest API to Draftbit
              </h6>
              <p>
                To get started using a REST API service with Draftbit, open the
                guide below and follow the steps.
              </p>
              <Link className="guide_btn">
                REST API and FigmaClone Guide <OpenInNew />
              </Link>
            </div>
            <div className="resources">
              <p style={{ fontWeight: "600", fontSize: "14px" }}>
                Available Resources
              </p>

              <Link className="guide_btn">
                Fetching Data <OpenInNew />
              </Link>
              <Link className="guide_btn">
                Submitting Data <OpenInNew />
              </Link>
              <Link className="guide_btn">
                Example Data <OpenInNew />
              </Link>
            </div>
          </div>
          <div className="main_bar steps_sec">
            <div className="enpoint_top">
              <div className="top_btn_sec">
                <div className="end_left">
                  <h5>Endpoints</h5>
                  <p>
                    Create or modify endpoints to interact with your API's
                    specific functionality, organized by object type.
                  </p>
                </div>
                <div className="end_right">
                  <button onClick={createEndpointOpenModal}>
                    <Add />
                    Add New EndPoint
                  </button>
                </div>
              </div>
            </div>
            <div className="main_data_sec">
              <div className="left_sec">
                <div className="categories">
                  <h5 className="title">ENDPOINTS CATEGORIES</h5>
                  {getEndpoints &&
                    getEndpoints.map((endpointDataItem, index) => (
                      <div className="inner_item" key={index}>
                        {endpointDataItem.Endpointdata.map((endpoint, idx) => (
                          <div key={idx} className="endpoint_data">
                            <div
                              className="cate_name"
                              onClick={() =>
                                openUpdateEndpointModal(
                                  endpoint._id,
                                  endpoint?.path_parameters?.auth
                                )
                              }
                            >
                              <h5>
                                {endpoint.name}
                                <span className="method_name">
                                  {endpoint.method}
                                </span>
                              </h5>
                              <span>{endpoint.path_parameters?.auth}</span>
                            </div>
                            <div className="actions">
                              <div className="popup_sec">
                                <MoreHoriz
                                  onClick={() =>
                                    handleToggleOptions(endpoint._id)
                                  }
                                />
                                {showOptions &&
                                  openOptionsIndex === endpoint._id && (
                                    <div
                                      className="options"
                                      style={{
                                        position: "absolute",
                                      }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleOptionClick("duplicate")
                                        }
                                      >
                                        <FolderCopy />
                                        Duplicate
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleOptionClick("delete")
                                        }
                                      >
                                        <DeleteOutline />
                                        Delete
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleOptionClick("update", endpoint)
                                        }
                                      >
                                        <BuildCircle />
                                        update
                                      </button>
                                    </div>
                                  )}

                                {deleteConfirmation &&
                                  openOptionsIndex === endpoint._id && (
                                    <div className="delete-confirmation">
                                      <h5>Delete Screen</h5>
                                      <p>
                                        Are you sure you want to <br></br>{" "}
                                        delete this screen?
                                      </p>
                                      <div className="btns_sec_delete">
                                        <button
                                          onClick={() =>
                                            handleConfirmDelete2(endpoint)
                                          }
                                        >
                                          Delete
                                        </button>
                                        <button onClick={handleCancelDelete}>
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="done_btn2">
          <div className="main_btn">
            <button className="btn_close" onClick={endpointsCloseModal}>
              <ArrowBack />
              Back
            </button>
            <button className="btn_save" onClick={"closeDataModal"}>
              Save
            </button>
          </div>
        </div>
      </Modal>

      {/* Create Enpoint Modal  */}
      <Modal
        open={createEndpointModal}
        onClose={createEndpointCloseModal}
        center
        classNames={{
          modal: "api_cloud_endpoint_modal",
        }}
      >
        <Formik
          initialValues={{
            name: "",
            method: "",
            object: "",
            role: "",
            path: "",
            headers: [{ key: "", value: "" }],
          }}
          validationSchema={validationSchema2}
          onSubmit={(values, { setSubmitting }) => {
            const endpointdata = [
              {
                name: values.name,
                method: values.method,
                Object_role: {
                  name: values.object + values.role,
                },
                path_parameters: {
                  auth: values.path,
                },
                headers: Object.fromEntries(
                  values.headers.map((header) => [header.key, header.value])
                ),
              },
            ];

            const data = {
              flashlistid: flashlistId,
              projectid: projectid,
              projectname: projectname,
              endpointdata: endpointdata,
            };

            // setPathValue(values.path);

            dispatch(addEndpoints(data));
            setSubmitting(false);
            setTimeout(() => {
              createEndpointCloseModal();
            }, 1000);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, isValid, dirty }) => (
            <>
              <Form>
                <div className="top_heads">
                  <h3>Create Endpoint</h3>
                  <img src={question} alt="" className="question_icon" />
                </div>

                <div className="body_secs">
                  <div className="left_bars">
                    <img
                      src={RESTGeneric}
                      alt="rest_api"
                      width="31px"
                      height="31px"
                    />{" "}
                    <br />
                    <h6>Example Data</h6>
                    <p
                      style={{
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                      className="space_btms"
                    >
                      https://example-data.figmaclone.com
                    </p>
                    <hr style={{ border: " 1px solid #ffffff" }}></hr>
                    <div className="connect_apis">
                      <h6
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        Connecting Rest API to Draftbit
                      </h6>
                      <p>
                        To get started using a REST API service with Draftbit,
                        open the guide below and follow the steps.
                      </p>
                      <Link className="guide_btns">
                        REST API and FigmaClone Guide <OpenInNew />
                      </Link>
                    </div>
                    <div className="resourcess">
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        Available Resources
                      </p>

                      <Link className="guide_btns">
                        Fetching Data <OpenInNew />
                      </Link>
                      <Link className="guide_btns">
                        Submitting Data <OpenInNew />
                      </Link>
                      <Link className="guide_btns">
                        Example Data <OpenInNew />
                      </Link>
                    </div>
                  </div>
                  <div className="main_bar steps_sec">
                    <div className="step_1">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Step 1 -Basics</Accordion.Header>
                          <Accordion.Body>
                            <div className="top_secz">
                              <h5>Enpoint Basics</h5>
                              <p>The fundamentals for this endpoint.</p>
                            </div>
                            <hr style={{ border: " 1px solid #696969" }}></hr>

                            <div className="input_secz">
                              <div className="innerz">
                                <div className="labelz">Name</div>
                                <div className="input_mainz">
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="New endpoint"
                                    onChange={handleChange}
                                    value={values.name}
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="error"
                                  />
                                  <span>
                                    Name your endpoint after what kind of data
                                    it is retrieving. e.g. `Get Todos` or `Get
                                    User`.
                                  </span>
                                </div>
                              </div>
                              <div className="innerz">
                                <div className="labelz">Method</div>
                                <div className="input_mainz">
                                  <select
                                    name="method"
                                    id="method"
                                    onChange={(e) => handleChange(e)}
                                    value={values.method}
                                  >
                                    <option>Please Select...</option>
                                    <option value="get">GET</option>
                                    <option value="post">POST</option>
                                    <option value="put">PUT</option>
                                    <option value="patch">PATCH</option>
                                    <option value="delete">DELETE</option>
                                  </select>

                                  <span>
                                    What is the underlying REST Method this
                                    endpoint will utilize to contact your
                                    backend? e.g. `GET`, `POST`, `PUT`,
                                    `DELETE`.
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Step 2 -Object & Role
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="top_secz">
                              <h5>Endpoint Caching</h5>
                              <p>
                                We utilize objects and roles in our queries to
                                make fetching and caching data in your app
                                easier. Roles describe what this endpoint
                                intends to do, ie the verb (e.g. Get Many).
                                Objects help you organize endpoints and cache
                                data and are typically described with nouns (ie
                                posts). You should only have one object and role
                                combination (ie Get Many Posts) per API service.
                                These settings are optional but highly
                                recommended.
                              </p>
                            </div>
                            <hr style={{ border: " 1px solid #ffffff" }}></hr>

                            <div className="input_secz">
                              <div className="innerz">
                                <div className="labelz">Object</div>
                                <div className="input_mainz">
                                  {/* <Select
                                  defaultValue={selectedOption}
                                  onChange={(selected) =>
                                    setSelectedOption(selected.value)
                                  }
                                  // options={options}
                                  styles={customStyles}
                                /> */}
                                  <input
                                    type="text"
                                    name="object"
                                    value={values.object}
                                    onChange={handleChange}
                                  />
                                  <span>
                                    What type of data does this endpoint
                                    manipulate? e.g. todos, posts, sneakers,
                                    etc. For all endpoints that share the same
                                    underlying data (ie Get Posts, Create Post,
                                    Delete Post), use the same Object so that we
                                    can cache data and update the cache
                                    correctly.
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="input_secz">
                              <div className="innerz">
                                <div className="labelz">Role</div>
                                <div className="input_mainz">
                                  {/* <Select
                                  defaultValue={selectedOption}
                                  onChange={(selected) =>
                                    setSelectedOption(selected.value)
                                  }
                                  options={role}
                                  styles={customStyles}
                                /> */}

                                  <select
                                    name="role"
                                    id="role"
                                    onChange={(e) => handleChange(e)}
                                    value={values.role}
                                  >
                                    <option value="GetOne">Get One</option>
                                    <option value="GetMany">Get Many</option>
                                    <option value="Create">Create</option>
                                    <option value="Update">Update</option>
                                    <option value="Delete">Delete</option>
                                    <option value="Login">Login</option>
                                    <option value="Delete">Register</option>
                                    <option value="Delete">Me</option>
                                    <option value="Delete">
                                      Refresh Token
                                    </option>
                                  </select>

                                  <span>
                                    Choose the type of action that this endpoint
                                    represents. You can only have one type of
                                    Role per Object in an API Service.
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            Step 3 -Path & Parameters
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="top_secs">
                              <h5>Endpoint Path & Parameters</h5>
                              <p>
                                Tell us where to access the data available in
                                your REST API Endpoint, including any
                                parameters.
                              </p>
                            </div>
                            {/* <hr style={{ border: " 1px solid #ffffff" }}></hr> */}
                            <div className="input_sec dp_blocks">
                              <div className="inners">
                                <div className="labels">Path</div>
                                <span>
                                  Appended to the Base URL to complete your
                                  request. Use the "variable" format to create
                                  variables which can be filled in at app
                                  runtime.
                                </span>
                                <div className="input_mains">
                                  <input
                                    type="text"
                                    name="path"
                                    placeholder="/path/to/endpoint?q={{search}}&key={{key}}"
                                    onChange={handleChange}
                                    // value={values.path}
                                    value={
                                      values.path &&
                                      !values.path.startsWith("/")
                                        ? `/${values.path}`
                                        : values.path
                                    }
                                  />
                                  <ErrorMessage
                                    name="path"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Step 4 -Headers</Accordion.Header>
                          <Accordion.Body>
                            <div className="top_sec">
                              <h5>Endpoint Headers</h5>
                              <p>
                                Add any additional HTTP Headers to include with
                                this request. We will automatically include any
                                headers defined in your service configuration,
                                but you can override those if you need to by
                                adding them again and setting a different value.
                              </p>
                            </div>
                            {/* <div className="border_bottom"></div> */}
                            <div className="input_sec">
                              <div className="body_sec_2">
                                <div className="add_row">
                                  <span>Key</span>
                                  <span className="val">Value</span>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      values.headers.push({
                                        key: "",
                                        value: "",
                                      });
                                      handleChange({
                                        target: {
                                          name: "headers",
                                          value: values.headers,
                                        },
                                      });
                                    }}
                                  >
                                    Add Row
                                  </button>
                                </div>
                                <FieldArray
                                  name="headers"
                                  render={({ push, remove }) => (
                                    <div className="key_value_sec">
                                      {values.headers.map((header, index) => (
                                        <div
                                          key={index}
                                          className="key_value_sec"
                                        >
                                          <div className="inner">
                                            <div className="input_sec">
                                              <input
                                                type="text"
                                                name={`headers[${index}].key`}
                                                value={header.key}
                                                onChange={(e) => {
                                                  const newHeaders = [
                                                    ...values.headers,
                                                  ];
                                                  newHeaders[index].key =
                                                    e.target.value;
                                                  handleChange({
                                                    target: {
                                                      name: "headers",
                                                      value: newHeaders,
                                                    },
                                                  });
                                                }}
                                                placeholder="Accept"
                                              />
                                              <ErrorMessage
                                                name={`headers.${index}.key`}
                                                component="div"
                                                className="error"
                                              />
                                            </div>
                                            <div className="input_sec">
                                              <input
                                                type="text"
                                                name={`headers[${index}].value`}
                                                value={header.value}
                                                onChange={(e) => {
                                                  const newHeaders = [
                                                    ...values.headers,
                                                  ];
                                                  newHeaders[index].value =
                                                    e.target.value;
                                                  handleChange({
                                                    target: {
                                                      name: "headers",
                                                      value: newHeaders,
                                                    },
                                                  });
                                                }}
                                                placeholder="application/json"
                                              />
                                              <ErrorMessage
                                                name={`headers.${index}.value`}
                                                component="div"
                                                className="error"
                                              />
                                            </div>
                                            <div className="remove_btn">
                                              <button
                                                type="button"
                                                onClick={() => remove(index)}
                                                style={{
                                                  background: "transparent",
                                                  border: "none",
                                                  color: "#141a29",
                                                }}
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            Step 5 -Test & Preview Response
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="top_sec top_new_secs">
                              <h5>Endpoint Preview</h5>
                              <div className="inner_secs">
                                <input
                                  type="text"
                                  value={`${storedPath}${values.path}`}
                                  onChange={handleChangeTest}
                                  placeholder="/path/to/endpoint?q={{search}}&key={{key}}"
                                  XB
                                />
                                {localStorage.setItem("prod_url", values.path)}
                                <button
                                  className="skips"
                                  onClick={handleSkipClick}
                                >
                                  Skip
                                </button>
                                <Link
                                  className="tests"
                                  onClick={handleTestClick}
                                >
                                  Test
                                </Link>
                              </div>
                            </div>
                            <div className="input_sec">
                              <div className="body_sec">
                                <div className="api_test">
                                  {fullUrl ? (
                                    <ReactJson
                                      src={apiData}
                                      collapsed={false}
                                      theme="threezerotwofour"
                                      style={{
                                        overflowX: "scroll",
                                        padding: "20px",
                                        width: "95%",
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <Keyboard />
                                      <h5>Not Ready To Test</h5>
                                      <p>You must give your endpoint a name.</p>
                                      <Link>Read the Docs</Link>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
                <div className="done_btn2">
                  <div className="main_btn">
                    <Link
                      className="btn_close"
                      onClick={() => setCreateEndpointModal(false)}
                    >
                      <ArrowBack />
                      Back
                    </Link>
                    <button
                      className="btn_save"
                      type="submit"
                      // disabled={!isValid}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Modal>

      {/* Update Endpoint Modal */}
      <Modal
        open={updateEnpoint}
        onClose={closeUpdateEndpointModal}
        center
        classNames={{
          modal: "api_cloud_endpoint_update_modal",
        }}
      >
        <div className="top_headz">
          <h3>Update Endpoint</h3>
          <img src={question} alt="" className="question_iconz" />
        </div>

        <div className="body_secz">
          <div className="left_barz">
            <img src={RESTGeneric} alt="rest_api" width="31px" height="31px" />{" "}
            <br />
            <h6>Example Data</h6>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "24px",
              }}
              className="space_btmz"
            >
              https://example-data.figmaclone.com
            </p>
            {/* <div className="border_bottom"></div> */}
            <div className="connect_apiz">
              <h6
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Connecting Rest API to Draftbit
              </h6>
              <p>
                To get started using a REST API service with Draftbit, open the
                guide below and follow the steps.
              </p>
              <Link className="guide_btnz">
                REST API and FigmaClone Guide <OpenInNew />
              </Link>
            </div>
            <div className="resourcesz">
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Available Resources
              </p>

              <Link className="guide_btnz">
                Fetching Data <OpenInNew />
              </Link>
              <Link className="guide_btnz">
                Submitting Data <OpenInNew />
              </Link>
              <Link className="guide_btnz">
                Example Data <OpenInNew />
              </Link>
            </div>
          </div>
          <div className="main_bar steps_sec">
            <div className="api_step_z">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Step 1 -Basics</Accordion.Header>
                  <Accordion.Body>
                    <div className="top_secz">
                      <h5>Enpoint Basics</h5>
                      <p>The fundamentals for this endpoint.</p>
                    </div>
                    {/* <div className="border_bottom"></div>{" "} */}
                    <div className="input_secz">
                      <div className="innerz">
                        <div className="labelz">Name</div>
                        <div className="input_mainz">
                          <input
                            type="text"
                            placeholder="endpoint"
                            value={endpointData.name}
                            onChange={(e) =>
                              handleChangeInputEdit("name", e.target.value)
                            }
                          />
                          <span>
                            Name your endpoint after what kind of data it is
                            retrieving. e.g. `Get Todos` or `Get User`.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input_secz">
                      <div className="innerz">
                        <div className="labelz">Method</div>
                        <div className="input_mainz">
                          <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            styles={customStyles}
                            // value={endpointData.method}
                          />
                          <span>
                            What is the underlying REST Method this endpoint
                            will utilize to contact your backend? e.g. `GET`,
                            `POST`, `PUT`, `DELETE`.
                          </span>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Step 2 -Object & Role</Accordion.Header>
                  <Accordion.Body>
                    <div className="top_secz">
                      <h5>Endpoint Caching</h5>
                      <p>
                        We utilize objects and roles in our queries to make
                        fetching and caching data in your app easier. Roles
                        describe what this endpoint intends to do, ie the verb
                        (e.g. Get Many). Objects help you organize endpoints and
                        cache data and are typically described with nouns (ie
                        posts). You should only have one object and role
                        combination (ie Get Many Posts) per API service. These
                        settings are optional but highly recommended.
                      </p>
                    </div>
                    <hr></hr>
                    <div className="input_secz">
                      <div className="innerz">
                        <div className="labelz">Object</div>
                        <div className="input_mainz">
                          <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            // options={options}
                            styles={customStyles}
                            value={endpointData?.Object_role?.name}
                          />
                          <span>
                            What type of data does this endpoint manipulate?
                            e.g. todos, posts, sneakers, etc. For all endpoints
                            that share the same underlying data (ie Get Posts,
                            Create Post, Delete Post), use the same Object so
                            that we can cache data and update the cache
                            correctly.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input_secz">
                      <div className="innerz">
                        <div className="labelz">Method</div>
                        <div className="input_mainz">
                          <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={role}
                            styles={customStyles}
                          />

                          <span>
                            Choose the type of action that this endpoint
                            represents. You can only have one type of Role per
                            Object in an API Service.
                          </span>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Step 3 -Path & Parameters</Accordion.Header>
                  <Accordion.Body>
                    <div className="top_sec">
                      <h5>Endpoint Path & Parameters</h5>
                      <p>
                        Tell us where to access the data available in your REST
                        API Endpoint, including any parameters.
                      </p>
                    </div>
                    <hr></hr>
                    <div className="input_sec dp_blockz">
                      <div className="innerz">
                        <div className="labelz">Path</div>
                        <span>
                          Appended to the Base URL to complete your request. Use
                          the "variable" format to create variables which can be
                          filled in at app runtime.
                        </span>
                        <div className="input_mainz">
                          <input
                            type="text"
                            placeholder="/path/to/endpoint?q={{search}}&key={{key}}"
                            value={endpointData?.path_parameters?.auth}
                            onChange={(e) =>
                              handleChangeInputEdit(
                                "path_parameters",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Step 4 -Headers</Accordion.Header>
                  <Accordion.Body>
                    <div className="top_sec">
                      <h5>Endpoint Headers</h5>
                      <p>
                        Add any additional HTTP Headers to include with this
                        request. We will automatically include any headers
                        defined in your service configuration, but you can
                        override those if you need to by adding them again and
                        setting a different value.
                      </p>
                    </div>
                    <hr></hr>
                    <div className="input_sec">
                      <div className="body_sec_2">
                        <div className="add_row">
                          <span>Key</span>
                          <span className="val">Value</span>
                          <button onClick={handleAddFields}>Add Row</button>
                        </div>
                        <div className="key_value_sec">
                          {inputFields.map((inputField, index) => (
                            <div key={index} className="key_value_sec">
                              <div className="inner">
                                <div className="input_sec">
                                  <input
                                    type="text"
                                    name="key"
                                    value="accept"
                                    onChange={(event) =>
                                      handleChangeInput(index, event)
                                    }
                                    placeholder="Accept"
                                  />
                                </div>
                                <div className="input_sec">
                                  <input
                                    type="text"
                                    name="value"
                                    value={endpointData?.headers?.Accept}
                                    onChange={(event) =>
                                      handleChangeInput(index, event)
                                    }
                                    placeholder="application/json"
                                  />
                                </div>
                                <div className="remove_btn">
                                  <Remove
                                    onClick={() => handleRemoveFields(index)}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    Step 5 -Test & Preview Response
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="top_sec top_new_sec">
                      <h5>Endpoint Preview</h5>
                      <div className="inner_sec5">
                        <input
                          type="text"
                          placeholder="http://34.229.194.55:8888/api/vi"
                        />
                        <button className="skip">Skip</button>
                        <button className="test">Test</button>
                      </div>
                    </div>
                    <div className="input_sec">
                      <div className="body_sec">
                        <div className="api_test">
                          <Keyboard />
                          <h5>Not Ready To Test</h5>
                          <p>You must give your endpoint a name.</p>
                          <Link>Read the Docs</Link>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </div>
          </div>
        </div>
        <div className="done_btn2">
          <div className="main_btn">
            <button className="btn_close" onClick={closeUpdateEndpointModal}>
              <ArrowBack />
              Back
            </button>
            <Link className="btn_save" onClick={handleSaveEndpoint}>
              Save
            </Link>
          </div>
        </div>
      </Modal>

      {/* Edit Rest API Modal */}
      <Modal
        open={editRestApi}
        onClose={closeEditRestApiModal}
        center
        classNames={{
          modal: "api_cloud_assets_modal",
        }}
      >
        <div className="top_head">
          <h3>Edit Rest API</h3>
          <img src={question} alt="" className="question_icon" />
        </div>

        <div className="body_sec">
          <div className="left_bar">
            <img src={RestApi} alt="rest_api" width="31px" height="31px" />{" "}
            <br />
            <h6>Rest API</h6>
            <p style={{ fontSize: "14px", lineHeight: "24px" }}>
              Add data to your app and connect your backend, API's, and cloud
              services to bring your app to life.
            </p>
            <hr></hr>
            <div className="connect_api">
              <h6 style={{ fontWeight: "600", fontSize: "14px" }}>
                Connecting Rest API to Draftbit
              </h6>
              <p>
                To get started using a REST API service with Draftbit, open the
                guide below and follow the steps.
              </p>
              <Link className="guide_btn">
                REST API and FigmaClone Guide <OpenInNew />
              </Link>
            </div>
            <div className="resources">
              <p style={{ fontWeight: "600", fontSize: "14px" }}>
                Available Resources
              </p>

              <Link className="guide_btn">
                Fetching Data <OpenInNew />
              </Link>
              <Link className="guide_btn">
                Submitting Data <OpenInNew />
              </Link>
              <Link className="guide_btn">
                Example Data <OpenInNew />
              </Link>
            </div>
          </div>
          <div className="main_bar steps_sec">
            <div className="step_1">
              <div className="title_sec">
                <KeyboardArrowDown /> Step 1 -API Basics
              </div>

              <div className="body_sec_2">
                <div className="input_sec">
                  <div className="title">
                    <p>Name</p>
                  </div>
                  <div className="input_field_new">
                    <input
                      type="text"
                      placeholder="Service name"
                      value={editedFlashlistData.name}
                      onChange={(event) =>
                        handleChangeInputEdit("name", event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="input_sec">
                  <div className="title">
                    <p>Dev URL</p>
                  </div>
                  <div className="input_field_new">
                    <input
                      type="text"
                      placeholder="https://api-staging.example.com"
                      value={editedFlashlistData.dev_url}
                      onChange={(event) =>
                        handleChangeInputEdit("dev_url", event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="input_sec">
                  <div className="title">
                    <p>
                      Prod URL{" "}
                      <Tooltip
                        title="Production URL 

If this value is set, this URL will be used when publishing in the Production environment. If unset or the environment is Development, the Dev URL will be used."
                        arrow
                      >
                        <InformationIcon />
                      </Tooltip>
                    </p>
                  </div>
                  <div className="input_field_new">
                    <input
                      type="text"
                      placeholder="https://api.example.com"
                      value={editedFlashlistData.prod_url}
                      onChange={(event) =>
                        handleChangeInputEdit("prod_url", event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="step_1 step_2">
              <div className="title_sec">
                <KeyboardArrowDown /> Step 2 - Headers & Authentication
              </div>
              <div className="mid_sec">
                <p>
                  HTTP Headers to include with all requests that use this API.
                </p>
              </div>
              <div className="body_sec_2">
                <div className="add_row">
                  <span>Key</span>
                  <span className="val">Value</span>
                  <button onClick={handleAddFields}>Add Row</button>
                </div>
                <div className="key_value_sec">
                  {inputFields.map((inputField, index) => (
                    <div key={index} className="key_value_sec">
                      <div className="inner">
                        <div className="input_sec">
                          <input
                            type="text"
                            name="key"
                            value={inputField.key}
                            onChange={(event) =>
                              handleChangeInput(index, event)
                            }
                            placeholder="Accept"
                          />
                        </div>
                        <div className="input_sec">
                          <input
                            type="text"
                            name="value"
                            value={inputField.value}
                            onChange={(event) =>
                              handleChangeInput(index, event)
                            }
                            placeholder="application/json"
                          />
                        </div>
                        <div className="remove_btn">
                          <Remove onClick={() => handleRemoveFields(index)} />
                        </div>
                      </div>

                      {/* <button onClick={() => handleRemoveFields(index)}>
                          Remove
                        </button> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="done_btn2">
          <div className="main_btn">
            <button className="btn_close" onClick={closeEditRestApiModal}>
              <ArrowBack />
              Close
            </button>
            <button className="btn_save" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApiCloudServicesModal;
