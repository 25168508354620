import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import info_icon from "../../../../assets/Icons/info_icon.svg";
import Switch from "react-switch";
import InformationIcon from "../../../../assets/Iconcomponent/InformationIcon";
const AdvancedComponent = ({
  handleIconClick,
  showTooltipData,
  setTooltipContent,
  tooltipContent,
  ToolTipBox,
}) => {
  const [checked, setChecked] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("");

  return (
    <div>
      <div className="scroll-sec">
        <Accordion flush defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Advanced </Accordion.Header>
            <Accordion.Body>
              <div className="box-1">
                <div className="adjuct_img">
                  <p>adjustsFontSize</p>
                  <ToolTipBox
                    iconType="green"
                    type="adjustsFontSize"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />{" "}
                </div>
                <div className="toggle-btn">
                  <Switch
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                    }}
                    onColor="#3366ff"
                    onHandleColor="#2693e6"
                    handleDiameter={29}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
              </div>
              <div className="box-2">
                <div className="adjuct_img">
                  <p>Allow Font Scaling</p>
                  <ToolTipBox
                    iconType="green"
                    type="Allow Font Scaling"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />{" "}
                </div>
                <div className="toggle_btn">
                  <Switch
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                    }}
                    onColor="#3366ff"
                    onHandleColor="#2693e6"
                    handleDiameter={29}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
              </div>

              <div className="access_box">
                <div className="acess-label">
                  <p>dataDetectorType</p>
                  <ToolTipBox
                    type="dataDetectorType"
                    iconType="green"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />{" "}
                </div>

                <div className="option-slt">
                  <select
                    onChange={(e) => setSelectedCategorie(e.target.value)}
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    <option value="" hidden>
                      select type
                    </option>
                    <option value="none">phoneNumber</option>
                    <option value="button">link</option>
                    <option value="link">link</option>
                    <option value="header">email</option>
                    <option value="search">none</option>
                    <option value="image">all</option>
                  </select>
                </div>
              </div>
              <div className="box-5">
                <div className="max_info">
                  <p>maxFontSizeMultiplier</p>
                  <ToolTipBox
                    iconType="green"
                    type="maxFontSizeMultiplier"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />{" "}
                </div>
                <div className="max_input">
                  <input
                    type="text"
                    name="max_font_size"
                    placeholder="Write your type"
                  />
                </div>
              </div>
              {/* <div className="box-3">
                <div className="adjuct_img">
                  <p>Allow Font Scaling</p>
                  <ToolTipBox
                    type="Allow Font Scaling"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />{" "}
                </div>
                <div className="toggle_btn">
                  <Switch
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                    }}
                    onColor="#3366ff"
                    onHandleColor="#2693e6"
                    handleDiameter={29}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
              </div> */}
              {/* <div className="box-4">
                <div className="adjuct_img">
                  <p>Allow Font Scaling</p>
                  <ToolTipBox
                    type="Allow Font Scaling"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />{" "}
                </div>
                <div className="toggle_btn">
                  <Switch
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                    }}
                    onColor="#3366ff"
                    onHandleColor="#2693e6"
                    handleDiameter={29}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
              </div> */}

              <div className="access_box">
                <div className="acess-label">
                  <p>textBreakStrategy</p>
                  <ToolTipBox
                    iconType="green"
                    type="textBreakStrategy"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />{" "}
                </div>

                <div className="option-slt">
                  <select
                    onChange={(e) => setSelectedCategorie(e.target.value)}
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    <option hidden>select your text break</option>
                    <option value="someOption">simple</option>
                    <option value="otherOption">highQuality</option>
                    <option value="otherOption">balanced</option>
                  </select>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default AdvancedComponent;
