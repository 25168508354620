import React from "react";
import { ToastContainer } from "react-toastify";
import DashboardData from "./DashboardData";
import DashboardHeader from "../../Componets/DashboardHeader/DashboardHeader";
import Profile from "./Profile/Profile";
import { useLocation } from "react-router-dom";

export const Dashboard = () => {
  const location = useLocation();

  return (
    <>
      <ToastContainer />
      <DashboardHeader />
      {location.pathname === "/dashboard" && <DashboardData />}
      {location.pathname === "/profile" && <Profile />}
    </>
  );
};
