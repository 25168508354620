import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import ModalDelete from "../svgComponents/ModalDelete";

const DeleteConfirmationPopup = ({
  openModal,
  onCloseModal1,
  handleDeleteConfirmationBlock,
}) => {
  const handleDelete = () => {
    handleDeleteConfirmationBlock();
    onCloseModal1();
  };
  return (
    <>
      <Modal
        open={openModal}
        onClose={onCloseModal1}
        center
        classNames={{
          modal: "delete_confirm_popup",
        }}
      >
        <div className="header_bar">
          <div className="svg_trash">
            <ModalDelete />
          </div>
        </div>
        <div className="body_sec">
          <h4>Are You Sure?</h4>
          <p>This item will be permanently deleted?</p>
        </div>

        <div className="btn_sec">
          <div className="del_btn">
            <button onClick={handleDelete}>Delete</button>
          </div>
          <div className="cancel_btn">
            <button onClick={onCloseModal1}>Cancel</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteConfirmationPopup;
