import React from "react";

const JustifyCenterSecond = ({ selectedFolder }) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="brd"
      style={{
        background:
          selectedFolder?.alignContent === "center" ? "#1A237E" : "none",
      }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5 0H5.5V16H4.5V0Z"
        fill={selectedFolder?.alignContent === "center" ? "white" : "#1A237E"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 8.5C7.26522 8.5 7.51957 8.60536 7.70711 8.79289C7.89464 8.98043 8 9.23478 8 9.5V11.5C8 11.7652 7.89464 12.0196 7.70711 12.2071C7.51957 12.3946 7.26522 12.5 7 12.5H3C2.73478 12.5 2.48043 12.3946 2.29289 12.2071C2.10536 12.0196 2 11.7652 2 11.5V9.5C2 9.23478 2.10536 8.98043 2.29289 8.79289C2.48043 8.60536 2.73478 8.5 3 8.5H7ZM9 3.5C9.26522 3.5 9.51957 3.60536 9.70711 3.79289C9.89464 3.98043 10 4.23478 10 4.5V6.5C10 6.76522 9.89464 7.01957 9.70711 7.20711C9.51957 7.39464 9.26522 7.5 9 7.5H1C0.734784 7.5 0.48043 7.39464 0.292893 7.20711C0.105357 7.01957 0 6.76522 0 6.5V4.5C0 4.23478 0.105357 3.98043 0.292893 3.79289C0.48043 3.60536 0.734784 3.5 1 3.5H9Z"
        fill={selectedFolder?.alignContent === "center" ? "white" : "#1A237E"}
      />
    </svg>
  );
};

export default JustifyCenterSecond;
