import React from "react";

const ClipSvg = () => {
  return (
    <div>
      <svg
        width="22"
        height="22"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0998 8.21373L9.03402 12.2795C8.54577 12.7677 7.75452 12.7677 7.26627 12.2795C6.77802 11.7912 6.77802 11 7.26627 10.5117L11.332 6.44598C12.3083 5.46973 13.8913 5.46973 14.8675 6.44598C15.8438 7.42223 15.8438 9.00523 14.8675 9.98148L10.8018 14.0472C9.33727 15.5117 6.96302 15.5117 5.49852 14.0472C4.03402 12.5827 4.03402 10.2085 5.49852 8.74398L9.38752 4.85498"
          stroke="#3c4c6e"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  );
};

export default ClipSvg;
