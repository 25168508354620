import React, { useState, useEffect } from "react";
import logo from "../../assets/Icons/logoipsum.svg";
import profile from "../../assets/Image/profile.jpg";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../redux/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../redux/actions/authActions";

import {
  getSdkVersionResourceList,
  updateMySdkVersionDashboard,
} from "../../redux/actions/defaultActions";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [currentVersion, setCurrentVersion] = useState();

  const access_token = localStorage.getItem("access_token");
  const refreshtoken = localStorage.getItem("refreshtoken");
  const user_id = localStorage.getItem("user_id");
  const projectid = localStorage.getItem("project_id");

  const onCloseModal = () => setOpenModal(false);

  useEffect(() => {
    dispatch(userProfile());
    dispatch(getSdkVersionResourceList());
  }, []);

  const currentVersionSdk = localStorage.getItem("userSdkVersion");

  const getSdkResourceList = useSelector(
    (state) => state?.default?.getSdkResourceList
  );

  useEffect(() => {
    if (getSdkResourceList) {
      const valuesof = getSdkResourceList?.find(
        (data) => data?.latest === true
      );

      if (currentVersionSdk !== valuesof?.version) {
        setOpenModal(true);
      }

      setCurrentVersion(valuesof?.version);
    }
  }, [getSdkResourceList]);

  // const handleLogout = async () => {
  //   try {
  //     const response = await axiosInstance.post(
  //       "logout",
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       toast.success(response?.data?.result?.message);

  //       localStorage.clear("access_token");
  //       navigate("/");
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   }
  // };

  const handleLogout = async () => {
    try {
      const response = await axiosInstance.post(
        "logout",
        { refreshtoken: refreshtoken }, // Include your refresh token here
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(response?.data?.result?.message);

        localStorage.clear("access_token");
        navigate("/");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  const getProfile = useSelector((state) => state?.auth?.getProfile);

  const openUpdateModal = () => {
    if (currentVersionSdk !== currentVersion) {
      setOpenModal(true);
    }
  };

  const updateVersion = () => {
    dispatch(updateMySdkVersionDashboard(currentVersionSdk, setOpenModal));
  };

  return (
    <>
      <div className="main-dash-header">
        <div className="coustom_container">
          <div className="inner">
            <div className="left-sec">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="right-sec">
              <Link className="logout-btn" onClick={handleLogout}>
                Logout
              </Link>
              <div className="account_setting">
                <Link to="/profile">
                  <img src={profile} alt="profile" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
