import React, { useEffect, useRef, useState } from "react";
import CrossSvg from "../svgComponents/CrossSvg";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import DownArrowSvg from "../svgComponents/DownArrowSvg";
import InfoSvg from "../svgComponents/InfoSvg";
import ClipSvg from "../svgComponents/ClipSvg";
import DeleteSvg from "../svgComponents/DeleteSvg";
import ViewSvg from "../svgComponents/ViewSvg";
import TextSvg from "../svgComponents/TextSvg";
import ImageSvg from "../svgComponents/ImageSvg";
import { useDispatch, useSelector } from "react-redux";
import {
  setFolderStructure,
  setSelectedFolder,
} from "../../redux/actions/defaultActions";
import { getSaveBlock } from "../../redux/actions/defaultActions";
import { deleteMySaveBlock } from "../../redux/actions/defaultActions";
import { toast } from "react-toastify";
import FlashListSvg from "../svgComponents/FlashListSvg";
import FetchSvg from "../svgComponents/FetchSvg";
import PressableSvg from "../svgComponents/PressableSvg";
import DatePickerSvg from "../svgComponents/DatePickerSvg";
import TabBarsSvg from "../svgComponents/TabBarsSvg";
import TextFieldSvg from "../svgComponents/TextFieldSvg";
import ButtonsSvg from "../svgComponents/ButtonsSvg";
import BottomSheetSvg from "../svgComponents/BottomSheetSvg";
import Tabbar from "../svgComponents/Tabbar";

const AddComponentPopup = ({ closeModalComponent, folder }) => {
  const dispatch = useDispatch();
  const [showBits, setShowBits] = useState(true);
  const [showBlocks, setShowBlocks] = useState(false);
  const [versionComponents, setVersionComponents] = useState({});
  const [saveBlock, setSaveBlock] = useState({});
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(null);

  const {
    getMySaveBlock,
    addNewSaveBlock,
    deleteMyNewSaveBlock,
    folderStructure,
    selectedFolder,
    getMySdkVersionList,
    getSdkResourceList,
  } = useSelector((state) => state.default);

  const fontWeights = [
    { value: "regular", label: "Regular" },
    { value: "bold", label: "Bold" },
    { value: "medium", label: "Medium" },
    { value: "100", label: "100" },
    { value: "200", label: "200" },
    { value: "300", label: "300" },
  ];

  const myCustomBlock = localStorage.getItem("project_id");
  const myCurrentsdk = localStorage?.getItem("sdkVersion");

  useEffect(() => {
    dispatch(getSaveBlock(myCustomBlock));
  }, [addNewSaveBlock, deleteMyNewSaveBlock]);

  const { v4: uuidv4 } = require("uuid");

  // const uuidv4 = () => {
  //   return "id" + Date.now() + Math.floor(Math.random() * 100);
  // };
  const fileInputRef = useRef(null);

  const openBlocks = () => {
    setShowBits(false);
    setShowBlocks(true);
  };
  const openBits = () => {
    setShowBlocks(false);
    setShowBits(true);
  };

  const openModal = (data, item) => {
    setSaveBlock(item);
    setDeleteConfirmationModal(true);
  };

  const closeModal = () => {
    setDeleteConfirmationModal(false);
  };
  const handleUniqueId = () => {
    return Math.random().toString(36).substring(2, 9);
  };

  const deepUpdateCustomDataType = (folder, isCustomType) => {
    return {
      ...folder,
      ...(folder.customDataType === false ? { customDataType: true } : {}),
      children: folder.children?.map((child) =>
        deepUpdateCustomDataType(child, isCustomType)
      ),
    };
  };

  const mapFolderStructure = (folders, selectedFolderId, customBlocks) => {
    return folders.map((folder) => {
      if (folder.id === selectedFolderId) {
        const updatedChildren = folder.children
          ? [...folder.children, deepUpdateCustomDataType(customBlocks, true)]
          : [deepUpdateCustomDataType(customBlocks, true)];

        return {
          ...folder,
          children: updatedChildren,
        };
      } else if (folder.children && folder.children.length > 0) {
        return {
          ...folder,
          children: mapFolderStructure(
            folder.children,
            selectedFolderId,
            customBlocks
          ),
        };
      } else {
        return folder;
      }
    });
  };

  useEffect(() => {
    if (myCurrentsdk) {
      const datas = getSdkResourceList?.find(
        (data) => data.version === myCurrentsdk
      );
      setVersionComponents(datas?.components);
    }
  }, [myCurrentsdk, getSdkResourceList]);

  const handleClickblockdata = (customBlocks) => {
    const uniqueId = uuidv4();
    let customBlocksWithUniqueId;

    if (customBlocks?.data?.type === "fetch") {
      customBlocksWithUniqueId = {
        ...customBlocks.data,
        id: uniqueId,
        customDataType: true,
        name: customBlocks?.name,
        children: updateFirstChildAndDescendants(customBlocks.data.children),
      };
    } else {
      customBlocksWithUniqueId = {
        ...customBlocks.data,
        id: uniqueId,
        customDataType: true,
        name: customBlocks?.name,
        children: updateChildrenWithUniqueIds(customBlocks.data.children),
      };
    }

    if (selectedFolder && selectedFolder.isFolder) {
      const updatedFolderStructure = mapFolderStructure(
        folderStructure,
        selectedFolder.id,
        customBlocksWithUniqueId
      );
      dispatch(setFolderStructure(updatedFolderStructure));
    } else {
      toast.error("Cannot add block to the selected item");
    }
  };

  const updateChildrenWithUniqueIds = (children = []) => {
    return children.map((child) => ({
      ...child,
      id: uuidv4(),
      children: updateChildrenWithUniqueIds(child.children),
    }));
  };

  const updateFirstChildAndDescendants = (children = []) => {
    if (children.length === 0) return children;
    return children.map((child, index) => {
      if (index === 0) {
        return {
          ...child,
          id: child.id + new Date().getSeconds() * 2,
          children: updateDescendants(child.children),
        };
      }

      return child;
    });
  };

  const updateDescendants = (children = []) => {
    return children.map((child) => ({
      ...child,
      id: child.id + new Date().getSeconds(),
      children: updateDescendants(child.children),
    }));
  };

  const findAndUpdateFolder = (
    folders,
    selectedFolderId,
    newChild,
    parentId
  ) => {
    return folders.map((folder) => {
      const updatedFolder = { ...folder, parentId: parentId };
      if (folder.id === selectedFolderId) {
        return {
          ...updatedFolder,
          children: [...(folder.children || []), newChild],
        };
      } else if (folder.children) {
        return {
          ...updatedFolder,
          children: findAndUpdateFolder(
            folder.children,
            selectedFolderId,
            newChild,
            folder.id
          ),
        };
      } else {
        return updatedFolder;
      }
    });
  };

  const handleAddImage = (parentId, parentType) => {
    if (
      (selectedFolder && selectedFolder.isFolder) ||
      selectedFolder?.type === "flashlist"
    ) {
      let flash = false;
      if (selectedFolder?.type === "flashlist") {
        flash = true;
      } else if (selectedFolder?.flashlistTypeValue === true) {
        flash = true;
      }

      if (selectedFolder?.type === "tabview") {
        toast.error("Cannot add image inside the tab view");
        return;
      }
      const newImage = {
        name: `Image `,
        id: uuidv4(),
        image: "",
        type: "image",
        versionAccess: "v1/v2",
        flashUniqueId: selectedFolder?.flashId ? uuidv4() : false,
        flashlistType: flash,
        flashId: selectedFolder?.flashId ? selectedFolder?.flashId : false,
        lastSelectedKey: "url",
        gridTemplateColumns: "",
        variableName: "",
        flashlistTypeValue:
          selectedFolder.flashlistType === true
            ? true
            : selectedFolder?.type === "flashlist"
              ? true
              : false,
        size:
        {
          width: "100",
          height: "100",
          min_width: "100",
          min_height: "100",
          max_width: "100",
          max_height: "100"
        },

        margin: {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        borderWidth:
        {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        }
        ,
        borderRadius:
        {
          all: "0",
          topLeft: "0",
          topRight: "0",
          bottomLeft: "0",
          bottomRight: "0",
        },

        parentId: parentId,
        parentType: parentType,
        borderStyle: "auto",
        borderColor: "#000",
        flashMap: true,
        sourceType: "Static",
        customDataType: false,
      };

      const updatedSelectedFolder = {
        ...selectedFolder,
        children: [...selectedFolder?.children, newImage],
      };

      const updatedFolderStructure = findAndUpdateFolder(
        folderStructure,
        selectedFolder.id,
        newImage
      );

      dispatch(setFolderStructure(updatedFolderStructure));
      dispatch(setSelectedFolder(updatedSelectedFolder));
    } else {
      toast.error("Cannot add image to the selected item");
    }
  };

  const handleCreateView = (parentId, parentType) => {
    if (
      (selectedFolder && selectedFolder.isFolder) ||
      selectedFolder?.type === "flashlist"
    ) {
      let flash = false;
      if (selectedFolder?.type === "flashlist") {
        flash = true;
      } else if (selectedFolder?.flashlistTypeValue === true) {
        flash = true;
      }

      if (selectedFolder?.type === "tabview") {
        toast.error("Cannot add view inside the tab view");
        return;
      }

      const newView = {
        id: uuidv4(),
        name: `View `,
        isFolder: true,

        flashUniqueId: selectedFolder?.flashId ? uuidv4() : false,
        children: [],
        viewBlock: "",
        type: "view",
        background: "#ffffff",
        versionAccess: "v1/v2",

        flashId: selectedFolder?.flashId ? selectedFolder?.flashId : false,
        flashlistType: flash,
        flashlistTypeValue:
          selectedFolder.flashlistType === true
            ? true
            : selectedFolder?.type === "flashlist"
              ? true
              : false,
        size:
        {
          width: "auto",
          height: "auto",
          min_width: "auto",
          min_height: "auto",
          max_width: "auto",
          max_height: "auto"
        },


        margin: {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        padding: {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        borderWidth:
        {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        borderRadius:
        {
          all: "0",
          topLeft: "0",
          topRight: "0",
          bottomLeft: "0",
          bottomRight: "0",
        },
        direction: "column",
        align: "flex-start",
        justify: "flex-start",
        parentId: parentId,
        parentType: parentType,
        alignitem: "flex-start",
        alignContent: "flex-start",
        flexWrap: "auto",
        display: "flex",
        gridColumn: "0",
        gridRow: "0",
        borderStyle: "double",
        borderColor: "#000",
        flashMap: true,
        customDataType: false,
        scrollable: false,
      };

      const updatedSelectedFolder = {
        ...selectedFolder,
        children: [...selectedFolder.children, newView],
      };

      const updatedFolderStructure = findAndUpdateFolder(
        folderStructure,
        selectedFolder.id,
        newView,
        selectedFolder.id
      );

      dispatch(setFolderStructure(updatedFolderStructure));
      dispatch(setSelectedFolder(updatedSelectedFolder));
    } else {
      toast.error("Cannot add view to the selected item");
    }
  };

  const handleCreatePresseable = (parentId, parentType) => {
    if (
      (selectedFolder && selectedFolder.isFolder) ||
      selectedFolder?.type === "flashlist"
    ) {
      let flash = false;
      if (selectedFolder?.type === "flashlist") {
        flash = true;
      } else if (selectedFolder?.flashlistTypeValue === true) {
        flash = true;
      }
      const newView = {
        id: uuidv4(),
        name: `Pressable`,
        isFolder: true,
        flashUniqueId: selectedFolder?.flashId ? uuidv4() : false,
        children: [],
        versionAccess: "v1/v2",
        onPress: "",
        onLongPress: "",
        type: "pressable",
        background: "#ffffff",
        flashId: selectedFolder?.flashId ? selectedFolder?.flashId : false,
        flashlistType: flash,
        flashlistTypeValue:
          selectedFolder.flashlistType === true
            ? true
            : selectedFolder?.type === "flashlist"
              ? true
              : false,
        size:
        {
          width: "auto",
          height: "auto",
          min_width: "auto",
          min_height: "auto",
          max_width: "auto",
          max_height: "auto"
        },


        margin: {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        padding: {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        borderWidth:
        {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        borderRadius:
        {
          all: "0",
          topLeft: "0",
          topRight: "0",
          bottomLeft: "0",
          bottomRight: "0",
        },
        direction: "column",
        align: "flex-start",
        justify: "flex-start",
        parentId: parentId,
        parentType: parentType,
        alignitem: "flex-start",
        alignContent: "flex-start",
        flexWrap: "auto",
        display: "flex",
        gridColumn: "0",
        gridRow: "0",
        borderStyle: "double",
        borderColor: "#000",
        flashMap: true,
        customDataType: false,
        scrollable: false,
      };

      const updatedSelectedFolder = {
        ...selectedFolder,
        children: [...selectedFolder.children, newView],
      };

      const updatedFolderStructure = findAndUpdateFolder(
        folderStructure,
        selectedFolder.id,
        newView,
        selectedFolder.id
      );

      dispatch(setFolderStructure(updatedFolderStructure));
      dispatch(setSelectedFolder(updatedSelectedFolder));
    } else {
      toast.error("Cannot add view to the selected item");
    }
  };

  const handleAddText = (parentId, parentType) => {
    if (
      (selectedFolder && selectedFolder.isFolder) ||
      selectedFolder?.type === "flashlist"
    ) {
      let flash = false;
      if (selectedFolder?.type === "flashlist") {
        flash = true;
      } else if (selectedFolder?.flashlistTypeValue === true) {
        flash = true;
      }

      if (selectedFolder?.type === "tabview") {
        toast.error("Cannot add text inside the tab view");
        return;
      }

      const newText = {
        name: `Text `,
        id: uuidv4(),
        flashUniqueId: selectedFolder?.flashId ? uuidv4() : false,
        canvas_text: "",
        type: "text",
        versionAccess: "v1/v2",
        customDataType: false,
        parentId: parentId,
        parentType: parentType,
        lastSelectedKey: "name",
        variableName: "",
        color: "#000000",
        fontSize: "16",
        letterSpacing: "0",
        lineHeight: "16",
        fontFamily: "Arial",
        textTransform: "auto",
        textAlign: "auto",
        textdecoration: "auto",
        borderStyle: "double",
        borderColor: "#000",
        flashMap: true,
        flashId: selectedFolder?.flashId ? selectedFolder?.flashId : false,
        flashlistType: flash,
        flashlistTypeValue:
          selectedFolder.flashlistType === true
            ? true
            : selectedFolder?.type === "flashlist"
              ? true
              : false,
        fontWeight: fontWeights.map((fontWeight) => ({
          Weight: fontWeight.value,
        })),
        margin: {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        padding: {
          all: "0",
          left: "5",
          top: "5",
          right: "5",
          bottom: "5"
        },
        borderWidth:
        {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        borderRadius:
        {
          all: "0",
          topLeft: "0",
          topRight: "0",
          bottomLeft: "0",
          bottomRight: "0",
        },
      };

      const updatedSelectedFolder = {
        ...selectedFolder,
        children: [...selectedFolder?.children, newText],
      };
      const updatedFolderStructure = findAndUpdateFolder(
        folderStructure,
        selectedFolder.id,
        newText,
        selectedFolder.id
      );
      dispatch(setFolderStructure(updatedFolderStructure));
      dispatch(setSelectedFolder(updatedSelectedFolder));
    } else {
      toast.error("Cannot add text to the selected item");
    }
  };

  const handleCreateFetch = (parentId, parentType) => {
    if (selectedFolder && selectedFolder.isFolder) {
      if (selectedFolder?.type === "tabview") {
        toast.error("Cannot add fetch inside the tab view");
        return;
      }

      const newFetch = {
        id: uuidv4(),
        name: `Fetch `,
        type: "fetch",
        parentId: parentId,
        parentType: parentType,
        versionAccess: "v1/v2",
        fetchUrl: "", // Add any additional properties relevant to fetch
        children: [], // Initialize children array
        flashMap: true,
        endpointId: "0",
        mergeResponse: "response",
        services: "select service",
        endpoint: "select endpoint",
        flashlistDataId: "0",

      };

      const updatedSelectedFolder = {
        ...selectedFolder,
        children: [...selectedFolder.children, newFetch],
      };

      const updatedFolderStructure = findAndUpdateFolder(
        folderStructure,
        selectedFolder.id,
        newFetch,
        selectedFolder.id
      );

      dispatch(setFolderStructure(updatedFolderStructure));
      dispatch(setSelectedFolder(updatedSelectedFolder));
    }
  };

  const handleCreateFlashList = (parentId, parentType) => {
    if (selectedFolder && selectedFolder.type === "fetch") {
      if (selectedFolder?.type === "tabview") {
        toast.error("Cannot add flashlist inside the tab view");
        return;
      }
      const newFlashList = {
        id: uuidv4(),
        name: `FlashList `,
        type: "flashlist",
        parentId: parentId,
        parentType: parentType,
        children: [],
        versionAccess: "v1/v2",

        background: "#ffffff",
        direction: "column",
        flexWrap: "auto",
        display: "flex",
        gridTemplateColumns: "",
        flashId: parentId,
        flashMap: true,
        response: "response",
        est_item_size: "",

        size:
        {
          width: "100",
          height: "100",
          min_width: "0",
          min_height: "0",
          max_width: "0",
          max_height: "0"
        },

        margin: {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        padding: {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        borderWidth:
        {
          all: "0",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        borderRadius:
        {
          all: "0",
          topLeft: "0",
          topRight: "0",
          bottomLeft: "0",
          bottomRight: "0",
        },
      };

      const updatedSelectedFolder = {
        ...selectedFolder,
        children: [...selectedFolder.children, newFlashList],
      };

      const updatedFolderStructure = findAndUpdateFolder(
        folderStructure,
        selectedFolder.id,
        newFlashList,
        selectedFolder.id
      );

      dispatch(setFolderStructure(updatedFolderStructure));
      dispatch(setSelectedFolder(updatedSelectedFolder));
    } else {
      toast.error(
        "Cannot add FlashList to the selected item. Please ensure you have selected a Fetch type folder."
      );
    }
  };

  const custom_block_id = saveBlock._id;

  const handleDeleteConfirmationBlock = (data) => {
    dispatch(deleteMySaveBlock(myCustomBlock, custom_block_id));
  };

  return (
    <>
      <div
        className="popupDashboard-Wrappers
      "
      >
        <div className="head_main">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="#1A237E"
            className="arrowSvg"
          >
            <path
              d="M18.7795 5.21543L18.6687 5.31035L10.4158 12.4822C10.1363 12.7248 9.96231 13.0887 9.96231 13.4947C9.96231 13.9008 10.1416 14.2646 10.4158 14.5072L18.6529 21.6738L18.79 21.7951C18.9219 21.8848 19.0801 21.9375 19.2488 21.9375C19.7076 21.9375 20.082 21.5473 20.082 21.0621V5.93789C20.082 5.45273 19.7076 5.0625 19.2488 5.0625C19.0748 5.0625 18.9113 5.12051 18.7795 5.21543Z"
              fill="#1A237E"
            />
          </svg>
          <div className="headSection">
            Add Components
            <CrossSvg closeModelPopup={closeModalComponent} />
          </div>
        </div>

        <div className="componentWrapper">
          <div className="comp_btns">
            <button
              className={showBits ? "bitBlock_btn_active" : "bitBlock_btn"}
              onClick={openBits}
            >
              Bits
            </button>
            <button
              className={showBlocks ? "bitBlock_btn_active" : "bitBlock_btn"}
              onClick={openBlocks}
            >
              Blocks
            </button>
          </div>

          {showBits && !showBlocks && (
            <div className="all_components">
              <div
                className="comp_box"
                onClick={() =>
                  handleAddImage(selectedFolder.id, selectedFolder.type)
                }
              >
                <ImageSvg />
                {/* <input
                  type="file"
                  ref={fileInputRef}
                  hidden
                  accept="image/*"
                  name="image_add"
                /> */}

                <h5 className="comp_name">Image</h5>
              </div>
              <div
                className="comp_box"
                onClick={() =>
                  handleAddText(selectedFolder.id, selectedFolder.type)
                }
              >
                <TextSvg />

                <h5 className="comp_name">Text</h5>
              </div>
              <div
                className="comp_box"
                onClick={() =>
                  handleCreateView(selectedFolder.id, selectedFolder.type)
                }
              >
                <ViewSvg />
                <h5 className="comp_name">View</h5>
              </div>
              <div
                className="comp_box"
                onClick={() =>
                  handleCreatePresseable(selectedFolder.id, selectedFolder.type)
                }
              >
                <PressableSvg />
                <h5 className="comp_name">Pressable</h5>
              </div>
              <div
                className="comp_box"
                onClick={() =>
                  handleCreateFlashList(selectedFolder.id, selectedFolder.type)
                }
              >
                <FlashListSvg width="20" height="20" />
                <h5 className="comp_name">Flashlist</h5>
              </div>
              <div
                className="comp_box"
                onClick={() =>
                  handleCreateFetch(selectedFolder.id, selectedFolder.type)
                }
              >
                <FetchSvg width="20" height="20" />
                <h5 className="comp_name">Fetch</h5>
              </div>
              <div
                className={
                  !versionComponents?.DatePicker ? "no_comp_box" : "comp_box"
                }
                style={{
                  cursor: !versionComponents?.DatePicker
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <DatePickerSvg width="20" height="20" />
                <h5 className="comp_name">Date Picker</h5>
              </div>
              {/* <div
                className={
                  !versionComponents?.TabBar ? "no_comp_box" : "comp_box"
                }
                style={{
                  cursor: !versionComponents?.TabBar
                    ? "not-allowed"
                    : "pointer",
                }}

                // onClick={() =>
                //   handleCreateFetch(selectedFolder.id, selectedFolder.type)
                // }  
              >
                <TabBarsSvg width="20" height="20" />
                <h5 className="comp_name">Tab Bars</h5>
              </div> */}
              <div
                className={
                  !versionComponents?.TextField ? "no_comp_box2" : "comp_box"
                }
                style={{
                  cursor: !versionComponents?.TextField
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <TextFieldSvg width="20" height="20" />
                <h5 className="comp_name">Text Field</h5>
              </div>
              <div
                className={
                  !versionComponents?.Buttons ? "no_comp_box2" : "comp_box"
                }
                style={{
                  cursor: !versionComponents?.Buttons
                    ? "not-allowed"
                    : "pointer",

                  background: "",
                }}
              >
                <ButtonsSvg width="20" height="20" />
                <h5 className="comp_name">Buttons</h5>
              </div>
              <div
                className={
                  !versionComponents?.BottomSheet ? "no_comp_box" : "comp_box"
                }
                style={{
                  cursor: !versionComponents?.BottomSheet
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <BottomSheetSvg width="20" height="20" />
                <h5 className="comp_name">Bottom Sheets</h5>
              </div>
              <div
                className={
                  !versionComponents?.Dropdown ? "no_comp_box" : "comp_box"
                }
                style={{
                  cursor: !versionComponents?.Dropdown
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <DatePickerSvg width="20" height="20" />
                <h5 className="comp_name">Picker</h5>
              </div>
              <div
                className={
                  !versionComponents?.TabView ? "no_comp_box" : "comp_box"
                }
                style={{
                  cursor: !versionComponents?.TabView
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <Tabbar />
                <h5 className="comp_name">Tab View</h5>
              </div>
              <div
                className={
                  !versionComponents?.TabViewItem ? "no_comp_box" : "comp_box"
                }
                style={{
                  cursor: !versionComponents?.TabViewItem
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <Tabbar />
                <h5 className="comp_name">Tab View Item</h5>
              </div>
            </div>
          )}

          {showBlocks && !showBits && getMySaveBlock?.length > 0 && (
            <div className="YourBlock_main">
              <div className="blocks">
                <span className="textHead">
                  Your Blocks
                  <InfoSvg />
                </span>
                <DownArrowSvg />
              </div>

              {getMySaveBlock?.map((customBlocks, index) => (
                <div className="blockList" key={index}>
                  <div
                    className="listLeft"
                    onClick={() => handleClickblockdata(customBlocks, index)}
                  >
                    <h5
                      className="listName"
                      selected={selectedBlock === customBlocks.index}
                    >
                      {customBlocks.name}
                    </h5>
                  </div>
                  <div className="flex_svg">
                    <div className="white_box">
                      <ClipSvg />
                    </div>
                    <div className="deleteIconContainer">
                      <DeleteSvg
                        openModal={() => openModal(index, customBlocks)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <DeleteConfirmationPopup
        openModal={deleteConfirmationModal}
        onCloseModal1={closeModal}
        handleDeleteConfirmationBlock={handleDeleteConfirmationBlock}
      />
    </>
  );
};

export default AddComponentPopup;
