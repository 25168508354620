import React from "react";

const OnBlur = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="house-person-leave"
      class="svg-inline--fa fa-house-person-leave fa-sm "
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="20px"
      height="20px"
    >
      <path
        fill="#ffff"
        d="M218.3 8.5c12.3-11.3 31.2-11.3 43.4 0L379.5 117.2c-32.6 19.5-57.5 50.2-69.6 86.7l-2.6 7.8c-11.2 33.5 6.9 69.8 40.5 81c15.8 5.3 32.2 4 46.3-2.3L361.5 372l-44 44H112c-26.5 0-48-21.5-48-48V256H32c-13.2 0-25-8.1-29.8-20.3s-1.6-26.2 8.1-35.2l208-192zM128 208v64c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM528 0a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM443.7 200.5l-8 3.5c-16.4 7.3-29 21.2-34.7 38.2l-2.6 7.8c-5.6 16.8-23.7 25.8-40.5 20.2s-25.8-23.7-20.2-40.5l2.6-7.8c11.4-34.1 36.6-61.9 69.4-76.5l8-3.5c20.8-9.2 43.3-14 66.1-14c44.6 0 84.8 26.8 101.9 67.9L601 232.7l21.4 10.7c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3L567 287.3c-10.3-5.2-18.4-13.8-22.8-24.5l-9.6-23-19.3 65.5 49.5 54c5.4 5.9 9.2 13 11.2 20.8l23 92.1c4.3 17.1-6.1 34.5-23.3 38.8s-34.5-6.1-38.8-23.3l-22-88.1-70.7-77.1c-14.8-16.1-20.3-38.6-14.7-59.7l16.9-63.5c-1 .4-1.9 .8-2.9 1.2zm-30 135.1c2.1 3 4.5 5.8 7 8.6l40.7 44.4-14.5 36.2c-2.4 6-6 11.5-10.6 16.1l-61.7 61.7c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L388.7 398l25-62.4z"
      ></path>
    </svg>
  );
};

export default OnBlur;
