import React from "react";

const DataBaseSvg = ({ onClick }) => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 20 23"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <g clip-path="url(#clip0_3385_5870)">
          <path
            d="M20 3.26531V5.30612C20 7.1046 15.5208 8.57143 10 8.57143C4.47915 8.57143 0 7.1046 0 5.30612V3.26531C0 1.46683 4.47915 0 10 0C15.5208 0 20 1.46683 20 3.26531ZM20 7.85714V12.449C20 14.2475 15.5208 15.7143 10 15.7143C4.47915 15.7143 0 14.2475 0 12.449V7.85714C2.14844 9.33674 6.08071 10.0255 10 10.0255C13.9193 10.0255 17.8515 9.33674 20 7.85714ZM20 15V19.5918C20 21.3903 15.5208 22.8571 10 22.8571C4.47915 22.8571 0 21.3903 0 19.5918V15C2.14844 16.4796 6.08071 17.1684 10 17.1684C13.9193 17.1684 17.8515 16.4796 20 15Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_3385_5870">
            <rect width="20" height="22.8571" fill="black" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default DataBaseSvg;
