import React from "react";

const AlignBottom = ({ selectedFolder }) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="brd1"
      style={{
        background: selectedFolder?.align === "baseline" && "#0AABE6",
      }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 3.5H16V4.5H0V3.5Z"
        fill="#1A237E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 0H11C11.2652 0 11.5196 0.105357 11.7071 0.292893C11.8946 0.48043 12 0.734784 12 1V7C12 7.26522 11.8946 7.51957 11.7071 7.70711C11.5196 7.89464 11.2652 8 11 8H5C4.73478 8 4.48043 7.89464 4.29289 7.70711C4.10536 7.51957 4 7.26522 4 7V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0Z"
        fill="#1A237E"
      />
    </svg>
  );
};

export default AlignBottom;
