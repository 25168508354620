import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ModalDelete from "../common/svgComponents/ModalDelete";

const DeletePopup = ({ change, projectID, handleDelete, onCloseModal }) => {
  return (
    <div>
      <Modal
        open={change}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "delete_popup",
        }}
      >
        <div className="main_container">
          <div className="img_contain">
            <ModalDelete />
          </div>
          <h5>Are you sure?</h5>
          <p>This item will be permanently deleted?</p>
          <div className="bt_flex">
            <button
              className="delete-btn"
              onClick={() => handleDelete(projectID)}
            >
              Delete
            </button>
            <button className="cancel-btn" onClick={onCloseModal}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeletePopup;
