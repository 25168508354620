import React from "react";

const InformationIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "relative" }}
    >
      <path
        d="M9.99984 1.66699C8.35166 1.66699 6.7405 2.15573 5.37009 3.07141C3.99968 3.98709 2.93158 5.28858 2.30084 6.8113C1.67011 8.33401 1.50509 10.0096 1.82663 11.6261C2.14817 13.2426 2.94185 14.7274 4.10728 15.8929C5.27272 17.0583 6.75758 17.852 8.37409 18.1735C9.9906 18.4951 11.6662 18.3301 13.1889 17.6993C14.7116 17.0686 16.0131 16.0005 16.9288 14.6301C17.8444 13.2597 18.3332 11.6485 18.3332 10.0003C18.3332 8.90598 18.1176 7.82234 17.6988 6.8113C17.28 5.80025 16.6662 4.88159 15.8924 4.10777C15.1186 3.33395 14.1999 2.72012 13.1889 2.30133C12.1778 1.88254 11.0942 1.66699 9.99984 1.66699ZM9.58317 4.16699C9.8304 4.16699 10.0721 4.2403 10.2776 4.37766C10.4832 4.51501 10.6434 4.71023 10.738 4.93864C10.8326 5.16705 10.8574 5.41838 10.8092 5.66085C10.7609 5.90333 10.6419 6.12606 10.4671 6.30088C10.2922 6.47569 10.0695 6.59474 9.82704 6.64297C9.58456 6.69121 9.33323 6.66645 9.10482 6.57184C8.87641 6.47723 8.68119 6.31702 8.54384 6.11145C8.40648 5.90589 8.33317 5.66422 8.33317 5.41699C8.33317 5.08547 8.46487 4.76753 8.69929 4.53311C8.93371 4.29869 9.25165 4.16699 9.58317 4.16699ZM11.6665 15.0003H10.8332C10.3911 15.0003 9.96722 14.8247 9.65466 14.5122C9.3421 14.1996 9.16651 13.7757 9.16651 13.3337V10.0003C8.94549 10.0003 8.73353 9.91253 8.57725 9.75625C8.42097 9.59997 8.33317 9.38801 8.33317 9.16699C8.33317 8.94598 8.42097 8.73402 8.57725 8.57774C8.73353 8.42146 8.94549 8.33366 9.16651 8.33366H9.99984C10.2209 8.33366 10.4328 8.42146 10.5891 8.57774C10.7454 8.73402 10.8332 8.94598 10.8332 9.16699V13.3337H11.6665C11.8875 13.3337 12.0995 13.4215 12.2558 13.5777C12.412 13.734 12.4998 13.946 12.4998 14.167C12.4998 14.388 12.412 14.6 12.2558 14.7562C12.0995 14.9125 11.8875 15.0003 11.6665 15.0003Z"
        fill="#4CAF50"
      />
    </svg>
  );
};

export default InformationIcon;
