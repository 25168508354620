import React from "react";

const TextCross = ({ selectedFolder }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="brd"
      // style={{
      //   background: selectedFolder?.textAlign === "auto" && "#0AABE6",
      // }}
    >
      <path
        d="M10.1699 9.99945L14.0599 6.10945C14.0947 6.07465 14.1223 6.03334 14.1412 5.98787C14.16 5.9424 14.1697 5.89367 14.1697 5.84445C14.1697 5.79524 14.16 5.74651 14.1412 5.70104C14.1223 5.65557 14.0947 5.61425 14.0599 5.57945C14.0251 5.54465 13.9838 5.51705 13.9384 5.49821C13.8929 5.47938 13.8442 5.46969 13.7949 5.46969C13.7457 5.46969 13.697 5.47938 13.6515 5.49821C13.6061 5.51705 13.5647 5.54465 13.5299 5.57945L9.63994 9.42945L5.74994 5.53945C5.71514 5.50465 5.67383 5.47705 5.62836 5.45821C5.58289 5.43938 5.53415 5.42969 5.48494 5.42969C5.43572 5.42969 5.38699 5.43938 5.34152 5.45821C5.29605 5.47705 5.25474 5.50465 5.21994 5.53945C5.18514 5.57425 5.15753 5.61557 5.1387 5.66104C5.11987 5.70651 5.11017 5.75524 5.11017 5.80445C5.11017 5.85367 5.11987 5.9024 5.1387 5.94787C5.15753 5.99334 5.18514 6.03465 5.21994 6.06945L9.10994 9.99945L5.21994 13.8495C5.18456 13.8839 5.15644 13.9251 5.13724 13.9706C5.11805 14.0162 5.10815 14.0651 5.10815 14.1145C5.10815 14.1639 5.11805 14.2127 5.13724 14.2583C5.15644 14.3038 5.18456 14.345 5.21994 14.3795C5.25287 14.4153 5.29313 14.4437 5.33798 14.4627C5.38284 14.4817 5.43125 14.4908 5.47994 14.4895C5.53013 14.491 5.58007 14.482 5.62657 14.463C5.67307 14.4441 5.71509 14.4156 5.74994 14.3795L9.63994 10.4895L13.5299 14.3795C13.5629 14.4153 13.6031 14.4437 13.648 14.4627C13.6928 14.4817 13.7413 14.4908 13.7899 14.4895C13.8402 14.4916 13.8903 14.4829 13.9369 14.4639C13.9835 14.4449 14.0255 14.4161 14.0599 14.3795C14.0953 14.345 14.1234 14.3038 14.1426 14.2583C14.1618 14.2127 14.1717 14.1639 14.1717 14.1145C14.1717 14.0651 14.1618 14.0162 14.1426 13.9706C14.1234 13.9251 14.0953 13.8839 14.0599 13.8495L10.1699 9.99945Z"
        fill="#fff"
      />
    </svg>
  );
};

export default TextCross;
