import React from "react";

const FileIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 0.25H1.75C0.92125 0.25 0.25 0.92125 0.25 1.75V12.25C0.25 13.0787 0.92125 13.75 1.75 13.75H12.25C13.0787 13.75 13.75 13.0787 13.75 12.25V3.25L10.75 0.25ZM7 12.25C5.75875 12.25 4.75 11.2413 4.75 10C4.75 8.75875 5.75875 7.75 7 7.75C8.24125 7.75 9.25 8.75875 9.25 10C9.25 11.2413 8.24125 12.25 7 12.25ZM9.25 4.75H1.75V1.75H9.25V4.75Z"
        fill="#1A237E"
      />
    </svg>
  );
};

export default FileIcon;
