import {
  DELETE_ENPOINT,
  DELETE_FLASHLIST,
  EDIT_MY_ENDPOINT,
  EDIT_MY_FLASHLIST,
  GET_ENDPOINTS,
  GET_FLASHLIST,
  GET_MY_FLASHLIST_DATA,
  MYNEW_DATA_DATA,
  SET_ENDPOINT,
  SET_FLASHLIST,
  SET_MY_FLASHLIST_DATA,
  SET_MY_FLASHLIST_KEYS,
  SET_MY_FLASHLIST_KEYS_DATA,
  SET_MY_MERGE_DATA,
  SET_MY_MERGE_DATA2,
  SET_RELOAD,
  SET_RELOAD_UPDATE,
} from "../types";

import { toast } from "react-toastify";
import { axiosInstance, axiosInstance2 } from "../axiosInstance";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
};

export const addFlashList = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`addflashlist`, data, config)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Flashlist Added Successfully");
          dispatch({
            type: SET_FLASHLIST,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          reject(new Error("Failed to add flashlist"));
        }
      })
      .catch((error) => {
        console.error("Error sending data to API:", error);
        toast.error(error.response?.data?.message || "Failed to add flashlist");
        reject(error); // Reject with the error
      });
  });
};

export const getFlashlistData = (projectid) => (dispatch) => {
  axiosInstance
    .get(`flashlists/${projectid}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_FLASHLIST,
          payload: res.data.flashlistdata,
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching screen data:", error);
    });
};

export const deleteFlashList = (projectid, data) => (dispatch) => {
  axiosInstance
    .delete(`flashlist/${projectid}/${data}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_FLASHLIST,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching screen data:", error);
    });
};

// export const addEndpoints = (data) => (dispatch) => {
//   console.log(data);
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .post(`addendpoints`, data, config)
//       .then((res) => {
//         if (res) {
//           toast.success("Flashlist Added Successfully");
//           dispatch({
//             type: SET_ENDPOINT,
//             payload: res.data,
//           });
//           resolve(res.data);
//         } else {
//           reject(new Error("Failed to add endpoint"));
//         }
//       })
//       .catch((error) => {
//         console.error("Error sending data to API:", error);
//         toast.error(error.response?.data?.message || "Failed to add endpoint");
//         reject(error); // Reject with the error
//       });
//   });
// };

// export const addEndpoints = (data) => (dispatch) => {
//   console.log({ data });
//   axiosInstance.post("addendpoints", data, config).then((res) => {
//     if (res.status) {
//       toast.success(res.data.message);
//       dispatch({
//         type: SET_ENDPOINT,
//         payload: res.data,
//       });
//     } else {
//       toast.error(res.data.message);
//     }
//   });
// };

export const addEndpoints = (data) => (dispatch) => {
  console.log(data, "212s");
  // Validate data.endpointdata
  if (!data || !Array.isArray(data.endpointdata)) {
    toast.error("Invalid endpoint data.");
    return;
  }

  axiosInstance
    .post("addendpoints", data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_ENDPOINT,
          payload: res.data,
        });
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      toast.error("Error adding endpoints.");
    });
};

export const getEndpointsData = (flashlistId) => (dispatch) => {
  axiosInstance
    .get(`endpoints/${flashlistId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_ENDPOINTS,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching endpoints data:", error);
    });
};

export const deleteEndpointdata = (flashlistId, data) => (dispatch) => {
  axiosInstance
    .delete(`delendpoints/${flashlistId}/${data}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_ENPOINT,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching screen data:", error);
    });
};

export const editEndpoint = (flashlistid, endpointid, data) => (dispatch) => {
  axiosInstance
    .patch(`updateendpoint/${flashlistid}/${endpointid}`, data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res?.data?.message);
        dispatch({
          type: EDIT_MY_ENDPOINT,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message);
      }
    });
};

export const myApiData =
  (url, data, method, apiCalledRef, key) => (dispatch) => {
    const validMethods = ["get", "post", "put", "delete", "patch"];
    const methodLower = method?.toLowerCase();

    if (!validMethods.includes(methodLower)) {
      console.error("Invalid method:", method);
      return;
    }

    let axiosRequest;
    if (methodLower === "get" || methodLower === "delete") {
      axiosRequest = axiosInstance[methodLower](`${url}${data}`, config);
    } else {
      axiosRequest = axiosInstance[methodLower](url, data, config);
    }

    axiosRequest
      .then((res) => {
        dispatch({
          type: MYNEW_DATA_DATA,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching endpoints data:", error);
      });
  };

export const addFlashlistKeys = (data) => (dispatch) => {
  axiosInstance
    .post("addflashlistkey", data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_MY_FLASHLIST_KEYS,
          payload: res.data,
        });
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      toast.error("Error adding Flashlist Items.");
    });
};

export const myFlashListkeys = (data) => (dispatch) => {
  axiosInstance
    .get(`getflashlistkey/${data}`, config)
    .then((res) => {
      dispatch({
        type: SET_MY_FLASHLIST_KEYS_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.error("Error fetching flashlistitems data:", error);
    });
};

export const editFlashlist = (projectId, flashlistid, data) => (dispatch) => {
  axiosInstance
    .patch(`updateflashlist/${projectId}/${flashlistid}`, data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res?.data?.message);
        dispatch({
          type: EDIT_MY_FLASHLIST,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error sending data to API:", error);
        toast.error(error.response.data.message);
      }
    });
};

export const addFlashData = (data) => (dispatch) => {
  axiosInstance
    .post("addflashdata", data, config)
    .then(async (res) => {
      if (res.status === 200) {
        toast.success(res.data.message);
        await dispatch({
          type: SET_MY_FLASHLIST_DATA,
          payload: res.data,
        });
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      console.error("Error adding Flashlist Data:", error);
      toast.error("Error adding Flashlist Data.");
    });
};

export const myFlashData =
  (projectId, fetchId, endpointId) => async (dispatch) => {
    try {
      const res = await axiosInstance.get(
        `flashdata/${projectId}/${fetchId}/${endpointId}`,
        config
      );
      dispatch({
        type: GET_MY_FLASHLIST_DATA,
        payload: res.data.data.Flashdata.data,
      });
    } catch (error) {
      console.error("Error fetching flashlist data:", error);
    }
  };

export const setMyMergeData = (data) => (dispatch) => {
  dispatch({
    type: SET_MY_MERGE_DATA,
    payload: data,
  });
  dispatch(setMergeableData(data));
  dispatch(setReloadMergeApi(true));
};

export const setMergeableData = (data) => (dispatch) => {
  dispatch({
    type: SET_MY_MERGE_DATA2,
    payload: data,
  });
  dispatch(setReloadUpdate(true));
};

export const setReloadMergeApi = (data) => (dispatch) => {
  dispatch({
    type: SET_RELOAD,
    payload: data,
  });
};
export const setReloadUpdate = (data) => (dispatch) => {
  dispatch({
    type: SET_RELOAD_UPDATE,
    payload: data,
  });
};
