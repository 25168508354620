import React from "react";
import { FutureCom } from "./components/FutureCom";
import { ScreenCom } from "./components/ScreenCom";
import { MoreBiller } from "./components/MoreBiller";
import Testimonials from "./components/Testimonials/Testimonials";
import AppCom from "./components/AppCom";
import AccountFuture from "./components/AccountFuture";
import ServiceCom from "./components/ServiceCom";
import Footer from "../Footer/Footer";
import { Topbar } from "../Topbar/Topbar";

const Homepage = () => {
  return (
    <>
      <Topbar />
      <AppCom />
      <ServiceCom />
      <MoreBiller />
      <AccountFuture />
      <FutureCom />
      <ScreenCom />
      <Testimonials />
      <Footer />
    </>
  );
};

export default Homepage;
