import { Topbar } from "./Componets/Topbar/Topbar.jsx";
import Homepage from "./Componets/Homepage/Homepage.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/Scss/style.scss";
import { Login } from "./pages/Login/Login";
import { SignUp } from "./pages/signUp/SignUp";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { Reset } from "./pages/reset/Reset";
import { VerifyOTP } from "./pages/verifyOtp/VerifyOTP";
import { SetPassword } from "./pages/setPassword/SetPassword";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import DashboardView from "./Componets/Workspace/DashboardView.js";
import Footer from "./Componets/Footer/Footer.js";
import { ToastContainer } from "react-toastify";

import { SignUpVerifyOtp } from "./pages/signUp/SignUpVerifyOtp/SignUpVerifyOtp.js";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes.js";
import { useSelector } from "react-redux";
import CanvasDisplay from "./Componets/Workspace/CanvasDisplay.js";
import Profile from "./pages/Dashboard/Profile/Profile.js";
import Document from "./Documentation/Document.jsx";
import MainDocsPage from "./Documentation/MainDocsPage.jsx";

function App() {
  const location = useLocation();
  const { project_name } = useParams();

  return (
    <>
      <ToastContainer />
      <div className="App">
        <Routes>
          {location.pathname === "/SignUp" || "/dashboard" ? "" : <Topbar />}
          <Route element={<PrivateRoutes />}>
            {/* <Route path="/workspace" element={<DashboardView />} /> */}
            <Route
              path="/workspace/:project_name/:id?"
              element={<DashboardView />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
          </Route>

          <Route path="/" element={<Homepage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/verifyOTP" element={<VerifyOTP />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/setPassword" element={<SetPassword />} />
          <Route path="/signup-verifyOTP" element={<SignUpVerifyOtp />} />

          <Route path="/docs/:docId" element={<Document />} />

          <Route path="/*" element={<MainDocsPage />} />
          {location.pathname === "/dashboard" || "/workspace" ? "" : <Footer />}
        </Routes>
      </div>
    </>
  );
}

export default App;
