import React, { useEffect, useRef, useState, version } from "react";
import Select from "react-select";
import {
  deleteVersion,
  getScreenData,
} from "../../../redux/actions/defaultActions";
import { useDispatch } from "react-redux";
import icon1 from "../../../assets/Icons/icon1.svg";
import moment from "moment";

const VersionTab = ({ screenVersionList, setVersions, versions }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [versions2, setVersions2] = useState();

  const ref = useRef(null);
  const dispatch = useDispatch();

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: " #1A237E",
      borderRadius: "5px",
      width: "100%",
      border: "none",
      color: "#fff",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1A237E",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  #1A237E" : "#1A237E",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
  };

  const myProjectId = localStorage.getItem("project_id");
  const screen_Id = localStorage.getItem("screen_Id");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const openPopupHandler = (id) => {
    setShowPopup(id === showPopup ? null : id);
  };

  useEffect(() => {
    if (screenVersionList) {
      const combinedOptions = screenVersionList?.versions?.map((key) => ({
        value: key._id,
        label: key.versionNumber,
      }));

      setVersions2(combinedOptions);
    }
  }, [screenVersionList]);

  const selectVersionChange = (data) => {
    dispatch(getScreenData(myProjectId, screen_Id, data?.versionNumber));
    setVersions(data?._id);
    localStorage.setItem("crntVersion_id", data?._id);
  };
  const selectVersionChange2 = (data) => {
    dispatch(getScreenData(myProjectId, screen_Id, data?.label));
    localStorage.setItem("crntVersion_id", data?.value);
  };
  const deleteVersionHandler = (data) => {
    dispatch(deleteVersion(myProjectId, screen_Id, data?._id));
  };

  return (
    <div>
      <div className="versionWrapper">
        <h4 className="versionProject">{screenVersionList?.screenName}</h4>
        <div className="selectDiv">
          <Select
            options={versions2}
            name="colors"
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Version"
            styles={colourStyles}
            onChange={selectVersionChange2}
          />
        </div>
        <div className="versionDetail">
          <p className="pNAme">Bugun</p>
          <div className="versionsMainDiv">
            {screenVersionList?.versions
              ?.slice()
              .reverse()
              .map((data, key) => (
                <div
                  className={
                    data?._id === versions ? "versions" : "versionTime"
                  }
                  key={key}
                >
                  <div onClick={() => selectVersionChange(data)}>
                    <p className="p1">{data?.versionNumber}</p>
                    <p className="p2">
                      {moment(data?.createdAt).format("DD MMM YYYY ( HH:mm )")}
                    </p>
                    {/* <p className="p3">version 1.1 </p> */}
                    <p className="p4">
                      <img src={icon1} alt="" />
                      <span style={{ marginLeft: "5px", marginTop: "2px" }}>
                        Burhan Karakas
                      </span>
                    </p>
                  </div>
                  {/* <div className="dotsDiv">
                    <div
                      className="dotdiv"
                      onClick={() => openPopupHandler(data?._id)}
                    >
                      <span>
                        <svg
                          width="4"
                          height="15"
                          viewBox="0 0 4 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                          <circle cx="2" cy="7.33301" r="2" fill="#D9D9D9" />
                          <circle cx="2" cy="12.667" r="2" fill="#D9D9D9" />
                        </svg>
                      </span>
                    </div>
                    {showPopup === data?._id && (
                      <div className="dots3" ref={ref}>
                        <p>Rollback</p>
                        <p onClick={() => deleteVersionHandler(data)}>Delete</p>
                      </div>
                    )}
                  </div> */}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VersionTab;
