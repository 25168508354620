import React from "react";

const DeleteSvg2 = () => {
  return (
    <>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3285_74)">
          <path
            d="M4.24984 13.4583C4.24984 14.241 4.8838 14.875 5.6665 14.875H11.3332C12.1159 14.875 12.7498 14.241 12.7498 13.4583V4.95833H4.24984V13.4583ZM13.4582 2.83333H10.979L10.2707 2.125H6.729L6.02067 2.83333H3.5415V4.25H13.4582V2.83333Z"
            fill="#141a29"
          />
        </g>
        <defs>
          <clipPath id="clip0_3285_74">
            <rect width="17" height="17" fill="#141a29" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default DeleteSvg2;
