import React from "react";

const AlignTop = ({ selectedFolder }) => {
  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="brd"
      style={{
        background: selectedFolder?.align === "flex-start" ? "#1A237E" : "none",
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V1H0V0Z"
        fill={selectedFolder?.align === "flex-start" ? "white" : "#1A237E"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6C11.2652 6 11.5196 6.10536 11.7071 6.29289C11.8946 6.48043 12 6.73478 12 7V9C12 9.26522 11.8946 9.51957 11.7071 9.70711C11.5196 9.89464 11.2652 10 11 10H5C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9V7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H11ZM11 1C11.2652 1 11.5196 1.10536 11.7071 1.29289C11.8946 1.48043 12 1.73478 12 2V4C12 4.26522 11.8946 4.51957 11.7071 4.70711C11.5196 4.89464 11.2652 5 11 5H5C4.73478 5 4.48043 4.89464 4.29289 4.70711C4.10536 4.51957 4 4.26522 4 4V2C4 1.73478 4.10536 1.48043 4.29289 1.29289C4.48043 1.10536 4.73478 1 5 1H11Z"
        fill={selectedFolder?.align === "flex-start" ? "white" : "#1A237E"}
      />
    </svg>
  );
};

export default AlignTop;
