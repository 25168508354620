import React from "react";

const LandscapeViewSvg = ({ handleLandscapeClick }) => {
  return (
    <div>
      <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleLandscapeClick}
      >
        <path
          d="M0.25 11.5V2.5C0.25 1.90326 0.487053 1.33097 0.90901 0.90901C1.33097 0.487053 1.90326 0.25 2.5 0.25L17.5 0.25C18.0967 0.25 18.669 0.487053 19.091 0.90901C19.5129 1.33097 19.75 1.90326 19.75 2.5V11.5C19.75 12.0967 19.5129 12.669 19.091 13.091C18.669 13.5129 18.0967 13.75 17.5 13.75L2.5 13.75C1.90326 13.75 1.33097 13.5129 0.90901 13.091C0.487053 12.669 0.25 12.0967 0.25 11.5ZM18.25 2.5C18.25 2.30109 18.171 2.11032 18.0303 1.96967C17.8897 1.82902 17.6989 1.75 17.5 1.75L2.5 1.75C2.30109 1.75 2.11032 1.82902 1.96967 1.96967C1.82902 2.11032 1.75 2.30109 1.75 2.5V11.5C1.75 11.6989 1.82902 11.8897 1.96967 12.0303C2.11032 12.171 2.30109 12.25 2.5 12.25L17.5 12.25C17.6989 12.25 17.8897 12.171 18.0303 12.0303C18.171 11.8897 18.25 11.6989 18.25 11.5V2.5Z"
          fill="white"
        />
        <path
          d="M17 8.5V5.5C17 5.10218 17.1054 4.72064 17.2929 4.43934C17.4804 4.15804 17.7348 4 18 4H18.5C18.6326 4 18.7598 4.07902 18.8536 4.21967C18.9473 4.36032 19 4.55109 19 4.75V9.25C19 9.44891 18.9473 9.63968 18.8536 9.78033C18.7598 9.92098 18.6326 10 18.5 10H18C17.7348 10 17.4804 9.84196 17.2929 9.56066C17.1054 9.27936 17 8.89782 17 8.5Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default LandscapeViewSvg;
