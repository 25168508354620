import React from "react";

const ThreeDots = ({ onClick }) => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <path
          d="M11.375 5.25C11.375 4.55381 11.6516 3.88613 12.1438 3.39384C12.6361 2.90156 13.3038 2.625 14 2.625C14.6962 2.625 15.3639 2.90156 15.8562 3.39384C16.3484 3.88613 16.625 4.55381 16.625 5.25C16.625 5.94619 16.3484 6.61387 15.8562 7.10616C15.3639 7.59844 14.6962 7.875 14 7.875C13.3038 7.875 12.6361 7.59844 12.1438 7.10616C11.6516 6.61387 11.375 5.94619 11.375 5.25ZM11.375 14C11.375 13.3038 11.6516 12.6361 12.1438 12.1438C12.6361 11.6516 13.3038 11.375 14 11.375C14.6962 11.375 15.3639 11.6516 15.8562 12.1438C16.3484 12.6361 16.625 13.3038 16.625 14C16.625 14.6962 16.3484 15.3639 15.8562 15.8562C15.3639 16.3484 14.6962 16.625 14 16.625C13.3038 16.625 12.6361 16.3484 12.1438 15.8562C11.6516 15.3639 11.375 14.6962 11.375 14ZM11.375 22.75C11.375 22.0538 11.6516 21.3861 12.1438 20.8938C12.6361 20.4016 13.3038 20.125 14 20.125C14.6962 20.125 15.3639 20.4016 15.8562 20.8938C16.3484 21.3861 16.625 22.0538 16.625 22.75C16.625 23.4462 16.3484 24.1139 15.8562 24.6062C15.3639 25.0984 14.6962 25.375 14 25.375C13.3038 25.375 12.6361 25.0984 12.1438 24.6062C11.6516 24.1139 11.375 23.4462 11.375 22.75Z"
          fill="#141a29"
        />
      </svg>
    </>
  );
};

export default ThreeDots;
