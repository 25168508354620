import React, { useEffect, useRef, useState } from "react";
import { Save, FolderCopy, Delete, Close } from "@mui/icons-material";
import FileIcon from "../../assets/Iconcomponent/FileIcon";
import Duplicate from "../../assets/Iconcomponent/Duplicate";
import DeleteAlign from "../../assets/Iconcomponent/DeleteAlign";

const Popup = ({
  onClose,
  onSaveCustomBlock,
  onDuplicate,
  onDelete,
  folderType,
  listItemRef,
  popupPosition,
}) => {
  const handleDelte = () => {
    onClose();
    onDelete();
  };

  return (
    <div
      className="save-view-popup"
      style={{
        position: "absolute",
        top: popupPosition.top,
        right: "1rem",
        zIndex: 1000,
        marginTop: "20px",
      }}
    >
      <span className="close_popup">{/* <Close onClick={onClose} /> */}</span>
      {/* <ul>
        <li onClick={onSaveCustomBlock} className="save_custom_btn">
          <Save />
          {folderType === "image" ||
          folderType === "text" ||
          folderType === "view"
            ? "Save Custom Block"
            : "Edit Custom Block"}
        </li>

        <li onClick={onDuplicate} className="duplicate_btn">
          <FolderCopy />
          Duplicate
        </li>
        <li onClick={handleDelte} className="delete_btn">
          <Delete />
          Delete
        </li>

      </ul> */}
      <div className="shareOptions">
        <svg
          width="27"
          height="16"
          viewBox="0 0 27 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="arrowsvgs"
        >
          <path
            d="M14.6604 1.01399L14.6606 1.01423L25.4958 13.4827L25.497 13.484L25.6237 13.6319C25.7035 13.7488 25.75 13.8888 25.75 14.0339C25.75 14.4292 25.4065 14.793 24.9272 14.793L2.07281 14.793C1.59346 14.793 1.25 14.4292 1.25 14.0339C1.25 13.894 1.29075 13.7607 1.36337 13.6451L1.52294 13.4647L1.52295 13.4647L1.52565 13.4616L12.3525 1.01754C12.3529 1.01708 12.3533 1.01661 12.3537 1.01615C12.6291 0.705598 13.0439 0.500942 13.508 0.500942C13.9768 0.500942 14.3889 0.701184 14.6604 1.01399Z"
            fill="#1A237E"
            stroke="#1A237E"
          />
        </svg>
        <ul className="shareUls">
          <li className="shareLis" onClick={onSaveCustomBlock}>
            <FileIcon />
            {folderType === "image" ||
            folderType === "text" ||
            folderType === "view"
              ? "Save Custom Block"
              : "Edit Custom Block"}
          </li>
          <li className="shareLis" onClick={onDuplicate}>
            <Duplicate />
            Duplicate
          </li>
          <li className="shareLis" onClick={handleDelte}>
            <DeleteAlign /> Delete
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Popup;
