import React from "react";

const AlignCenterSecond = ({ typography2 }) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="btm"
      style={{
        background: typography2?.align === "flex-start" ? "#1A237E" : "none",
      }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 9H16V10H0V9Z"
        fill={typography2?.align === "flex-start" ? "white" : "#1A237E"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 5C11.2652 5 11.5196 5.10536 11.7071 5.29289C11.8946 5.48043 12 5.73478 12 6V8C12 8.26522 11.8946 8.51957 11.7071 8.70711C11.5196 8.89464 11.2652 9 11 9H5C4.73478 9 4.48043 8.89464 4.29289 8.70711C4.10536 8.51957 4 8.26522 4 8V6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5H11ZM11 0C11.2652 0 11.5196 0.105357 11.7071 0.292893C11.8946 0.48043 12 0.734784 12 1V3C12 3.26522 11.8946 3.51957 11.7071 3.70711C11.5196 3.89464 11.2652 4 11 4H5C4.73478 4 4.48043 3.89464 4.29289 3.70711C4.10536 3.51957 4 3.26522 4 3V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H11Z"
        fill={typography2?.align === "flex-start" ? "white" : "#1A237E"}
      />
    </svg>
  );
};

export default AlignCenterSecond;
