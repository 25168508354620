import React from "react";
import switchimg from "../../../assets/Image/switchimg.png";
import fingerprints from "../../../assets/Image/fingerprints.svg";
import touch from "../../../assets/Image/touch.svg";

const ServiceCom = () => {
  return (
    <>
      <div className="main_service">
        <div className="inner_service">
          <div className="coustom_container">
            <div className="top_head">
            <p>What We Do</p>
            <h2>What Will You Get</h2>
            </div>
            <div className="body_sec">
              <div className="img_sec">
                <img src={switchimg} alt="switch" />
                <h6>Remote Access</h6>
                <p>But I Must Explain To You How All This Mistaken Idea Of Denouncing How All This Mistaken Pleasure Rationally.</p>
              </div>

              <div className="img_sec">
                <img src={fingerprints} alt="switch" />
                <h6>Secure Protection</h6>
                <p>But I Must Explain To You How All This Mistaken Idea Of Denouncing How All This Mistaken Pleasure Rationally.</p>
              </div>

              <div className="img_sec">
                <img src={touch} alt="switch" />
                <h6>Banking Support</h6>
                <p>But I Must Explain To You How All This Mistaken Idea Of Denouncing How All This Mistaken Pleasure Rationally.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCom;
