import React from "react";

const JustifyRight = ({ selectedFolder }) => {
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        background: selectedFolder?.justify === "flex-end" ? "#1A237E" : "none",
      }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 0H10V16H11V0Z"
        fill={selectedFolder?.justify === "flex-end" ? "white" : "#1A237E"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 8.5C4.73478 8.5 4.48043 8.60536 4.29289 8.79289C4.10536 8.98043 4 9.23478 4 9.5V11.5C4 11.7652 4.10536 12.0196 4.29289 12.2071C4.48043 12.3946 4.73478 12.5 5 12.5H9C9.26522 12.5 9.51957 12.3946 9.70711 12.2071C9.89464 12.0196 10 11.7652 10 11.5V9.5C10 9.23478 9.89464 8.98043 9.70711 8.79289C9.51957 8.60536 9.26522 8.5 9 8.5H5ZM1 3.5C0.734784 3.5 0.48043 3.60536 0.292893 3.79289C0.105357 3.98043 0 4.23478 0 4.5V6.5C0 6.76522 0.105357 7.01957 0.292893 7.20711C0.48043 7.39464 0.734784 7.5 1 7.5H9C9.26522 7.5 9.51957 7.39464 9.70711 7.20711C9.89464 7.01957 10 6.76522 10 6.5V4.5C10 4.23478 9.89464 3.98043 9.70711 3.79289C9.51957 3.60536 9.26522 3.5 9 3.5H1Z"
        fill={selectedFolder?.justify === "flex-end" ? "white" : "#1A237E"}
      />
    </svg>
  );
};

export default JustifyRight;
