import Carousel from "react-multi-carousel";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import image1 from "../../../../assets/Image/image1.png";
import image2 from "../../../../assets/Image/image2.png";
import image3 from "../../../../assets/Image/image3.png";
import image4 from "../../../../assets/Image/image4.png";
import image5 from "../../../../assets/Image/image5.png";

const SliderComp = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
    },
  };

  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div>
        <button onClick={() => previous()}>Previous</button>
        <button onClick={() => next()}>Next</button>
      </div>
    );
  };

  return (
    <>
      <Carousel
        responsive={responsive}
        renderDotsOutside
        customButtonGroup={<CustomButtonGroup />}
        infinite
      >
        {/* Your image components */}
        <div className="slide_image scroll-1">
          <img src={image1} alt="" />
        </div>
        <div className="slide_image scroll-2">
          <img src={image2} alt="" />
        </div>
        <div className="slide_image scroll-3">
          <img src={image3} alt="" />
        </div>
        <div className="slide_image scroll-4">
          <img src={image4} alt="" />
        </div>
        <div className="slide_image scroll-5">
          <img src={image5} alt="" />
        </div>
      </Carousel>
    </>
  );
};

export default SliderComp;
