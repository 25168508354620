import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import info_icon from "../../../../assets/Icons/info_icon.svg";
import Switch from "react-switch";
import InformationIcon from "../../../../assets/Iconcomponent/InformationIcon";

const WebSpecificComponent = ({
  handleIconClick,
  showTooltipData,
  setTooltipContent,
  tooltipContent,
  ToolTipBox,
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <div>
      {" "}
      <div className="scroll-sec">
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Web Specific</Accordion.Header>
            <Accordion.Body>
              <div className="web-1">
                <div className="web_img">
                  <p>Web Outline</p>
                  <ToolTipBox
                    iconType="green"
                    type="Web Outline"
                    handleIconClick={handleIconClick}
                    showTooltipData={showTooltipData}
                    tooltipContent={tooltipContent}
                  />{" "}
                </div>
                <div className="toggle-btn">
                  <Switch
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                    }}
                    onColor="#3366ff"
                    onHandleColor="#2693e6"
                    handleDiameter={29}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default WebSpecificComponent;
