import React from "react";

const ButtonsSvg = ({ change }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.4375 10.625C5.678 10.625 4.25 11.7661 4.25 13.175V20.825C4.25 22.2339 5.678 23.375 7.4375 23.375H26.5625C28.322 23.375 29.75 22.2339 29.75 20.825V13.175C29.75 11.7661 28.322 10.625 26.5625 10.625H7.4375ZM7.08262 8.5H26.9174C29.6544 8.5 31.875 10.4019 31.875 12.75V21.25C31.875 23.5981 29.6544 25.5 26.9174 25.5H7.08262C4.34562 25.5 2.125 23.5981 2.125 21.25V12.75C2.125 10.4019 4.34562 8.5 7.08262 8.5Z"
          fill={change ? "#fff" : "#3c4c6e"}
        />
        <path
          d="M22.3125 15.9375H11.6875C11.1007 15.9375 10.625 16.4132 10.625 17C10.625 17.5868 11.1007 18.0625 11.6875 18.0625H22.3125C22.8993 18.0625 23.375 17.5868 23.375 17C23.375 16.4132 22.8993 15.9375 22.3125 15.9375Z"
          fill={change ? "#fff" : "#3c4c6e"}
        />
      </svg>
    </div>
  );
};

export default ButtonsSvg;
