import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-responsive-modal";
import { useLocation } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

export const Topbar = () => {
  const location = useLocation();

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const drawerClass = isDrawerOpen ? "drawer open" : "drawer";

  // const isAuthenticated = () => {
  //   return localStorage.getItem("access_token") !== null;
  // };

  // logout api
  const access_token = localStorage.getItem("access_token");

  // const handleLogout = async () => {
  //   try {
  //     const response = await axiosInstance.post(
  //       "logout",
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       toast.success(response?.data?.result?.message, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });

  //       localStorage.clear("access_token");
  //       navigate("/login");
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message, {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   }
  // };

  const auth = localStorage.getItem("access_token") !== null;

  return (
    <>
      <div className="main_nav">
        <div className="coustom_container navbar-main">
          <Navbar expand="lg" className="navbar_list">
            <div className="logo_bar">
              <Navbar.Brand href="#" className="topbarLeft">
                <Link to="/">
                  <img src="/assets/Image/logo.svg" alt="" />
                </Link>
              </Navbar.Brand>
            </div>
            <div className="nav_bar">
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="topList"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <Nav.Link href="#" className="topListitem">
                    Features
                  </Nav.Link>
                  <Nav.Link href="#" className="topListitem">
                    Why Draftbit
                  </Nav.Link>
                  <Nav.Link href="#" className="topListitem">
                    Sucess Stories
                  </Nav.Link>
                  <Nav.Link href="#" className="topListitem">
                    Pricing
                  </Nav.Link>
                  <Nav.Link href="#" className="topListitem">
                    Experts
                  </Nav.Link>
                  <Nav.Link href="#" className="topListitem">
                    About Us
                  </Nav.Link>

                  {auth ? (
                    // <Link className="logout-btn" onClick={handleLogout}>
                    //   Logout
                    // </Link>

                    <Nav.Link href="#/dashboard" className="topListitem">
                      Dashboard
                    </Nav.Link>
                  ) : (
                    <div className="div_gap">
                      <Link to="/login">
                        <button className=" button_Sign ">Sign In</button>
                      </Link>
                      <Link to="/signup">
                        <button className=" button_start ">Get Started</button>
                      </Link>
                    </div>
                  )}

                  {/* {location.pathname === "/login" ? <Logout /> : ""} */}
                </Nav>
              </Navbar.Collapse>
            </div>

            <div className="mob-resp">
              <Menu onClick={handleDrawerOpen} />
            </div>
          </Navbar>
        </div>
      </div>

      {/* modal  */}

      <Modal
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        right
        classNames={{
          overlay: "P2P-PayOverlay",
          modal: "Header__sidebar__right",
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
        }}
      >
        <div className={drawerClass}>
          <div className="nav-list">
            <div className="inner">
              <ul>
                <li>
                  <Link>Features</Link>
                </li>
                <li>
                  <Link>Why Drafbit</Link>
                </li>
                <li>
                  <Link>Success Stories</Link>
                </li>
                <li>
                  <Link>Pricing</Link>
                </li>
                <li>
                  <Link>Experts</Link>
                </li>
                <li>
                  <Link>About Us</Link>
                </li>
              </ul>
            </div>
            <div className="btn-sec">
              <div className="inner">
                <div className="sign-btn">
                  <Link to="/login">Sign In</Link>
                </div>
                <div className="get-btn">
                  <Link to="/signup">Get Started</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
