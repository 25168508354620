import React from "react";

function JustifyHorizontalLeft({ selectedFolder }) {
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="brd"
      style={{
        background:
          selectedFolder?.justify === "flex-start" ? "#1A237E" : "none",
      }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H1V16H0V0Z"
        fill={selectedFolder?.justify === "flex-start" ? "white" : "#1A237E"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 8.5C6.26522 8.5 6.51957 8.60536 6.70711 8.79289C6.89464 8.98043 7 9.23478 7 9.5V11.5C7 11.7652 6.89464 12.0196 6.70711 12.2071C6.51957 12.3946 6.26522 12.5 6 12.5H2C1.73478 12.5 1.48043 12.3946 1.29289 12.2071C1.10536 12.0196 1 11.7652 1 11.5V9.5C1 9.23478 1.10536 8.98043 1.29289 8.79289C1.48043 8.60536 1.73478 8.5 2 8.5H6ZM10 3.5C10.2652 3.5 10.5196 3.60536 10.7071 3.79289C10.8946 3.98043 11 4.23478 11 4.5V6.5C11 6.76522 10.8946 7.01957 10.7071 7.20711C10.5196 7.39464 10.2652 7.5 10 7.5H2C1.73478 7.5 1.48043 7.39464 1.29289 7.20711C1.10536 7.01957 1 6.76522 1 6.5V4.5C1 4.23478 1.10536 3.98043 1.29289 3.79289C1.48043 3.60536 1.73478 3.5 2 3.5H10Z"
        fill={selectedFolder?.justify === "flex-start" ? "white" : "#1A237E"}
      />
    </svg>
  );
}

export default JustifyHorizontalLeft;
