import { useState } from "react";

const UpdateMyValues = (setFolderStructureAction, setSelectedFolderAction) => {
  const updateValueRecursive = (folders, folderId, propertyName, newValue) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, [propertyName]: newValue };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateValueRecursive(
          folder.children,
          folderId,
          propertyName,
          newValue
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  const handleStyleUpdate = (
    folderStructure,
    selectedFolder,
    propertyName,
    newValue
  ) => {
    if (
      (selectedFolder && selectedFolder?.[propertyName]) ||
      selectedFolder?.[propertyName] === "" || selectedFolder?.[propertyName] === false
    ) {
      const updatedSelectedFolder = {
        ...selectedFolder,
        [propertyName]: newValue,
      };

      const updatedFolderStructure = updateValueRecursive(
        folderStructure,
        selectedFolder.id,
        propertyName,
        newValue
      );

      setFolderStructureAction(updatedFolderStructure);
      setSelectedFolderAction(updatedSelectedFolder);
    }
  };

  return { handleStyleUpdate };
};

export default UpdateMyValues;
