import React, { useEffect, useState } from "react";
import profile from "../../assets/Image/profile.jpg";
import weather from "../../assets/Image/weather.png";
import {
  Storage,
  Subscript,
  DesignServices,
  Filter,
} from "@mui/icons-material";
import ImageAssetsModal from "../../common/ImageAssetsModal";
import { Link, useNavigate } from "react-router-dom";
import { userProfile } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import {
  addScreenList,
  editScreenData,
  getSdkVersionList,
  getSdkVersionResourceList,
  publishMyScreen,
  setDefaultValues,
  updateMySdkVersion,
} from "../../redux/actions/defaultActions";
import databaseNew from "../../assets/Icons/databaseNew.svg";
import ApiCloudServicesModal from "../../common/ApiCloudServicesModal";
import DataBaseSvg from "../../common/svgComponents/DataBaseSvg";

const Header = ({
  openModal,
  closeModal,
  modalOpen,
  uploadThisImageHandler,
  screen,
}) => {
  const [openModalData, setOpenModalData] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cuurentSdkVersion = localStorage.getItem("sdkVersion");
  const userSdkVersion = localStorage.getItem("userSdkVersion");

  const myProjectId = localStorage.getItem("project_id");

  const {
    defaultValues,
    folderStructure,
    getMyScreenData,
    getMySdkVersionList,
    updateSdkVersion,
    getSdkResourceList,
  } = useSelector((state) => state.default);

  useEffect(() => {
    dispatch(userProfile());
  }, []);

  useEffect(() => {
    dispatch(getSdkVersionList(myProjectId));
    dispatch(getSdkVersionResourceList(myProjectId));
  }, [updateSdkVersion]);

  useEffect(() => {
    if (getMySdkVersionList) {
      const valuesData = getMySdkVersionList?.filter(
        (item) => item?.isSelected === true
      );

      localStorage.setItem("sdkVersion", valuesData?.[0]?.version);
    }
  }, [getMySdkVersionList]);

  useEffect(() => {
    if (getSdkResourceList) {
      const valuesof = getSdkResourceList?.find(
        (data) => data?.latest === true
      );

      if (userSdkVersion === valuesof?.version) {
        setShowDropdown(true);
      }
    }
  }, [getSdkResourceList]);

  const getProfile = useSelector((state) => state.auth.getProfile);

  const firstLetter = getProfile?.result?.fname
    ? getProfile.result.fname[0]
    : "";

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  const screen_Id = localStorage.getItem("screen_Id");
  const screen_name = localStorage.getItem("screen_name");

  const handlePublishBtn = () => {
    const item = {
      p_id: myProjectId,
      s_id: screen_Id,
    };

    const data = {
      name: screen?.screen?.name,
      screendata: folderStructure,
    };

    dispatch(publishMyScreen(item, data));
  };
  const handleAddScreenData = () => {
    // const screenName = localStorage.getItem("screen_name");
    const data = {
      name: screen?.screen?.name,
      screendata: folderStructure,
    };
    const data2 = localStorage?.getItem("crntVersion_id");

    dispatch(editScreenData(myProjectId, screen_Id, data2, data));
  };

  const projectName = localStorage.getItem("project_name");

  const openDataModal = () => {
    setOpenModalData(true);
  };

  const closeDataModal = () => {
    setOpenModalData(false);
  };

  const sdkVersionChangeHandler = (e) => {
    const value = e.target.value;

    console.log(getMySdkVersionList);
    const valuesData = getMySdkVersionList?.find(
      (item) => item?.isSelected === true
    );

    if (valuesData?.version !== value) {
      dispatch(updateMySdkVersion(myProjectId, value));
    }
  };

  return (
    <>
      <div className="main-header">
        <div className="inner">
          <div className="left-profile">
            <div className="tools-sec">
              {/* <Storage /> */}
              {/* <img
                src={databaseNew}
                alt="database"
               
                onClick={openDataModal}
              /> */}
              <DataBaseSvg onClick={openDataModal} />
              <span>
                <Subscript />
              </span>
              <span>
                <DesignServices />
              </span>
              <span>
                <Filter onClick={() => openModal("direct")} />
              </span>
            </div>
          </div>
          <div className="right-weather">
            {/* <div className="img_1"></div> */}
            <div className="btn_screen_list">
              <button onClick={handlePublishBtn} className="pubBtn">
                Publish
              </button>
              <button onClick={handleAddScreenData}>Save</button>
            </div>
            <div
              className="profile-main"
              onClick={navigateToDashboard}
              style={{ cursor: "pointer" }}
            >
              <div className="img-sec">
                {" "}
                <img
                  src={profile}
                  alt="profile"
                  className="user_first_letter"
                />
                {firstLetter}
              </div>
              <div className="name-sec">
                <p>{projectName}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImageAssetsModal
        openModal={modalOpen}
        onCloseModal1={closeModal}
        uploadThisImageHandler={uploadThisImageHandler}
      />

      <ApiCloudServicesModal
        openModalData={openModalData}
        closeDataModal={closeDataModal}
        openDataModal={openDataModal}
        setOpenModalData={setOpenModalData}
      />
    </>
  );
};

export default Header;
