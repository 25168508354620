import React, { useState, useRef, useEffect } from "react";
import Switch from "react-switch";
import {
  ContentCopy,
  KeyboardArrowUp,
  KeyboardArrowDown,
  FormatIndentDecrease,
  AlignHorizontalCenter,
  VerticalAlignCenter,
  FormatAlignCenter,
  TextFormat,
  AlignHorizontalLeft,
  AlignHorizontalRight,
  FormatAlignJustify,
  FormatAlignLeft,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import Accordion from "react-bootstrap/Accordion";
import { ChromePicker } from "react-color";
import MainMarginPopup from "../../../common/DashboardPopup/InsidePopup/MainMarginPopup";
import Select from "react-select";
import DropDown from "./FunctionComponent/DropDown";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  resetCanvasData,
  setDefaultValues,
  getScreenData,
  setSelectedFolder,
  setFolderStructure,
  setSelectedCanvas,
  updateCanvasBackground,
  selectedFolder,
  addMyStyleData,
  getMySavedStyles,
} from "../../../redux/actions/defaultActions";
import CanvasDisplay from "../CanvasDisplay";
import FontPicker from "font-picker-react";
import axios from "axios";
import { fetchGoogleFonts } from "../../../redux/actions/authActions";
import StyleSheet from "../../../common/styleSheets/StyleSheet";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import DragHandleSharpIcon from "@mui/icons-material/DragHandleSharp";
import LinearScaleSharpIcon from "@mui/icons-material/LinearScaleSharp";
import DashIcon from "../../../assets/Icons/DashIcon.svg";
import qicon from "../../../assets/Icons/qicon.svg";
import qqicon from "../../../assets/Icons/qqicon.svg";
import qqqqicon from "../../../assets/Icons/qqqqicon.svg";
import crossone from "../../../assets/Icons/crossone.svg";
import UpdateMyValues from "../../../common/customHooks/UpdateMyValues";
import SpecifieComponent from "./CommonRightbarTab/SpecifieComponent";
import ToolTipBox from "../../../ToolTip/ToolTipBox";
import AlignTop from "../../../assets/Iconcomponent/AlignTop";
import AlignCenter from "../../../assets/Iconcomponent/AlignCenter";
import AlignLeft from "../../../assets/Iconcomponent/AlignLeft";
import AlignRight from "../../../assets/Iconcomponent/AlignRight";
import AlignBottom from "../../../assets/Iconcomponent/AlignBottom";
import JustifyHorizontalLeft from "../../../assets/Iconcomponent/JustifyHorizontalLeft";
import TextAlign from "../../../assets/Iconcomponent/TextAlign";
import TextCross from "../../../assets/Iconcomponent/TextCross";
import CenterAlign from "../../../assets/Iconcomponent/CenterAlign";
import TextCrossTransform from "../../../assets/Iconcomponent/TextCrossTransform";
import TextAlignRight from "../../../assets/Iconcomponent/TextAlignRight";
import TextAlignLast from "../../../assets/Iconcomponent/TextAlignLast";
import JustifyCenter from "../../../assets/Iconcomponent/JustifyCenter";
import JustifyMidCenter from "../../../assets/Iconcomponent/JustifyMidCenter";
import JustifyLast from "../../../assets/Iconcomponent/JustifyLast";
import JustifyRight from "../../../assets/Iconcomponent/JustifyRight";
import AlignMIddle from "../../../assets/Iconcomponent/AlignMIddle";
import AlignText from "../../../assets/Iconcomponent/AlignText";
import JustifyAlign from "../../../assets/Iconcomponent/JustifyAlign";
import AlignTopSecond from "../../../assets/Iconcomponent/AlignTopSecond";
import AlignTopThird from "../../../assets/Iconcomponent/AlignTopThird";
import AlignCenterSecond from "../../../assets/Iconcomponent/AlignCenterSecond";
import AlignCenterThird from "../../../assets/Iconcomponent/AlignCenterThird";
import AlignSecond from "../../../assets/Iconcomponent/AlignSecond";
import JustifyHorizontalLeftSecond from "../../../assets/Iconcomponent/JustifyHorizontalLeftSecond";
import JustifyCenterSecond from "../../../assets/Iconcomponent/JustifyCenterSecond";
import JustifyMidCenterSecond from "../../../assets/Iconcomponent/JustifyMidCenterSecond";
import JustifyRightSecond from "../../../assets/Iconcomponent/JustifyRightSecond";
import JustifyLastSeond from "../../../assets/Iconcomponent/JustifyLastSeond";
import JustifyAlignThird from "../../../assets/Iconcomponent/JustifyAlignThird";
import JustifyAlignSecond from "../../../assets/Iconcomponent/JustifyAlignSecond";

const colorOptions = [
  { value: "transparent", label: "Transparent" },
  { value: "#ff0000", label: "Red" },
  { value: "#00ff00", label: "Green" },
  { value: "#0000ff", label: "Blue" },
  { value: "#ffffff", label: "white" },
];

const defaultOption = { value: "#ffffff", label: "Default" };

const fontWeightValue = [
  { value: "regular", label: "regular" },
  { value: "bold", label: "bold" },
  { value: "medium", label: "medium" },
  { value: "100", label: "100" },
  { value: "200", label: "200" },
  { value: "300", label: "300" },
  { value: "400", label: "400" },
  { value: "500", label: "500" },
  { value: "600", label: "600" },
  { value: "700", label: "700" },
  { value: "800", label: "800" },
  { value: "900", label: "900" },
];

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#1A237E",
    borderRadius: "5px",
    width: "200px",
    border: "none",
    color: "#fff",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#fffff",
    color: "#1A237E",
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? "#fff" : "#1A237E",
    color: isFocused ? "#1A237E" : "#fff",
    cursor: "pointer",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#ffff",
  }),

  placeholder: (styles) => ({
    ...styles,
    color: "white", // Customize the placeholder color
  }),
};
const customStylesData = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#1A237E",
    borderRadius: "5px",
    width: "195px",
    border: "none",
    minHeight: "30px",
    fontSize: "14px",
    color: "#fff",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#fffff",
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? "#fff" : "#1A237E",
    color: isFocused ? "#fff" : "#fff",
    cursor: "pointer",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#fff",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "White",
    fontSize: "12px",
  }),
};

const MainScroll = ({ showTooltipData, setShowTooltipData, iconType }) => {
  const [checked, setChecked] = useState(false);

  const ReverseSwitch = ({ checked, handleColumnChange, setChecked }) => {
    return (
      <label>
        <Switch
          checked={checked}
          onChange={() => {
            if (checked) {
              handleColumnChange("row");
            } else {
              handleColumnChange("row-reverse");
            }
            setChecked(!checked);
          }}
          onColor="#42a5f5 "
          onHandleColor="#2693e6"
          handleDiameter={29}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          className="react-switch"
          id="material-switch"
        />
      </label>
    );
  };

  const dispatch = useDispatch();
  const { selectedFolder, folderStructure, getMysavedStyles, saveMyStyles } =
    useSelector((state) => state.default);
  const { getMyFontList } = useSelector((state) => state.auth);
  const { getScreenList } = useSelector((state) => state.default);

  const colorPickerRef = useRef();
  const FontcolorPickerRef = useRef();
  const BorderColorPickerRef = useRef();

  const [mainPopup, setMainPopup] = useState("");
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedFontColor, setSelectedFontColor] = useState();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showFontColorPicker, setShowFontColorPicker] = useState(false);
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);
  const [widthInput, setWidthInput] = useState("");
  const [heightInput, setHeightInput] = useState("");
  const [widthUnit, setWidthUnit] = useState("");
  const [heightUnit, setHeightUnit] = useState("");
  const [minMaxvalues, setMinMaxValues] = useState({});
  const [minMaxvaluesUnit, setMinMaxValuesUnit] = useState({});
  const [widthItem, setWidthItem] = useState("");
  const [activeAlignment, setActiveAlignment] = useState("");
  const [rerender, setRerender] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [typography, setTypography] = useState({});
  const [typography2, setTypography2] = useState();
  const [styleSheetModal, setStyleSheetModal] = useState(false);
  const [selectedBorderColor, setSelectedBorderColor] = useState(
    selectedFolder?.borderColor
  );

  const [searchedProducts, setSearchedProducts] = useState();
  const [addStyleValue, setStyleValue] = useState();
  const [selectedStyleValue, setSelectedStyleValue] = useState();
  const [bottomSnap, setBottomSnap] = useState({ value: 10, unit: "%" });
  const [middleSnap, setMiddleSnap] = useState({ value: 50, unit: "%" });
  const [topSnap, setTopSnap] = useState({ value: 80, unit: "%" });
  const [tooltipContent, setTooltipContent] = useState();
  const tooltipRef = useRef();
  const keypressed = useRef("");
  const keypressedEnter = useRef("");

  const handleIconClick = (e, type) => {
    e.stopPropagation();

    if (type === tooltipContent) {
      setShowTooltipData(false);
      setTooltipContent();
    } else {
      setTooltipContent(type);
      setShowTooltipData(true);
    }
  };

  useEffect(() => {
    if (selectedFolder) {
      setTypography(selectedFolder);
      setSelectedFontColor(selectedFolder?.color);
    }
  }, [selectedFolder]);

  useEffect(() => {
    dispatch(getMySavedStyles(localStorage.getItem("project_id")));
  }, [saveMyStyles]);

  useEffect(() => {
    if (getMysavedStyles) {
      const filtered = getMysavedStyles.filter(
        (item) => item.styletype === selectedFolder?.type
      );
      setSearchedProducts(filtered);
    }
  }, [getMysavedStyles, selectedFolder]);

  useEffect(() => {
    const findParentObject = (parentId, folders) => {
      for (const folder of folders) {
        if (folder.id === parentId) {
          setTypography2(folder);
          return;
        }
        if (folder.children && folder.children.length > 0) {
          findParentObject(parentId, folder.children);
        }
      }
    };
    findParentObject(selectedFolder?.parentId, folderStructure);
  }, [selectedFolder, folderStructure]);

  const fontWeights = [
    { value: "regular", label: "Regular" },
    { value: "bold", label: "Bold" },
    { value: "medium", label: "Medium" },
    { value: "100", label: "100" },
    { value: "200", label: "200" },
    { value: "300", label: "300" },
  ];

  const fontKey = process.env.REACT_APP_FONT_API_KEY;

  useEffect(() => {
    dispatch(fetchGoogleFonts());
  }, []);

  useEffect(() => {
    if (selectedFolder) {
      setSelectedColor();
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (selectedFolder && selectedFolder.size) {
      setWidthInput(selectedFolder.size ? selectedFolder.size.width : "");
      setHeightInput(
        selectedFolder.size ? selectedFolder.size.height : ""
      );
      setWidthUnit(selectedFolder.size ? "px" : "");
      setHeightUnit(selectedFolder.size ? "px" : "");
      const datas = {
        min_width: selectedFolder.size
          ? selectedFolder.size?.min_width
          : "",
        min_height: selectedFolder.size
          ? selectedFolder.size.min_height
          : "",
        max_width: selectedFolder.size
          ? selectedFolder.size.max_width
          : "",
        max_height: selectedFolder.size
          ? selectedFolder.size.max_height
          : "",
      };

      setMinMaxValues(datas);

      const data2 = {
        min_width: selectedFolder.size ? "px" : "",
        min_height: selectedFolder.size ? "px" : "",
        max_width: selectedFolder.size ? "px" : "",
        max_height: selectedFolder.size ? "px" : "",
      };

      setMinMaxValuesUnit(data2);
    }
    if (selectedFolder && selectedFolder.flex) {
      setWidthItem(selectedFolder.flex[0] ? selectedFolder.flex[0].width : "");
    }
  }, [selectedFolder]);



  useEffect(() => {
    document.addEventListener("click", handleCloseColorPicker);
    return () => {
      document.removeEventListener("click", handleCloseColorPicker);
    };
  }, []);

  const customStyling = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? "#eceff0"
              : undefined,
        color: isDisabled ? "green" : isSelected,

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "#eceff0"
            : undefined,
        },
      };
    },
  };

  const setSelectedFolderAction = (folder) =>
    dispatch(setSelectedFolder(folder));

  const setFolderStructureAction = (structure) =>
    dispatch(setFolderStructure(structure));

  const closeMainPopup = () => setMainPopup(false);

  const { handleStyleUpdate } = UpdateMyValues(
    setFolderStructureAction,
    setSelectedFolderAction
  );

  const handleWidthChange = (e) => {
    const newWidth = e.target.value;
    console.log("handlwidt" + selectedFolder.size);
    if (selectedFolder && selectedFolder.size) {
      const newSize =
      {
        width: newWidth,
        height: selectedFolder.size.height,
        min_height: selectedFolder.size.min_height,
        min_width: selectedFolder.size?.min_width,
        max_height: selectedFolder.size.max_height,
        max_width: selectedFolder.size.max_width,

      };
      console.log("handlwidth newSize " + newSize);
      handleStyleUpdate(folderStructure, selectedFolder, "size", newSize);
    }
  };

  const handleHeightChange = (e) => {
    const newHeight = e.target.value;
    if (selectedFolder && selectedFolder.size) {
      const newSize =
      {
        width: selectedFolder.size.width,
        height: newHeight,
        min_height: selectedFolder.size.min_height,
        min_width: selectedFolder.size?.min_width,
        max_height: selectedFolder.size?.max_height,
        max_width: selectedFolder.size?.max_width,

      };

      handleStyleUpdate(folderStructure, selectedFolder, "size", newSize);
    }
  };

  const minMaxvaluechangeHandler = (e) => {
    setRerender(true);
    setMinMaxValues({ ...minMaxvalues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (rerender) {
      if (
        selectedFolder &&
        selectedFolder.size &&
        (selectedFolder.type === "view" || selectedFolder.type === "image")
      ) {
        if (minMaxvalues || minMaxvaluesUnit) {
          const newSize =
          {
            width: selectedFolder?.size?.width || "auto",

            height: selectedFolder?.size?.height || "auto",

            min_width:
              minMaxvalues?.min_width !== undefined
                ? minMaxvalues?.min_width
                : selectedFolder?.size?.min_width || "auto",

            min_height:
              minMaxvalues?.min_height !== undefined
                ? minMaxvalues.min_height
                : selectedFolder?.size?.min_height || "auto",

            max_width:
              minMaxvalues?.max_width !== undefined
                ? minMaxvalues?.max_width
                : selectedFolder?.size?.max_width || "auto",

            max_height:
              minMaxvalues?.max_height !== undefined
                ? minMaxvalues?.max_height
                : selectedFolder?.size?.max_height || "auto",

          };
          handleStyleUpdate(folderStructure, selectedFolder, "size", newSize);
        }
      }
      setRerender(false);
    }
  }, [minMaxvalues, minMaxvaluesUnit]);

  const minMaxchangeHandler = (e) => {
    setRerender(true);
    setMinMaxValuesUnit({
      ...minMaxvaluesUnit,
      [e.target.name]: e.target.value,
    });
  };

  const handleColumnChange = (types) => {
    if (types !== "row-reverse") {
      setChecked(false);
    }
    handleStyleUpdate(folderStructure, selectedFolder, "direction", types);
  };

  const handleFontColorChange = () => {
    handleTextfontColorChange(selectedFontColor);
  };

  const handleTextfontColorChange = (data) => {
    setActiveAlignment(data);
    handleStyleUpdate(folderStructure, selectedFolder, "color", data);
  };

  const handleApplyButtonClick = (data) => {
    setActiveAlignment(data);
    handleStyleUpdate(folderStructure, selectedFolder, "background", data);
  };

  const handleMarginChange = (e) => {
    const newMargin = e.target.value;
    if (selectedFolder) {
      let updatedMargin;
      if (e.target.name === "all") {
        updatedMargin =
        {
          all: newMargin,
          left: "",
          top: "",
          right: "",
          bottom: ""
        };

      } else {
        updatedMargin =
        {
          all: "",
          left: newMargin,
          top: newMargin,
          right: newMargin,
          bottom: newMargin
        };
      }
      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "margin",
        updatedMargin
      );
    }
  };

  const handleTopMarginChange = (e) => {
    const newMargin = e.target.value;
    if (selectedFolder) {
      const updatedMargin =
      {
        all: selectedFolder?.margin.all,
        left: selectedFolder?.margin.left,
        top: newMargin,
        right: selectedFolder?.margin.right,
        bottom: selectedFolder?.margin.bottom
      };


      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "margin",
        updatedMargin
      );
    }
  };

  const handleLeftMarginChange = (e) => {
    const newMargin = e.target.value;
    if (selectedFolder) {
      const updatedMargin =
      {
        all: selectedFolder?.margin.all,
        left: newMargin,
        top: selectedFolder?.margin.top,
        right: selectedFolder?.margin.right,
        bottom: selectedFolder?.margin.bottom
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "margin",
        updatedMargin
      );
    }
  };

  const handleRightMarginChange = (e) => {
    const newMargin = e.target.value;
    if (selectedFolder) {
      const updatedMargin =
      {
        all: selectedFolder?.margin.all,
        left: selectedFolder?.margin.left,
        top: selectedFolder?.margin.top,
        right: newMargin,
        bottom: selectedFolder?.margin.bottom
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "margin",
        updatedMargin
      );
    }
  };

  const handleBottomMarginChange = (e) => {
    const newMargin = e.target.value;
    if (selectedFolder) {
      const updatedMargin =
      {
        all: selectedFolder?.margin.all,
        left: selectedFolder?.margin.left,
        top: selectedFolder?.margin.top,
        right: selectedFolder?.margin.right,
        bottom: newMargin
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "margin",
        updatedMargin
      );
    }
  };

  const handlePaddingChange = (e) => {
    const newPadding = e.target.value;
    if (selectedFolder) {
      let updatedPadding;
      if (e.target.name === "all") {
        updatedPadding =
        {
          all: newPadding,
          left: "",
          top: "",
          right: "",
          bottom: ""
        };

      } else {
        updatedPadding =
        {
          all: "",
          left: newPadding,
          top: newPadding,
          right: newPadding,
          bottom: newPadding
        };
      }

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "padding",
        updatedPadding
      );
    }
  };

  const handleTopPaddingChange = (e) => {
    const newPadding = e.target.value;
    if (selectedFolder) {
      const updatedPadding =
      {
        all: selectedFolder?.padding.all,
        left: selectedFolder?.padding.left,
        top: newPadding,
        right: selectedFolder?.padding.right,
        bottom: selectedFolder?.padding.bottom
      };


      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "padding",
        updatedPadding
      );
    }
  };

  const handleLeftPaddingChange = (e) => {
    const newPadding = e.target.value;
    if (selectedFolder) {
      const updatedPadding =
      {
        all: selectedFolder?.padding.all,
        left: newPadding,
        top: selectedFolder?.padding.top,
        right: selectedFolder?.padding.right,
        bottom: selectedFolder?.padding.bottom
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "padding",
        updatedPadding
      );
    }
  };
  const handleRightPaddingChange = (e) => {
    const newPadding = e.target.value;
    if (selectedFolder) {
      const updatedPadding =
      {
        all: selectedFolder?.padding.all,
        left: selectedFolder?.padding.left,
        top: selectedFolder?.padding.top,
        right: newPadding,
        bottom: selectedFolder?.padding.bottom
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "padding",
        updatedPadding
      );
    }
  };

  const handleBottomPaddingChange = (e) => {
    const newPadding = e.target.value;
    if (selectedFolder) {
      const updatedPadding =
      {
        all: selectedFolder?.padding.all,
        left: selectedFolder?.padding.left,
        top: selectedFolder?.padding.top,
        right: selectedFolder?.padding.right,
        bottom: newPadding
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "padding",
        updatedPadding
      );
    }
  };

  const handleSnapChange = (e, type) => {
    let updateSnaps = [];
    const snaps = e.target.value;
    if (selectedFolder) {
      if (type === "top") {
        updateSnaps = [
          { ...selectedFolder?.snaps[0], topSnap: snaps },
          { ...selectedFolder?.snaps[1] },
          { ...selectedFolder?.snaps[2] },
        ];
      } else if (type === "middle") {
        updateSnaps = [
          { ...selectedFolder?.snaps[0] },
          { ...selectedFolder?.snaps[1], middleSnap: snaps },
          { ...selectedFolder?.snaps[2] },
        ];
      } else if (type === "bottom") {
        updateSnaps = [
          { ...selectedFolder?.snaps[0] },
          { ...selectedFolder?.snaps[1] },
          { ...selectedFolder?.snaps[2], bottomSnap: snaps },
        ];
      }
      console.log(updateSnaps);
      handleStyleUpdate(folderStructure, selectedFolder, "snaps", updateSnaps);
    }
  };

  const handleAlignChange = (data) => {
    setActiveAlignment(data);
    handleStyleUpdate(folderStructure, selectedFolder, "align", data);
  };

  const handleAlignContentChange = (data) => {
    setActiveAlignment(data);
    handleStyleUpdate(folderStructure, selectedFolder, "alignContent", data);
  };
  const handleJustifyChange = (data) => {
    handleStyleUpdate(folderStructure, selectedFolder, "justify", data);
  };

  const updateParentAlign = (folders, folderId, align) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, align: align };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateParentAlign(
          folder.children,
          folderId,
          align
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  const selectedParentAlign = (types) => {
    const updatedFolderStructure = updateParentAlign(
      folderStructure,
      selectedFolder.parentId,
      types
    );
    setFolderStructureAction(updatedFolderStructure);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
  };
  // const handleFontColor = (color) => {
  //   setSelectedFontColor(color.hex);
  // };

  const handleFontColor = (newColor) => {
    const hexColor = newColor.hex.replace(/\s/g, ""); // Remove spaces from hex code
    setSelectedFontColor(hexColor);
  };

  const toggleColorPicker = (event) => {
    event.stopPropagation();
    setShowColorPicker(!showColorPicker);
  };

  const toggleFontColorPicker = (event) => {
    event.stopPropagation();
    setShowFontColorPicker(true);
  };
  const toggleBorderColorPicker = (event) => {
    event.stopPropagation();
    setShowBorderColorPicker(true);
  };

  const handleCloseColorPicker = (event) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      if (
        event.target.className === "chrome-color-picker" ||
        colorPickerRef.current.contains(event.target)
      ) {
        return;
      }

      setShowColorPicker(false);
    }
    if (
      FontcolorPickerRef.current &&
      !FontcolorPickerRef.current.contains(event.target)
    ) {
      if (
        event.target.className === "chrome-color-picker" ||
        FontcolorPickerRef.current.contains(event.target)
      ) {
        return;
      }

      setShowFontColorPicker(false);
    }
    if (
      BorderColorPickerRef.current &&
      !BorderColorPickerRef.current.contains(event.target)
    ) {
      if (
        event.target.className === "chrome-color-picker" ||
        BorderColorPickerRef.current.contains(event.target)
      ) {
        return;
      }

      setShowBorderColorPicker(false);
    }
  };

  const openMainPopup = (key, marginValue) => {
    setMainPopup(key);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color ? color.value : null);
  };

  const handleWidthUnitChange = (e) => {
    setWidthUnit(e.target.value);
    setWidthInput("");
  };

  const handleHeightUnitChange = (e) => {
    setHeightUnit(e.target.value);
    setHeightInput("");
  };

  const handleLetterSpacingChange = (e) => {
    const newLetterSpacing = e.target.value;
    setTypography({ ...typography, [e.target.name]: newLetterSpacing });
    if (
      (selectedFolder && selectedFolder?.type == "text") ||
      selectedFolder?.type == "datepicker"
    ) {
      if (newLetterSpacing !== "") {
        handleStyleUpdate(
          folderStructure,
          selectedFolder,
          "letterSpacing",
          newLetterSpacing
        );
      } else {
        console.error(
          "Error: selectedFolder or its style property is not properly initialized."
        );
      }
    }
  };

  const handleFontSizeChange = (e) => {
    const newFontSize = e.target.value;

    setTypography({ ...typography, [e.target.name]: newFontSize });
    if (
      (selectedFolder && selectedFolder?.type === "text") ||
      selectedFolder?.type === "datepicker"
    ) {
      if (newFontSize !== "") {
        handleStyleUpdate(
          folderStructure,
          selectedFolder,
          "fontSize",
          newFontSize
        );
      } else {
        console.error(
          "Error: selectedFolder or its style property is not properly initialized."
        );
      }
    }
  };

  const handleLineHeightChange = (e) => {
    const newlineHeight = e.target.value;
    setTypography({ ...typography, [e.target.name]: newlineHeight });
    if (
      (selectedFolder && selectedFolder?.type === "text") ||
      selectedFolder?.type === "datepicker"
    ) {
      if (newlineHeight !== "") {
        handleStyleUpdate(
          folderStructure,
          selectedFolder,
          "lineHeight",
          newlineHeight
        );
      } else {
        console.error(
          "Error: selectedFolder or its style property is not properly initialized."
        );
      }
    }
  };

  const handleFontFamilyChange = (e) => {
    const newFontFamily = e.target.value;
    setTypography({ ...typography, [e.target.name]: newFontFamily });
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "fontFamily",
      newFontFamily
    );
  };

  const handleFontWeightChange = (e) => {
    const newfontWeight = e.target.value;
    setTypography({ ...typography, [e.target.name]: newfontWeight });
    if (newfontWeight !== "") {
      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "fontWeight",
        newfontWeight
      );
    } else {
      console.error(
        "Error: selectedFolder or its style property is not properly initialized."
      );
    }
  };

  const handleFontColorSelect = (data) => {
    setSelectedFontColor(data.value);
  };

  const handleTextTransformChange = (data) => {
    setActiveAlignment(data);
    handleStyleUpdate(folderStructure, selectedFolder, "textTransform", data);
  };

  const handleTextAlignChange = (data) => {
    setActiveAlignment(data);
    handleStyleUpdate(folderStructure, selectedFolder, "textAlign", data);
  };
  const handleWrapChange = (data) => {
    handleStyleUpdate(folderStructure, selectedFolder, "flexWrap", data);
  };

  const appStyle = {
    backgroundColor: selectedColor,
  };
  const appColor = {
    color: selectedFontColor,
  };
  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const styleSheetEdit = () => {
    setStyleSheetModal(true);
  };

  const closeDataModal = () => {
    setStyleSheetModal(false);
  };

  const handleBorderChange = (color) => {
    setSelectedBorderColor(color.hex);
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "borderColor",
      color.hex
    );
  };

  const handleBorderColorSelect = (color) => {
    setSelectedBorderColor(color ? color.value : null);
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "borderColor",
      color.value
    );
  };

  const handleBorderColorChange = (data) => {
    setActiveAlignment(data);
    handleStyleUpdate(folderStructure, selectedFolder, "borderColor", data);
  };

  const handleBorderTextchange = (data) => {
    handleStyleUpdate(folderStructure, selectedFolder, "textdecoration", data);
  };

  const HandleBorderImageChange = (data) => {
    handleStyleUpdate(folderStructure, selectedFolder, "borderStyle", data);
  };

  const appBorderColor = {
    color: selectedBorderColor,
  };
  function updateSelectedFolder(styleSheet, selectedFolder) {
    for (const key in styleSheet) {
      if (
        styleSheet.hasOwnProperty(key) &&
        styleSheet[key] !== undefined &&
        selectedFolder.hasOwnProperty(key)
      ) {
        if (Array.isArray(styleSheet[key])) {
          selectedFolder[key] = styleSheet[key].map((item) => {
            return { ...item };
          });
        } else {
          selectedFolder[key] = styleSheet[key];
        }
      }
    }
  }

  const updateValueRecursive = (folders, folderId, propertyUpdates) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        // Update properties in the current folder
        return { ...folder, ...propertyUpdates };
      } else if (folder.children && folder.children.length > 0) {
        // Recursively update properties in children
        const updatedChildren = updateValueRecursive(
          folder.children,
          folderId,
          propertyUpdates
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  const styleSheetUpdateAll = (
    folderStructure,
    selectedFolder,
    propertyUpdates
  ) => {
    if (
      selectedFolder &&
      Object.keys(propertyUpdates).some(
        (propertyName) => selectedFolder[propertyName]
      )
    ) {
      const updatedSelectedFolder = {
        ...selectedFolder,
        ...propertyUpdates,
      };

      const updatedFolderStructure = updateValueRecursive(
        folderStructure,
        selectedFolder.id,
        propertyUpdates
      );

      setFolderStructureAction(updatedFolderStructure);
      setSelectedFolderAction(updatedSelectedFolder);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedStyleValue(selectedOption?.value?.data);
    updateSelectedFolder(selectedOption?.value?.data, selectedFolder);
    styleSheetUpdateAll(
      folderStructure,
      selectedFolder,
      selectedOption?.value?.data
    );
  };

  const handleInputChange = (newValue) => {
    setStyleValue(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (selectedFolder?.type === "text") {
        dispatch(
          addMyStyleData(localStorage.getItem("project_id"), {
            styles: [
              {
                stylename: addStyleValue,
                componenttype: selectedFolder?.type,
                stylesdata: {
                  color: selectedFolder && selectedFolder?.color,
                  fontSize: selectedFolder && selectedFolder?.fontSize,
                  letterSpacing:
                    selectedFolder && selectedFolder?.letterSpacing,
                  lineHeight: selectedFolder && selectedFolder?.lineHeight,
                  fontFamily: selectedFolder && selectedFolder?.fontFamily,
                  textTransform:
                    selectedFolder && selectedFolder?.textTransform,
                  textAlign: selectedFolder && selectedFolder?.textAlign,
                  textdecoration:
                    selectedFolder && selectedFolder?.textdecoration,
                  borderStyle: selectedFolder && selectedFolder?.borderStyle,
                  borderColor: selectedFolder && selectedFolder?.borderColor,
                  fontWeight: selectedFolder && selectedFolder?.fontWeight,
                  borderWidth: selectedFolder && selectedFolder?.borderWidth,
                  margin:
                  {
                    all: selectedFolder && selectedFolder?.margin.all,

                    left: selectedFolder && selectedFolder?.margin.left,

                    top: selectedFolder && selectedFolder?.margin.top,

                    right: selectedFolder && selectedFolder?.margin.right,

                    bottom:
                      selectedFolder && selectedFolder?.margin.bottom,

                  },

                  padding:
                  {
                    all: selectedFolder && selectedFolder?.padding.all,

                    left: selectedFolder && selectedFolder?.padding.left,

                    top: selectedFolder && selectedFolder?.padding.top,

                    right: selectedFolder && selectedFolder?.padding.right,

                    bottom:
                      selectedFolder && selectedFolder?.padding.bottom,

                  },

                  borderWidth:
                  {
                    all: selectedFolder && selectedFolder?.borderWidth.all,
                    left:
                      selectedFolder && selectedFolder?.borderWidth.left,
                    top: selectedFolder && selectedFolder?.borderWidth.top,
                    right:
                      selectedFolder && selectedFolder?.borderWidth.right,
                    bottom:
                      selectedFolder && selectedFolder?.borderWidth.bottom,

                  },

                  borderRadius:
                  {
                    all:
                      selectedFolder && selectedFolder?.borderRadius.all,
                    topLeft:
                      selectedFolder &&
                      selectedFolder?.borderRadius.topLeft,
                    topRight:
                      selectedFolder &&
                      selectedFolder?.borderRadius.topRight,
                    bottomLeft:
                      selectedFolder &&
                      selectedFolder?.borderRadius.bottomLeft,
                    bottomRight:
                      selectedFolder &&
                      selectedFolder?.borderRadius.bottomRight,

                  },

                },
              },
            ],
          })
        );
      } else if (selectedFolder?.type === "view") {
        dispatch(
          addMyStyleData(localStorage.getItem("project_id"), {
            styles: [
              {
                stylename: addStyleValue,
                componenttype: selectedFolder?.type,
                stylesdata: {
                  borderStyle: selectedFolder && selectedFolder?.borderStyle,
                  borderColor: selectedFolder && selectedFolder?.borderColor,
                  borderWidth: selectedFolder && selectedFolder?.borderWidth,
                  align: selectedFolder && selectedFolder?.align,
                  alignContent: selectedFolder && selectedFolder?.alignContent,
                  alignitem: selectedFolder && selectedFolder?.alignitem,
                  background: selectedFolder && selectedFolder?.background,
                  direction: selectedFolder && selectedFolder?.direction,
                  display: selectedFolder && selectedFolder?.display,
                  flexWrap: selectedFolder && selectedFolder?.flexWrap,
                  justify: selectedFolder && selectedFolder?.justify,
                  margin:
                  {
                    all: selectedFolder && selectedFolder?.margin.all,

                    left: selectedFolder && selectedFolder?.margin.left,

                    top: selectedFolder && selectedFolder?.margin.top,

                    right: selectedFolder && selectedFolder?.margin.right,

                    bottom:
                      selectedFolder && selectedFolder?.margin.bottom,

                  },

                  padding:
                  {
                    all: selectedFolder && selectedFolder?.padding.all,

                    left: selectedFolder && selectedFolder?.padding.left,

                    top: selectedFolder && selectedFolder?.padding.top,

                    right: selectedFolder && selectedFolder?.padding.right,

                    bottom:
                      selectedFolder && selectedFolder?.padding.bottom,

                  },

                  borderWidth:
                  {
                    all: selectedFolder && selectedFolder?.borderWidth.all,
                    left:
                      selectedFolder && selectedFolder?.borderWidth.left,
                    top: selectedFolder && selectedFolder?.borderWidth.top,
                    right:
                      selectedFolder && selectedFolder?.borderWidth.right,
                    bottom:
                      selectedFolder && selectedFolder?.borderWidth.bottom,

                  },
                  borderRadius:
                  {
                    all:
                      selectedFolder && selectedFolder?.borderRadius.all,
                    topLeft:
                      selectedFolder &&
                      selectedFolder?.borderRadius.topLeft,
                    topRight:
                      selectedFolder &&
                      selectedFolder?.borderRadius.topRight,
                    bottomLeft:
                      selectedFolder &&
                      selectedFolder?.borderRadius.bottomLeft,
                    bottomRight:
                      selectedFolder &&
                      selectedFolder?.borderRadius.bottomRight,
                  },

                  size:
                  {
                    width: selectedFolder && selectedFolder?.size.width,

                    height: selectedFolder && selectedFolder?.size.height,

                  },


                },
              },
            ],
          })
        );
      } else if (selectedFolder?.type === "image") {
        dispatch(
          addMyStyleData(localStorage.getItem("project_id"), {
            styles: [
              {
                stylename: addStyleValue,
                componenttype: selectedFolder?.type,
                stylesdata: {
                  borderStyle: selectedFolder && selectedFolder?.borderStyle,
                  borderColor: selectedFolder && selectedFolder?.borderColor,
                  borderWidth: selectedFolder && selectedFolder?.borderWidth,
                  margin:
                  {
                    all: selectedFolder && selectedFolder?.margin.all,

                    left: selectedFolder && selectedFolder?.margin.left,

                    top: selectedFolder && selectedFolder?.margin.top,

                    right: selectedFolder && selectedFolder?.margin.right,

                    bottom:
                      selectedFolder && selectedFolder?.margin.bottom,

                  },

                  size:
                  {
                    width: selectedFolder && selectedFolder?.size.width,

                    height: selectedFolder && selectedFolder?.size.height,

                  },

                  borderWidth:
                  {
                    all: selectedFolder && selectedFolder?.borderWidth.all,
                    left:
                      selectedFolder && selectedFolder?.borderWidth.left,
                    top: selectedFolder && selectedFolder?.borderWidth.top,
                    right:
                      selectedFolder && selectedFolder?.borderWidth.right,
                    bottom:
                      selectedFolder && selectedFolder?.borderWidth.bottom,

                  },
                  borderRadius:
                  {
                    all:
                      selectedFolder && selectedFolder?.borderRadius.all,
                    topLeft:
                      selectedFolder &&
                      selectedFolder?.borderRadius.topLeft,
                    topRight:
                      selectedFolder &&
                      selectedFolder?.borderRadius.topRight,
                    bottomLeft:
                      selectedFolder &&
                      selectedFolder?.borderRadius.bottomLeft,
                    bottomRight:
                      selectedFolder &&
                      selectedFolder?.borderRadius.bottomRight,
                  },

                },
              },
            ],
          })
        );
      } else if (selectedFolder?.type === "bottomSheets") {
        dispatch(
          addMyStyleData(localStorage.getItem("project_id"), {
            styles: [
              {
                stylename: addStyleValue,
                componenttype: selectedFolder?.type,
                stylesdata: {
                  borderStyle: selectedFolder && selectedFolder?.borderStyle,
                  borderColor: selectedFolder && selectedFolder?.borderColor,
                  borderWidth: selectedFolder && selectedFolder?.borderWidth,
                  margin:
                  {
                    all: selectedFolder && selectedFolder?.margin.all,

                    left: selectedFolder && selectedFolder?.margin.left,

                    top: selectedFolder && selectedFolder?.margin.top,

                    right: selectedFolder && selectedFolder?.margin.right,

                    bottom:
                      selectedFolder && selectedFolder?.margin.bottom,

                  },

                  size:
                  {
                    width: selectedFolder && selectedFolder?.size.width,
                    height: selectedFolder && selectedFolder?.size.height,

                  },


                  borderWidth:
                  {
                    all: selectedFolder && selectedFolder?.borderWidth.all,
                    left:
                      selectedFolder && selectedFolder?.borderWidth.left,
                    top: selectedFolder && selectedFolder?.borderWidth.top,
                    right:
                      selectedFolder && selectedFolder?.borderWidth.right,
                    bottom:
                      selectedFolder && selectedFolder?.borderWidth.bottom,

                  },
                  borderRadius:
                  {
                    all:
                      selectedFolder && selectedFolder?.borderRadius.all,
                    topLeft:
                      selectedFolder &&
                      selectedFolder?.borderRadius.topLeft,
                    topRight:
                      selectedFolder &&
                      selectedFolder?.borderRadius.topRight,
                    bottomLeft:
                      selectedFolder &&
                      selectedFolder?.borderRadius.bottomLeft,
                    bottomRight:
                      selectedFolder &&
                      selectedFolder?.borderRadius.bottomRight,
                  },
                },
              },
            ],
          })
        );
      }
    }
  };

  const updateBorderWidthRecursively = (folders, folderId, newWidth) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, width: newWidth };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateBorderWidthRecursively(
          folder.children,
          folderId,
          newWidth
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  const handleWidthValueChange = (e) => {
    const newWidth = e.target.value;
    if (selectedFolder) {
      let updatedWidth;
      if (e.target.name === "all") {
        updatedWidth =
        {
          all: newWidth,
          left: "",
          top: "",
          right: "",
          bottom: ""
        };

      } else {
        updatedWidth =
        {
          all: "",
          left: newWidth,
          top: newWidth,
          right: newWidth
        };

      }


      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderWidth",
        updatedWidth
      );
    }
  };

  const handleTopWidthValueChange = (e) => {
    const newWidth = e.target.value;
    if (selectedFolder) {
      const updatedWidth =
      {
        all: selectedFolder?.borderWidth.all,
        left: selectedFolder?.borderWidth.left,
        top: newWidth,
        right: selectedFolder?.borderWidth.right,
        bottom: selectedFolder?.borderWidth.bottom
      };


      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderWidth",
        updatedWidth
      );
    }
  };

  const handleBottomWidthValueChange = (e) => {
    const newWidth = e.target.value;
    if (selectedFolder) {
      const updatedWidth =
      {
        all: selectedFolder?.borderWidth.all,
        left: selectedFolder?.borderWidth.left,
        top: selectedFolder?.borderWidth.top,
        right: selectedFolder?.borderWidth.right,
        bottom: newWidth
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderWidth",
        updatedWidth
      );
    }
  };

  const handleLeftWidthValueChange = (e) => {
    const newWidth = e.target.value;
    if (selectedFolder) {
      const updatedWidth =
      {
        all: selectedFolder?.borderWidth.all,
        left: newWidth,
        top: selectedFolder?.borderWidth.top,
        right: selectedFolder?.borderWidth.right,
        bottom: selectedFolder?.borderWidth.bottom
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderWidth",
        updatedWidth
      );
    }
  };
  const handleRightWidthValueChange = (e) => {
    const newWidth = e.target.value;
    if (selectedFolder) {
      const updatedWidth =
      {
        all: selectedFolder?.borderWidth.all,
        left: selectedFolder?.borderWidth.left,
        top: selectedFolder?.borderWidth.top,
        right: newWidth,
        bottom: selectedFolder?.borderWidth.bottom
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderWidth",
        updatedWidth
      );
    }
  };

  const handleBorderRadiusChange = (e) => {
    const newRadius = e.target.value;
    if (selectedFolder) {
      let updatedRadius;
      if (e.target.name === "all") {
        updatedRadius =
        {
          all: newRadius,
          topLeft: "0",
          topRight: "0",
          bottomLeft: "0",
          bottomRight: "0",
        };

      } else {
        updatedRadius =
        {
          all: "",
          topLeft: newRadius,
          topRight: newRadius,
          bottomLeft: newRadius,
          bottomRight: newRadius
        };

      }

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderRadius",
        updatedRadius
      );
    }
  };
  const handleBorderleftRadiusChange = (e) => {
    const newRadius = e.target.value;
    if (selectedFolder) {
      let updatedRadius = {
        all: "0",
        topLeft: newRadius,
        topRight: selectedFolder?.borderRadius.topRight,
        bottomLeft: selectedFolder?.borderRadius.bottomLeft,
        bottomRight: selectedFolder?.borderRadius.bottomRight
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderRadius",
        updatedRadius
      );
    }
  };
  const handleBorderRightRadiusChange = (e) => {
    const newRadius = e.target.value;
    if (selectedFolder) {

      const updatedRadius = {
        all: "0",
        topLeft: selectedFolder?.borderRadius.topLeft,
        topRight: newRadius,
        bottomLeft: selectedFolder?.borderRadius.bottomLeft,
        bottomRight: selectedFolder?.borderRadius.bottomRight
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderRadius",
        updatedRadius
      );
    }
  };

  const handleBorderBottomLeftRadiusChange = (e) => {
    const newRadius = e.target.value;
    if (selectedFolder) {
      const updatedRadius = {
        all: "0",
        topLeft: selectedFolder?.borderRadius.topLeft,
        topRight: selectedFolder?.borderRadius.topRight,
        bottomLeft: newRadius,
        bottomRight: selectedFolder?.borderRadius.bottomRight
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderRadius",
        updatedRadius
      );
    }
  };
  const handleBorderBottomRightRadiusChange = (e) => {
    const newRadius = e.target.value;
    if (selectedFolder) {
      const updatedRadius = {
        all: "0",
        topLeft: selectedFolder?.borderRadius.topLeft,
        topRight: selectedFolder?.borderRadius.topRight,
        bottomLeft: selectedFolder?.borderRadius.bottomLeft,
        bottomRight: newRadius
      };

      handleStyleUpdate(
        folderStructure,
        selectedFolder,
        "borderRadius",
        updatedRadius
      );
    }
  };

  return (
    <>
      <div className="main-scroll">
        <div className="scrollable-comp">
          <div className="style-bar">
            <Accordion flush defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Stylesheet</Accordion.Header>
                <Accordion.Body>
                  <div className="main-select">
                    <div className="left-sec">
                      <Select
                        placeholder="Select or Create New..."
                        styles={customStylesData}
                        className="inputfor"
                        hideSelectedOptions
                        name="option-select"
                        noOptionsMessage={() => <div>No Styles Available</div>}
                        inputValue={addStyleValue?.name}
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        options={
                          selectedFolder?.id &&
                          searchedProducts?.map((data) => ({
                            value: data,
                            label: data.name,
                          }))
                        }
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div className="right-secs">
                      <div className="icns">
                        <span onClick={styleSheetEdit}>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="far"
                            data-icon="pen"
                            className="svg-inline--fa fa-pen fa-xs fa-swap-opacity "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M36.4 360.9L13.4 439 1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1L73 498.6l78.1-23c12.4-3.6 23.7-9.9 33.4-18.4c1.4-1.2 2.7-2.5 4-3.8L492.7 149.3c21.9-21.9 24.6-55.6 8.2-80.5c-2.3-3.5-5.1-6.9-8.2-10L453.3 19.3c-25-25-65.5-25-90.5 0L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4zm46 13.5c1.7-5.6 4.5-10.8 8.4-15.2c.6-.6 1.1-1.2 1.7-1.8L321 129 383 191 154.6 419.5c-4.7 4.7-10.6 8.2-17 10.1l-23.4 6.9L59.4 452.6l16.1-54.8 6.9-23.4z"
                            ></path>
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_3385_1612)">
                              <path
                                d="M1.75 12.6875H7.875C8.11563 12.6875 8.3125 12.4906 8.3125 12.25V10.5H9.625V12.25C9.625 13.2152 8.84023 14 7.875 14H1.75C0.784766 14 0 13.2152 0 12.25V6.125C0 5.15977 0.784766 4.375 1.75 4.375H3.5V5.6875H1.75C1.50937 5.6875 1.3125 5.88438 1.3125 6.125V12.25C1.3125 12.4906 1.50937 12.6875 1.75 12.6875ZM6.125 8.3125H12.25C12.4906 8.3125 12.6875 8.11563 12.6875 7.875V1.75C12.6875 1.50937 12.4906 1.3125 12.25 1.3125H6.125C5.88438 1.3125 5.6875 1.50937 5.6875 1.75V7.875C5.6875 8.11563 5.88438 8.3125 6.125 8.3125ZM4.375 7.875V1.75C4.375 0.784766 5.15977 0 6.125 0H12.25C13.2152 0 14 0.784766 14 1.75V7.875C14 8.84023 13.2152 9.625 12.25 9.625H6.125C5.15977 9.625 4.375 8.84023 4.375 7.875Z"
                                fill="currentColor"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3385_1612">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>{" "}
                        </span>
                        <span>
                          <svg
                            width="12"
                            height="16"
                            viewBox="0 0 12 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1C0.446875 1 0 1.44687 0 2C0 2.55312 0.446875 3 1 3H11C11.5531 3 12 2.55312 12 2C12 1.44687 11.5531 1 11 1H1ZM6.70625 5.29375C6.31563 4.90312 5.68125 4.90312 5.29063 5.29375L1.29062 9.29375C0.9 9.68437 0.9 10.3187 1.29062 10.7094C1.68125 11.1 2.31562 11.1 2.70625 10.7094L5 8.41562V14C5 14.5531 5.44688 15 6 15C6.55312 15 7 14.5531 7 14V8.41562L9.29375 10.7094C9.68437 11.1 10.3188 11.1 10.7094 10.7094C11.1 10.3187 11.1 9.68437 10.7094 9.29375L6.70937 5.29375H6.70625Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <span>
                          <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_3385_1616)">
                              <path
                                d="M1 11C1 11.5531 1.44687 12 2 12C2.55312 12 3 11.5531 3 11L3 1C3 0.446875 2.55312 0 2 0C1.44687 0 1 0.446875 1 1L1 11ZM5.29375 5.29375C4.90312 5.68437 4.90312 6.31875 5.29375 6.70937L9.29375 10.7094C9.68437 11.1 10.3187 11.1 10.7094 10.7094C11.1 10.3188 11.1 9.68438 10.7094 9.29375L8.41562 7L14 7C14.5531 7 15 6.55312 15 6C15 5.44688 14.5531 5 14 5L8.41562 5L10.7094 2.70625C11.1 2.31563 11.1 1.68125 10.7094 1.29062C10.3187 0.9 9.68437 0.9 9.29375 1.29062L5.29375 5.29063V5.29375Z"
                                fill="currentColor"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3385_1616">
                                <rect
                                  width="12"
                                  height="16"
                                  fill="white"
                                  transform="matrix(0 -1 1 0 0 12)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>{" "}
          </div>
          {(selectedFolder?.type === "text" ||
            selectedFolder?.type === "textField" ||
            selectedFolder?.type === "button" ||
            selectedFolder?.type === "bottomSheets" ||
            selectedFolder?.type === "dropdown" ||
            selectedFolder?.type === "datepicker") && (
              <SpecifieComponent
                typeIcon="IconButton"
                buttonType="buttonContent"
                OpacityType="opacityButton"
                dateType="datepicker"
                buttonShapeType="button"
                radioType="radiobutton"
                buttonRowType="buttonRow"
                condition="true"
                selectedFolder={selectedFolder}
                folderStructure={folderStructure}
                handleStyleUpdate={handleStyleUpdate}
                handleWidthValueChange={handleWidthValueChange}
                handleBorderColorSelect={handleBorderColorSelect}
                handleBorderRadiusChange={handleBorderRadiusChange}
                bottomSnap={bottomSnap}
                setBottomSnap={setBottomSnap}
                middleSnap={middleSnap}
                setMiddleSnap={setMiddleSnap}
                topSnap={topSnap}
                setTopSnap={setTopSnap}
                handleSnapChange={handleSnapChange}
                handleColorSelect={handleColorSelect}
                handleApplyButtonClick={handleApplyButtonClick}
                handleTextfontColorChange={handleTextfontColorChange}
              />
            )}
          {(selectedFolder?.type === "view" ||
            selectedFolder?.type === "text" ||
            selectedFolder?.type === "textField") && (
              <div className="style-bar layout-bar">
                <Accordion flush defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Layout</Accordion.Header>
                    <Accordion.Body>
                      {selectedFolder?.type === "view" && (
                        <div className="main-layout">
                          <p className="ltr-space">FLEX ITEMS</p>
                          <div className="top-sec">
                            <div className="left-sec">
                              <span
                                className="dir"
                                style={{
                                  background:
                                    selectedFolder?.direction && "#0AABE6",
                                }}
                              >
                                Direction
                              </span>

                              <div className="img-main ">
                                <Tooltip
                                  title="Column"
                                  placement="top"
                                  className="tooltip_top"
                                >
                                  <button
                                    className="img-main"
                                    onClick={() => handleColumnChange("column")}
                                    style={{
                                      color: "white",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span
                                      className={
                                        activeAlignment === "column"
                                          ? "active"
                                          : "no-active"
                                      }
                                      style={{
                                        background:
                                          selectedFolder?.direction ===
                                          "column" && "#0AABE6",
                                        borderRadius: "6px 0 0 6px",
                                      }}
                                    >
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M10 11.166L8.06 12.9711C7.77875 13.2325 7.3975 13.3793 7 13.3793C6.6025 13.3793 6.22125 13.2325 5.94 12.9711L4 11.166H10Z"
                                          fill="white"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M13 5.58272C13.2652 5.58272 13.5196 5.68075 13.7071 5.85524C13.8946 6.02973 14 6.2664 14 6.51317V9.30453C14 9.5513 13.8946 9.78796 13.7071 9.96245C13.5196 10.1369 13.2652 10.235 13 10.235H1C0.734784 10.235 0.48043 10.1369 0.292893 9.96245C0.105357 9.78796 0 9.5513 0 9.30453V6.51317C0 6.2664 0.105357 6.02973 0.292893 5.85524C0.48043 5.68075 0.734784 5.58272 1 5.58272H13ZM13 0C13.2652 0 13.5196 0.0980295 13.7071 0.272523C13.8946 0.447017 14 0.683681 14 0.930453V3.72181C14 3.96858 13.8946 4.20525 13.7071 4.37974C13.5196 4.55423 13.2652 4.65226 13 4.65226H1C0.734784 4.65226 0.48043 4.55423 0.292893 4.37974C0.105357 4.20525 0 3.96858 0 3.72181V0.930453C0 0.683681 0.105357 0.447017 0.292893 0.272523C0.48043 0.0980295 0.734784 0 1 0H13Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </Tooltip>

                                <Tooltip title="Row" placement="top">
                                  <span
                                    onClick={() => handleColumnChange("row")}
                                    className={
                                      activeAlignment === "row"
                                        ? "active"
                                        : "no-active"
                                    }
                                    style={{
                                      background:
                                        selectedFolder?.direction === "row" &&
                                        "#0AABE6",
                                      borderRradius: "0 3px 3px 0",
                                    }}
                                  >
                                    <svg
                                      width="15"
                                      height="14"
                                      viewBox="0 0 15 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12 4L13.94 5.94C14.2209 6.22125 14.3787 6.6025 14.3787 7C14.3787 7.3975 14.2209 7.77875 13.94 8.06L12 10V4Z"
                                        fill="white"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M6 1C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0H10C10.2652 0 10.5196 0.105357 10.7071 0.292893C10.8946 0.48043 11 0.734784 11 1V13C11 13.2652 10.8946 13.5196 10.7071 13.7071C10.5196 13.8946 10.2652 14 10 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V1ZM0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4C4.26522 0 4.51957 0.105357 4.70711 0.292893C4.89464 0.48043 5 0.734784 5 1V13C5 13.2652 4.89464 13.5196 4.70711 13.7071C4.51957 13.8946 4.26522 14 4 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V1Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                            <div className="right-sec">
                              <div>
                                <ReverseSwitch
                                  handleColumnChange={handleColumnChange}
                                  checked={checked}
                                  setChecked={setChecked}
                                />
                              </div>
                              <span
                                style={{ color: "#1a237e", fontSize: "12px" }}
                              >
                                Reverse
                              </span>
                            </div>
                          </div>
                          <div className="align-sec">
                            <div className="lt-sec">
                              <p style={{ textTransform: "capitalize" }}>Align</p>
                            </div>
                            <div className="rt-sec">
                              <Tooltip title="Flexstart" placement="top">
                                <span
                                  onClick={() => {
                                    handleAlignChange("flex-start");
                                  }}
                                  className={
                                    activeAlignment === "flex-start"
                                      ? "active"
                                      : "no-active"
                                  }
                                >
                                  <AlignTop selectedFolder={selectedFolder} />
                                </span>
                              </Tooltip>
                              <Tooltip title="Center" placement="top">
                                <span
                                  onClick={() => handleAlignChange("center")}
                                  className={
                                    activeAlignment === "center"
                                      ? "active"
                                      : "no-active"
                                  }
                                >
                                  <AlignCenter selectedFolder={selectedFolder} />
                                </span>
                              </Tooltip>
                              <Tooltip title="Flexend" placement="top">
                                <span
                                  onClick={() => handleAlignChange("flex-end")}
                                  className={
                                    activeAlignment === "flex-end"
                                      ? "active"
                                      : "no-active"
                                  }
                                >
                                  <AlignLeft selectedFolder={selectedFolder} />
                                </span>
                              </Tooltip>
                              <Tooltip title="Stretch" placement="top">
                                <span
                                  onClick={() => handleAlignChange("stretch")}
                                  className={
                                    activeAlignment === "stretch"
                                      ? "active"
                                      : "no-active"
                                  }
                                >
                                  <AlignTopSecond
                                    selectedFolder={selectedFolder}
                                  />
                                </span>
                              </Tooltip>
                              <Tooltip title="Baseline" placement="top">
                                <span
                                  onClick={() => handleAlignChange("baseline")}
                                  className={
                                    activeAlignment === "baseline"
                                      ? "active"
                                      : "spans"
                                  }
                                >
                                  <AlignCenterThird
                                    selectedFolder={selectedFolder}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          </div>

                          { }
                        </div>
                      )}


                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
          {selectedFolder?.type === "view" ||
            selectedFolder?.type === "button" ||
            selectedFolder?.type === "flashlist" ? (
            <div className="style-bar background-bar">
              <Accordion flush defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Background{" "}
                    <ToolTipBox
                      type="Background"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </Accordion.Header>

                  <Accordion.Body>
                    <div className="main-background">
                      <p
                      // style={{
                      //   background: selectedFolder?.background && "#0AABE6",
                      // }}
                      >
                        Color
                      </p>
                      <div className="flex-side ">
                        <div
                          className="color-picker"
                          onClick={toggleColorPicker}
                          style={{
                            width: "28px",
                            height: "28px",
                            background: selectedColor
                              ? selectedColor
                              : selectedFolder?.background,
                            cursor: "pointer",
                            borderRadius: "3px",
                            borderColor: "#1A237E",
                          }}
                        ></div>
                        {showColorPicker && (
                          <div ref={colorPickerRef}>
                            <ChromePicker
                              color={selectedColor}
                              onChange={handleColorChange}
                              disableAlpha
                            />
                          </div>
                        )}
                      </div>

                      <div className="slt-1">
                        <div className="slt-left">
                          <Select
                            placeholder="Select Color..."
                            options={colorOptions}
                            onChange={handleColorSelect}
                            styles={customStyles}
                          />
                        </div>
                        <button
                          className="apply-btn"
                          onClick={() => handleApplyButtonClick(selectedColor)}
                          disabled={!selectedColor}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : null}
          {selectedFolder?.type === "text" ||
            selectedFolder?.type === "button" ||
            selectedFolder?.type === "datepicker" ||
            selectedFolder?.type === "dropdown" ||
            selectedFolder?.type === "textField" ? (
            <div className="style-bar typpography-bar">
              <Accordion flush defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p>Typography</p>
                    <ToolTipBox
                      type="Typography"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="main-sec">
                      <p className="ltr-space">STYLE</p>
                      <div className="main-flex">
                        <div className="lt-slt">
                          <button
                            style={{
                              background:
                                selectedFolder?.fontSize > 0 && "#0AABE6",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "60px",
                              borderRadius: "4px",
                              height: "28px",
                              fontSize: "14px",
                              marginBottom: "0px",
                            }}
                          >
                            Fonts
                          </button>
                          <button
                            style={{
                              background:
                                selectedFolder?.fontSize > 0 && "#0AABE6",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "60px",
                              borderRadius: "4px",
                              height: "28px",
                              fontSize: "14px",
                              marginBottom: "0px",
                            }}
                          >
                            Weight
                          </button>
                        </div>

                        <div className="rt-slt">
                          <select
                            onChange={handleFontFamilyChange}
                            name="fontFamily"
                          >
                            {/* <option>{typography?.fontFamily}</option> */}
                            {getMyFontList &&
                              getMyFontList?.map((data) => (
                                <>
                                  <option
                                    key={data}
                                    value={data}
                                    selected={
                                      data === typography?.fontFamily
                                        ? true
                                        : false
                                    }
                                  >
                                    {data}
                                  </option>
                                </>
                              ))}
                          </select>

                          <select
                            onChange={handleFontWeightChange}
                            name="fontWeight"
                          >
                            {fontWeightValue.map((weight) => (
                              <option
                                key={weight.value}
                                value={weight.value}
                                selected={
                                  weight.value === typography?.fontWeight
                                    ? true
                                    : false
                                }
                              >
                                {weight.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div>
                        <div className="contain-flex">
                          <div className="rt-size"></div>
                          <div className="flex-num-size">
                            <div className="slt-num-size">
                              <input
                                type="number"
                                name="fontSize"
                                placeholder="auto"
                                onChange={handleFontSizeChange}
                                value={typography?.fontSize}
                              />
                              <span className="no_brdr">px</span>
                            </div>

                            <div className="slt-num-size">
                              <input
                                type="number"
                                name="lineHeight"
                                placeholder="auto"
                                onChange={handleLineHeightChange}
                                value={typography?.lineHeight}
                              />

                              <span className="no_brdr" value="px">
                                px
                              </span>
                            </div>
                            <div className="slt-num-size">
                              <input
                                type="number"
                                name="letterSpacing"
                                placeholder="auto"
                                onChange={handleLetterSpacingChange}
                                value={typography?.letterSpacing}
                              />

                              <span className="no_brdr" value="px">
                                px
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="font-btm">
                          <p
                            style={{
                              background:
                                selectedFolder?.fontSize > 0 && "#0AABE6",
                              borderRadius: "6px",

                              padding: "5px",
                              color: selectedFolder?.fontSize > 0 && "#fff",
                            }}
                          >
                            Font Size
                          </p>
                          <p
                            style={{
                              background:
                                selectedFolder?.lineHeight > 0 && "#0AABE6",
                              padding: "5px",
                              borderRadius: "6px",

                              color: selectedFolder?.lineHeight > 0 && "#fff",
                            }}
                          >
                            Line Height
                          </p>
                          <p
                            style={{
                              background:
                                selectedFolder?.letterSpacing > 0 && "#0AABE6",
                              padding: "5px",
                              borderRadius: "6px",

                              color:
                                selectedFolder?.letterSpacing > 0 && "#fff",
                            }}
                          >
                            Letter Spacing
                          </p>
                        </div>
                      </div>
                      <div className="main-background">
                        <button
                          className="color-btn"
                          onClick={handleFontColorChange}
                          disabled={!selectedFontColor}
                          style={{
                            background:
                              selectedFolder?.color === "#00000" && "#0AABE6",
                          }}
                        >
                          Color
                        </button>

                        <div className="flex-side ">
                          <div
                            className="color-picker"
                            onClick={toggleFontColorPicker}
                            style={{
                              width: "28px",
                              height: "28px",
                              backgroundColor: selectedFontColor
                                ? selectedFontColor
                                : selectedFolder?.color,
                              cursor: "pointer",
                              borderRadius: "3px",
                              border: "1px solid #1A237E",
                            }}
                          ></div>

                          {showFontColorPicker && (
                            <div ref={FontcolorPickerRef}>
                              <ChromePicker
                                color={selectedFontColor}
                                onChange={handleFontColor}
                                disableAlpha
                              />
                            </div>
                          )}
                        </div>

                        <div className="slt-1">
                          <div className="slt-left">
                            <Select
                              placeholder="Select Color..."
                              options={colorOptions}
                              onChange={handleFontColorSelect}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="align-secs">
                        <div className="lt-sec">
                          <p>Align</p>
                        </div>
                        <div className="rt-sec">
                          <Tooltip title="auto" placement="top">
                            <span
                              onClick={() => {
                                handleTextAlignChange("auto");
                              }}
                              className={
                                activeAlignment === "auto"
                                  ? "active"
                                  : "no-active"
                              }
                            >
                              <TextCross selectedFolder={selectedFolder} />
                            </span>
                          </Tooltip>
                          <Tooltip title="Left" placement="top">
                            <span
                              onClick={() => handleTextAlignChange("Left")}
                              className={
                                activeAlignment === "Left"
                                  ? "active"
                                  : "no-active"
                              }
                            >
                              <TextAlign selectedFolder={selectedFolder} />
                            </span>
                          </Tooltip>
                          <Tooltip title="Center" placement="top">
                            <span
                              onClick={() => handleTextAlignChange("Center")}
                              className={
                                activeAlignment === "Center"
                                  ? "active"
                                  : "no-active"
                              }
                            >
                              <CenterAlign selectedFolder={selectedFolder} />
                            </span>
                          </Tooltip>
                          <Tooltip title="Right" placement="top">
                            <span
                              onClick={() => handleTextAlignChange("Right")}
                              className={
                                activeAlignment === "Right"
                                  ? "active"
                                  : "no-active"
                              }
                            >
                              <TextAlignRight selectedFolder={selectedFolder} />
                            </span>
                          </Tooltip>
                          <Tooltip title="Justify" placement="top">
                            <span
                              onClick={() => handleTextAlignChange("Justify")}
                              className={
                                activeAlignment === "Justify"
                                  ? "active"
                                  : "no-active"
                              }
                            >
                              <TextAlignLast selectedFolder={selectedFolder} />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="justify-sec">
                        <div className="lt-sec">
                          <p>Transform</p>
                        </div>
                        <div className="rt-sec">
                          <Tooltip title="auto" placement="top">
                            <span
                              onClick={() => handleTextTransformChange("auto")}
                              className={
                                activeAlignment === "auto"
                                  ? "active"
                                  : "no-actives"
                              }
                            >
                              <TextCrossTransform
                                selectedFolder={selectedFolder}
                              />
                            </span>
                          </Tooltip>

                          <Tooltip title="capitalize" placement="top">
                            <span
                              onClick={() =>
                                handleTextTransformChange("capitalize")
                              }
                              className={
                                activeAlignment === "capitalize"
                                  ? "active"
                                  : "no-active"
                              }
                            >
                              <p
                                className="brd1"
                              // style={{
                              //   background:
                              //     selectedFolder?.textTransform ===
                              //       "capitalize" && "#0AABE6",
                              //   cursor: "pointer",
                              // }}
                              >
                                Aa
                              </p>
                            </span>
                          </Tooltip>

                          <Tooltip title="uppercase" placement="top">
                            <span
                              onClick={() =>
                                handleTextTransformChange("uppercase")
                              }
                              className={
                                activeAlignment === "uppercase"
                                  ? "active"
                                  : "no-active"
                              }
                            >
                              <p
                                className="brd2"
                              // style={{
                              //   background:
                              //     selectedFolder?.textTransform ===
                              //       "uppercase" && "#0AABE6",
                              //   cursor: "pointer",
                              // }}
                              >
                                AA
                              </p>
                            </span>
                          </Tooltip>

                          <Tooltip title="lowercase" placement="top">
                            <span
                              onClick={() =>
                                handleTextTransformChange("lowercase")
                              }
                              className={
                                activeAlignment === "lowercase"
                                  ? "active"
                                  : "no-active"
                              }
                            >
                              <p
                                className="brd3"
                              // style={{
                              //   background:
                              //     selectedFolder?.textTransform ===
                              //       "lowercase" && "#0AABE6",
                              //   cursor: "pointer",
                              // }}
                              >
                                aa
                              </p>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : null}
          <div className="style-bar size-bar">
            {selectedFolder?.type !== "text" &&
              selectedFolder?.type !== "flashlist" ? (
              <Accordion flush defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Size{" "}
                    <ToolTipBox
                      type="Size"
                      showTooltipData={showTooltipData}
                      handleIconClick={handleIconClick}
                      tooltipContent={tooltipContent}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="init_size">
                      <div className="main-size">
                        <div className="size-input-1">
                          <p
                            className="size_title"
                            style={{
                              background:
                                selectedFolder?.size?.width > 0 &&
                                "#1A237E",
                              color:
                                selectedFolder?.size?.width > 0 && "#fff",
                            }}
                          >
                            Width
                          </p>
                          <div className="flex-num-pt-1">
                            <div className="slt-num-1">
                              <input
                                type="text"
                                placeholder="auto"
                                value={widthInput}
                                onChange={handleWidthChange}
                              />
                              <select
                                value={widthUnit}
                                onChange={handleWidthUnitChange}
                                className="sizeSelectBox"
                              >
                                <option className="no_brdrs" value="px">
                                  px
                                </option>
                                <option className="no_brdrs" value="%">
                                  %
                                </option>
                              </select>
                              {/* <span>px</span> */}
                            </div>
                          </div>
                        </div>
                        <div className="size-input-1">
                          <p
                            className="size_title"
                            style={{
                              background:
                                selectedFolder?.size?.height > 0 &&
                                "#1A237E",
                              color:
                                selectedFolder?.size?.height > 0 && "#fff",
                            }}
                          >
                            Height
                          </p>
                          <div className="flex-num-pt-1">
                            <div className="slt-num-1">
                              <input
                                type="text"
                                placeholder="auto"
                                value={heightInput}
                                onChange={handleHeightChange}
                              />
                              <select
                                value={heightUnit}
                                onChange={handleHeightUnitChange}
                              >
                                <option className="no_brdrs" value="px">
                                  px
                                </option>
                                <option className="no_brdrs" value="%">
                                  %
                                </option>
                              </select>
                              {/* <span>px</span> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex-num-pt-1 mg_tb">
                        <div className="inner_num">
                          <p
                            className="size_title"
                            style={{
                              background:
                                selectedFolder?.size?.min_width > 0 &&
                                "#1A237E",
                              color:
                                selectedFolder?.size?.min_width > 0 &&
                                "#fff",
                            }}
                          >
                            Min W
                          </p>
                          <div className="slt-num-1">
                            <div
                              className="auto-slt-1"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                type="text"
                                placeholder="auto "
                                value={minMaxvalues?.min_width}
                                onChange={minMaxvaluechangeHandler}
                                name="min_width"
                              />
                              <select
                                onChange={minMaxchangeHandler}
                                name="min_width"
                              >
                                <option className="no_brdr" value="px">
                                  px
                                </option>
                                <option className="no_brdr" value="%">
                                  %
                                </option>
                              </select>

                              {/* <span>px</span> */}
                            </div>
                          </div>
                        </div>
                        <div className="inner_num">
                          <p
                            className="size_title"
                            style={{
                              background:
                                selectedFolder?.size?.min_height > 0 &&
                                "#1A237E",
                              color:
                                selectedFolder?.size?.min_height > 0 &&
                                "#fff",
                            }}
                          >
                            Min H
                          </p>
                          <div className="slt-num-1">
                            <input
                              type="text"
                              placeholder="auto "
                              value={minMaxvalues?.min_height}
                              onChange={minMaxvaluechangeHandler}
                              name="min_height"
                            />
                            <select
                              onChange={minMaxchangeHandler}
                              name="min_height"
                            >
                              <option className="no_brdr" value="px">
                                px
                              </option>
                              <option className="no_brdr" value="%">
                                %
                              </option>
                            </select>

                            {/* <span>px</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="flex-num-pt-1">
                        <div className="inner_num">
                          <p
                            className="size_title"
                            style={{
                              background:
                                selectedFolder?.size?.max_width > 0 &&
                                "#1A237E",
                              color:
                                selectedFolder?.size?.max_width > 0 &&
                                "#fff",
                            }}
                          >
                            Max W
                          </p>
                          <div className="slt-num-1">
                            <input
                              type="text"
                              placeholder="auto "
                              value={minMaxvalues?.max_width}
                              onChange={minMaxvaluechangeHandler}
                              name="max_width"
                            />
                            <select
                              onChange={minMaxchangeHandler}
                              name="max_width"
                            >
                              <option className="no_brdr" value="px">
                                px
                              </option>
                              <option className="no_brdr" value="%">
                                %
                              </option>
                            </select>
                            {/* <span>px</span> */}
                          </div>
                        </div>
                        <div className="inner_num">
                          <p
                            className="size_title"
                            style={{
                              background:
                                selectedFolder?.size?.max_height > 0 &&
                                "#1A237E",
                              color:
                                selectedFolder?.size?.max_height > 0 &&
                                "#fff",
                            }}
                          >
                            Max H
                          </p>
                          <div className="slt-num-1">
                            <input
                              type="text"
                              value={minMaxvalues?.max_height}
                              onChange={minMaxvaluechangeHandler}
                              name="max_height"
                              placeholder="auto"
                            />
                            <select
                              onChange={minMaxchangeHandler}
                              name="max_height"
                            >
                              <option className="no_brdr" value="px">
                                px
                              </option>
                              <option className="no_brdr" value="%">
                                %
                              </option>
                            </select>
                            {/* <span>px</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : null}
          </div>
          <div className="style-bar margin-bar">
            <Accordion flush defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {selectedFolder?.type !== "image" ? (
                    <p>Margins & Padding</p>
                  ) : (
                    <p>Margins</p>
                  )}{" "}
                  <ToolTipBox
                    type="Margins & Padding"
                    showTooltipData={showTooltipData}
                    handleIconClick={handleIconClick}
                    tooltipContent={tooltipContent}
                  />
                </Accordion.Header>
                <Accordion.Body>
                  <div className="main-overall">
                    {selectedFolder?.type !== "image" ? (
                      <div className="auto-margin">
                        <p className="ltr-space">OVERALL</p>
                        <div className="inner_margin">
                          <div className="mr-1">
                            <p
                              className="width_mrg"
                              style={{
                                background:
                                  selectedFolder?.margin?.all > 0 &&
                                  "#0AABE6",
                              }}
                            >
                              Margin
                            </p>

                            <div className="mr_sec">
                              <input
                                type="number"
                                placeholder="auto"
                                value={selectedFolder?.margin?.all}
                                name="all"
                                onChange={handleMarginChange}
                              />

                              <span>px</span>
                            </div>
                          </div>
                          <div className="mr-1">
                            <p
                              className="width_mrg"
                              style={{
                                background:
                                  selectedFolder?.padding?.all > 0 &&
                                  "#0AABE6",
                              }}
                            >
                              Padding
                            </p>
                            <div className="mr_sec">
                              <input
                                type="number"
                                placeholder="auto"
                                value={selectedFolder?.padding?.all}
                                onChange={handlePaddingChange}
                                name="all"
                              />
                              {/* <select>
                              <option className="no_brdr" value="px">
                                px
                              </option>
                              <option className="no_brdr" value="%">
                                %
                              </option>
                            </select> */}
                              <span>px</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="main-margin selected-item-sec">
                      <p className="ltr-space">INDIVIDUAL</p>
                      <p className="mg_para">Margin</p>
                      <div className="margin-flx">
                        <button
                          onClick={() => openMainPopup("margin_top")}
                          className="auto_btn top_margin"
                          style={{
                            backgroundColor:
                              selectedFolder?.margin?.top > 0 && "#0AABE6",
                          }}
                        >
                          {selectedFolder?.margin?.top > 0
                            ? `${selectedFolder?.margin?.top} px`
                            : "auto"}
                        </button>
                        {mainPopup === "margin_top" ? (
                          <MainMarginPopup
                            closeMainPopup={closeMainPopup}
                            setMainPopup={setMainPopup}
                            title="Margin Top"
                            handleTopMarginChange={handleTopMarginChange}
                          />
                        ) : null}
                      </div>
                    </div>

                    <div className="rtangle-box">
                      <div className="lt-btn">
                        {selectedFolder?.type !== "image" ? (
                          <p>Padding</p>
                        ) : null}
                        {selectedFolder?.type !== "image" ? (
                          <button
                            onClick={() => openMainPopup("padding_top")}
                            className="auto_btn top_padding"
                            style={{
                              backgroundColor:
                                selectedFolder?.padding?.top > 0 &&
                                "#0AABE6",
                            }}
                          >
                            {selectedFolder?.padding?.top > 0
                              ? `${selectedFolder?.padding?.top} px`
                              : "auto"}
                          </button>
                        ) : null}
                        {mainPopup === "padding_top" ? (
                          <MainMarginPopup
                            setMainPopup={setMainPopup}
                            closeMainPopup={closeMainPopup}
                            title="Padding Top"
                            handleTopPaddingChange={handleTopPaddingChange}
                          />
                        ) : null}
                      </div>
                      <div className="space-btn">
                        <button
                          onClick={() => openMainPopup("Margin left")}
                          className="auto_btn left_margin"
                          style={{
                            backgroundColor:
                              selectedFolder?.margin?.left > 0 &&
                              "#0AABE6",
                          }}
                        >
                          {selectedFolder?.margin?.left > 0
                            ? `${selectedFolder?.margin?.left} px`
                            : "auto"}
                        </button>
                        {mainPopup === "Margin left" ? (
                          <MainMarginPopup
                            setMainPopup={setMainPopup}
                            closeMainPopup={closeMainPopup}
                            title="Margin Left"
                            handleLeftMarginChange={handleLeftMarginChange}
                          />
                        ) : null}
                        {selectedFolder?.type !== "image" ? (
                          <button
                            onClick={() => openMainPopup("Padding left")}
                            className="auto_btn left_padding"
                            style={{
                              backgroundColor:
                                selectedFolder?.padding?.left > 0 &&
                                "#0AABE6",
                            }}
                          >
                            {selectedFolder?.padding?.left > 0
                              ? `${selectedFolder?.padding?.left} px`
                              : "auto"}
                          </button>
                        ) : null}
                        {mainPopup === "Padding left" ? (
                          <MainMarginPopup
                            setMainPopup={setMainPopup}
                            closeMainPopup={closeMainPopup}
                            title="Padding Left"
                            handleLeftPaddingChange={handleLeftPaddingChange}
                          />
                        ) : null}

                        {selectedFolder?.type !== "image" ? (
                          <button
                            onClick={() => openMainPopup("Padding Right")}
                            className="auto_btn right_padding"
                            style={{
                              backgroundColor:
                                selectedFolder?.padding?.right > 0 &&
                                "#0AABE6",
                            }}
                          >
                            {selectedFolder?.padding?.right > 0
                              ? `${selectedFolder?.padding?.right} px`
                              : "auto"}
                          </button>
                        ) : null}
                        {mainPopup === "Padding Right" ? (
                          <MainMarginPopup
                            setMainPopup={setMainPopup}
                            closeMainPopup={closeMainPopup}
                            title="Padding Right"
                            handleRightPaddingChange={handleRightPaddingChange}
                          />
                        ) : null}

                        <button
                          onClick={() => openMainPopup("Margin right")}
                          className="auto_btn right_margin"
                          style={{
                            backgroundColor:
                              selectedFolder?.margin?.right > 0 &&
                              "#0AABE6",
                          }}
                        >
                          {selectedFolder?.margin?.right > 0
                            ? `${selectedFolder?.margin?.right} px`
                            : "auto"}
                        </button>
                        {mainPopup === "Margin right" ? (
                          <MainMarginPopup
                            setMainPopup={setMainPopup}
                            closeMainPopup={closeMainPopup}
                            title="Margin Right"
                            handleRightMarginChange={handleRightMarginChange}
                          />
                        ) : null}
                      </div>

                      <div className="center-btn">
                        {selectedFolder?.type !== "image" ? (
                          <button
                            onClick={() => openMainPopup("Padding bottom")}
                            className="auto_btn bottom_padding"
                            style={{
                              backgroundColor:
                                selectedFolder?.padding?.bottom > 0 &&
                                "#0AABE6",
                            }}
                          >
                            {selectedFolder?.padding?.bottom > 0
                              ? `${selectedFolder?.padding?.bottom} px`
                              : "auto"}
                          </button>
                        ) : null}

                        {mainPopup === "Padding bottom" ? (
                          <MainMarginPopup
                            setMainPopup={setMainPopup}
                            closeMainPopup={closeMainPopup}
                            title="Padding Bottom"
                            handleBottomPaddingChange={
                              handleBottomPaddingChange
                            }
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="margin-flx">
                      <button
                        onClick={() => openMainPopup("Margin bottom")}
                        className="auto_btn btm_btn bottom_margin"
                        style={{
                          backgroundColor:
                            selectedFolder?.margin?.bottom > 0 &&
                            "#0AABE6",
                        }}
                      >
                        {selectedFolder?.margin?.bottom > 0
                          ? `${selectedFolder?.margin?.bottom} px`
                          : "auto"}
                      </button>
                      {mainPopup === "Margin bottom" ? (
                        <MainMarginPopup
                          setMainPopup={setMainPopup}
                          closeMainPopup={closeMainPopup}
                          title="Margin Bottom"
                          handleBottomMarginChange={handleBottomMarginChange}
                        />
                      ) : null}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          { }
          <div className="style-bar Decoration-bar">
            {selectedFolder?.type === "flashlist" ? null : (
              <Accordion flush defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Border{" "}
                    <ToolTipBox
                      type="Border"
                      handleIconClick={handleIconClick}
                      showTooltipData={showTooltipData}
                      tooltipContent={tooltipContent}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="over-first-sec">
                      <p className="ltr-space">overall</p>
                      <div className="two-flexItem">
                        <div className="one-width">
                          <h5
                            style={{
                              background:
                                selectedFolder?.borderWidth?.all > 0 &&
                                "#0AABE6",
                              color:
                                selectedFolder?.borderWidth?.all > 0 &&
                                "#fff",
                            }}
                          >
                            width
                          </h5>
                          <div className="border_style">
                            <input
                              type="number"
                              placeholder="auto"
                              value={selectedFolder?.borderWidth?.all}
                              name="all"
                              onChange={handleWidthValueChange}
                            />
                            <span className="unit">px</span>
                          </div>
                        </div>
                        <div className="one-radius">
                          <h5
                            style={{
                              background:
                                selectedFolder?.borderRadius?.all > 0 &&
                                "#0AABE6",
                              color:
                                selectedFolder?.borderRadius?.all > 0 &&
                                "#fff",
                            }}
                          >
                            radius
                          </h5>
                          <div className="border_style">
                            <input
                              name="all"
                              type="number"
                              placeholder="auto"
                              value={selectedFolder?.borderRadius?.all}
                              onChange={handleBorderRadiusChange}
                            />
                            <span className="unit">px</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="middle-contain">
                      <p className="ltr-space">individual edges</p>
                      <p className="para-wth">Width</p>
                      <div className="auto-flx">
                        <button
                          className="auto_btn btm_btn top_width"
                          onClick={() => openMainPopup("Width_Top")}
                          style={{
                            backgroundColor:
                              selectedFolder?.borderWidth?.top > 0 &&
                              "#0AABE6",
                          }}
                        >
                          {selectedFolder?.borderWidth?.top > 0
                            ? `${selectedFolder?.borderWidth?.top} px`
                            : "auto"}
                        </button>

                        {mainPopup === "Width_Top" ? (
                          <MainMarginPopup
                            setMainPopup={setMainPopup}
                            closeMainPopup={closeMainPopup}
                            title="Width top"
                            handleTopWidthValueChange={
                              handleTopWidthValueChange
                            }
                          />
                        ) : null}
                      </div>
                      <div className="rectangle-box">
                        <div className="top-left-btn">
                          <button
                            className="auto_btn top_auto"
                            onClick={() => openMainPopup("Radius_Top")}
                            style={{
                              backgroundColor:
                                selectedFolder?.borderRadius?.topLeft >
                                0 && "#0AABE6",
                            }}
                          >
                            {" "}
                            {selectedFolder?.borderRadius?.topLeft > 0
                              ? `${selectedFolder?.borderRadius?.topLeft} px`
                              : "0"}
                          </button>
                          {mainPopup === "Radius_Top" ? (
                            <MainMarginPopup
                              setMainPopup={setMainPopup}
                              closeMainPopup={closeMainPopup}
                              title="Radius left"
                              handleBorderleftRadiusChange={
                                handleBorderleftRadiusChange
                              }
                            />
                          ) : null}
                        </div>
                        <div className="space-auto-btn">
                          <button
                            className="auto_btn left_auto"
                            onClick={() => openMainPopup("Width_left")}
                            style={{
                              backgroundColor:
                                selectedFolder?.borderWidth?.left > 0 &&
                                "#0AABE6",
                            }}
                          >
                            {" "}
                            {selectedFolder?.borderWidth?.left > 0
                              ? `${selectedFolder?.borderWidth?.left} px`
                              : "auto"}
                          </button>
                          {mainPopup === "Width_left" ? (
                            <MainMarginPopup
                              setMainPopup={setMainPopup}
                              closeMainPopup={closeMainPopup}
                              title="Width left"
                              handleLeftWidthValueChange={
                                handleLeftWidthValueChange
                              }
                            />
                          ) : null}

                          <button
                            onClick={() => openMainPopup("Width_right")}
                            className="auto_btn right_auto"
                            style={{
                              backgroundColor:
                                selectedFolder?.borderWidth?.right > 0 &&
                                "#0AABE6",
                            }}
                          >
                            {" "}
                            {selectedFolder?.borderWidth?.right > 0
                              ? `${selectedFolder?.borderWidth?.right} px`
                              : "auto"}
                          </button>
                          {mainPopup === "Width_right" ? (
                            <MainMarginPopup
                              setMainPopup={setMainPopup}
                              closeMainPopup={closeMainPopup}
                              handleRightWidthValueChange={
                                handleRightWidthValueChange
                              }
                              title="Width right"
                            />
                          ) : null}
                        </div>
                        <div className="below_auto-btn">
                          <button
                            onClick={() => openMainPopup("Radius_left")}
                            className="left_auto  bottom_left_auto"
                            style={{
                              backgroundColor:
                                selectedFolder?.borderRadius?.bottomLeft >
                                0 && "#0AABE6",
                            }}
                          >
                            {selectedFolder?.borderRadius?.bottomLeft > 0
                              ? `${selectedFolder?.borderRadius?.bottomLeft} px`
                              : "0"}
                          </button>
                          {mainPopup === "Radius_left" ? (
                            <MainMarginPopup
                              setMainPopup={setMainPopup}
                              closeMainPopup={closeMainPopup}
                              title="Radius Bottom Left"
                              handleBorderBottomleftRadius={
                                handleBorderBottomLeftRadiusChange
                              }
                            />
                          ) : null}
                          <button
                            onClick={() => openMainPopup("Radius_Bottom")}
                            className="auto_btn bottom_auto"
                            style={{
                              backgroundColor:
                                selectedFolder?.borderRadius?.bottomRight >
                                0 && "#0AABE6",
                            }}
                          >
                            {" "}
                            {selectedFolder?.borderRadius?.bottomRight > 0
                              ? `${selectedFolder?.borderRadius?.bottomRight} px`
                              : "0"}
                          </button>
                          {mainPopup === "Radius_Bottom" ? (
                            <MainMarginPopup
                              setMainPopup={setMainPopup}
                              closeMainPopup={closeMainPopup}
                              title="Radius Bottom Right"
                              handleBorderBottomRightRadiusChange={
                                handleBorderBottomRightRadiusChange
                              }
                            />
                          ) : null}
                        </div>

                        <div className="center-auto-btn">
                          <button
                            onClick={() => openMainPopup("Radius_right")}
                            className=" right_auto bottom_right_auto"
                            style={{
                              backgroundColor:
                                selectedFolder?.borderRadius?.topRight >
                                0 && "#0AABE6",
                            }}
                          >
                            {selectedFolder?.borderRadius?.topRight > 0
                              ? `${selectedFolder?.borderRadius?.topRight} px`
                              : "0"}
                          </button>
                          {mainPopup === "Radius_right" ? (
                            <MainMarginPopup
                              setMainPopup={setMainPopup}
                              closeMainPopup={closeMainPopup}
                              title="Radius Right"
                              handleBorderRightRadiusChange={
                                handleBorderRightRadiusChange
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="auto-flx">
                        <button
                          onClick={() => openMainPopup("Width_bottom")}
                          className="auto_btn btm_btn bottom_width"
                          style={{
                            backgroundColor:
                              selectedFolder?.borderWidth?.bottom > 0 &&
                              "#0AABE6",
                          }}
                        >
                          {selectedFolder?.borderWidth?.bottom > 0
                            ? `${selectedFolder?.borderWidth?.bottom} px`
                            : "auto"}
                        </button>
                        {mainPopup === "Width_bottom" ? (
                          <MainMarginPopup
                            setMainPopup={setMainPopup}
                            closeMainPopup={closeMainPopup}
                            handleBottomWidthValueChange={
                              handleBottomWidthValueChange
                            }
                            title="Width bottom"
                          />
                        ) : null}
                      </div>
                    </div>
                    {(selectedFolder?.type === "image" ||
                      selectedFolder?.type === "text" ||
                      selectedFolder?.type === "button" ||
                      selectedFolder?.type === "view" ||
                      selectedFolder?.type === "bottomSheets" ||
                      selectedFolder?.type === "datepicker") && (
                        <>
                          <div className="top-para">
                            <p className="ltr-space">DECORATION</p>
                          </div>
                          <div className="main-dec">
                            <button
                              onClick={() =>
                                handleBorderColorChange(selectedBorderColor)
                              }
                              style={{
                                backgroundColor:
                                  selectedFolder?.borderColor && "#0AABE6",
                              }}
                            >
                              Color
                            </button>
                            <div className="flex-side ">
                              <div
                                className="color-picker"
                                ref={BorderColorPickerRef}
                                onClick={toggleBorderColorPicker}
                                style={{
                                  width: "30px",
                                  height: "30px",

                                  background: selectedBorderColor
                                    ? selectedBorderColor
                                    : selectedBorderColor?.background,
                                  cursor: "pointer",
                                  borderRadius: "3px",
                                }}
                              ></div>
                              {showBorderColorPicker && (
                                <div>
                                  <ChromePicker
                                    color={selectedBorderColor}
                                    onChange={handleBorderChange}
                                    disableAlpha
                                  />
                                </div>
                              )}
                            </div>

                            <div className="slt-1">
                              <div className="slt-left">
                                <Select
                                  placeholder="Select Color..."
                                  options={colorOptions}
                                  styles={customStyles}
                                  onChange={handleBorderColorSelect}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                    {(selectedFolder?.type === "image" ||
                      selectedFolder?.type === "text" ||
                      selectedFolder?.type === "view" ||
                      selectedFolder?.type === "datepicker") && (
                        <>
                          <div className="middle-part">
                            <div className="left_sec">
                              <button
                                style={{
                                  backgroundColor:
                                    selectedFolder?.borderStyle && "#0AABE6",
                                }}
                              >
                                Style
                              </button>
                            </div>
                            <div className="right_sec">
                              <Tooltip title="none" placement="top">
                                <div
                                  onClick={() => HandleBorderImageChange("none")}
                                  className={
                                    activeAlignment === "none"
                                      ? "active"
                                      : "no-active"
                                  }
                                >
                                  <CloseIcon
                                    className="brd"
                                    style={{
                                      background:
                                        selectedFolder?.borderStyle === "none" &&
                                        "#0AABE6",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip title="solid" placement="top">
                                <div
                                  onClick={() => HandleBorderImageChange("solid")}
                                  className={
                                    activeAlignment === "solid"
                                      ? "active"
                                      : "no-active"
                                  }
                                >
                                  <RemoveSharpIcon
                                    className="btmIcon"
                                    style={{
                                      background:
                                        selectedFolder?.borderStyle === "solid" &&
                                        "#0AABE6",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip title="double" placement="top">
                                <div
                                  onClick={() =>
                                    HandleBorderImageChange("double")
                                  }
                                  className={
                                    activeAlignment === "double"
                                      ? "active"
                                      : "no-active"
                                  }
                                >
                                  <DragHandleSharpIcon
                                    className="btmIcon"
                                    style={{
                                      background:
                                        selectedFolder?.borderStyle ===
                                        "double" && "#0AABE6",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip title="dotted " placement="top">
                                <div
                                  onClick={() =>
                                    HandleBorderImageChange("dotted")
                                  }
                                  className={
                                    activeAlignment === "dotted "
                                      ? "active"
                                      : "no-active"
                                  }
                                >
                                  <LinearScaleSharpIcon
                                    className="btmIcon"
                                    style={{
                                      background:
                                        selectedFolder?.borderStyle ===
                                        "dotted" && "#0AABE6",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip title="dashed " placement="top">
                                <div
                                  onClick={() =>
                                    HandleBorderImageChange("dashed")
                                  }
                                  className={
                                    activeAlignment === "dashed "
                                      ? "active"
                                      : "no-active"
                                  }
                                >
                                  <img
                                    className="rticon"
                                    src={DashIcon}
                                    alt=""
                                    style={{
                                      background:
                                        selectedFolder?.borderStyle ===
                                        "dashed" && "#0AABE6",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </>
                      )}
                    {selectedFolder?.type === "text" && (
                      <>
                        <div className="last-part">
                          <div className="left_line">
                            <button
                              style={{
                                backgroundColor:
                                  selectedFolder?.textdecoration && "#0AABE6",
                              }}
                            >
                              Line
                            </button>
                          </div>
                          <div className="right_line">
                            <Tooltip title=" none" placement="top">
                              <span
                                className={
                                  activeAlignment === "none"
                                    ? "active"
                                    : "no-active"
                                }
                                onClick={() => handleBorderTextchange("none")}
                              >
                                <img
                                  className="brd"
                                  src={crossone}
                                  alt=""
                                  style={{
                                    background:
                                      selectedFolder?.textdecoration ===
                                      "none" && "#0AABE6",
                                  }}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title=" underLine" placement="top">
                              <span
                                className={
                                  activeAlignment === "underLine"
                                    ? "active"
                                    : "no-active"
                                }
                                onClick={() =>
                                  handleBorderTextchange("underLine")
                                }
                              >
                                <img
                                  className="btmIcon"
                                  src={qicon}
                                  alt=""
                                  style={{
                                    background:
                                      selectedFolder?.textdecoration ===
                                      "underLine" && "#0AABE6",
                                  }}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip title="line-Through" placement="top">
                              <span
                                className={
                                  activeAlignment === "line-Through"
                                    ? "active"
                                    : "no-active"
                                }
                                onClick={() =>
                                  handleBorderTextchange("line-Through")
                                }
                              >
                                <img
                                  className="btmIcon"
                                  src={qqicon}
                                  alt=""
                                  style={{
                                    background:
                                      selectedFolder?.textdecoration ===
                                      "line-Through" && "#0AABE6",
                                  }}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip
                              title="underLine Line-Through"
                              placement="top"
                            >
                              <span
                                className={
                                  activeAlignment === "underLine Line-Through"
                                    ? "active"
                                    : "no-active"
                                }
                                onClick={() =>
                                  handleBorderTextchange(
                                    " underLine Line-Through"
                                  )
                                }
                              >
                                <img
                                  className="rticon"
                                  src={qqqqicon}
                                  alt=""
                                  style={{
                                    background:
                                      selectedFolder?.textdecoration ===
                                      "underLine Line-Through" && "#0AABE6",
                                  }}
                                />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

            {/* {tooltipVisible && (
              <div className="tooltip-container" ref={tooltipRef}>
                <ToolTipBox
                  title={tooltipContent.title}
                  content={tooltipContent.content}
                  link={tooltipContent.link}
                />
              </div>
            )} */}
          </div>

          <div className="tlg-btn">
            {/* <Switch checked={checked} onChange={checked} /> */}
            <ReverseSwitch checked={checked} setChecked={setChecked} />
            <p>Log action in this chain</p>
          </div>
        </div>
      </div>

      {/* <StyleSheet
        styleSheetModal={styleSheetModal}
        closeDataModal={closeDataModal}
      /> */}
    </>
  );
};

export default MainScroll;
