import {
  SET_DEFAULT_VALUES,
  SET_MY_ASSETS,
  SET_MY_NEW_ASSETS,
  SET_DELETE_MY_NEW_ASSETS,
  SET_SAVE_BLOCK,
  SET_NEW_SAVE_BLOCK,
  SET_DELETE_NEW_SAVE_BLOCK,
  ADD_NEW_SCREEN,
  GET_MY_SCREEN,
  SET_SELECTED_CANVAS,
  SET_DATA_BLOCK,
  SAVE_CANVAS_DATA,
  GET_MY_SCREEN_DATA,
  DELETE_MY_SCREEN,
  EDIT_MY_SCREEN_DATA,
  SET_MYNEWDATA,
  SET_MYNEWDATA2,
  SET_DUPLICATED_FOLDER,
  SET_DIV_WIDTH_HEIGHT,
  SET_FOLDER_WIDTH_HEIGHT,
  UPDATE_CANVAS_BACKGROUND,
  SET_NEW_STYLE_DATA,
  SET_GET_STYLE_DATA,
  SET_GET_VERSION_LIST,
  SET_ADD_PUBLISH_SCREEN,
  SET_DELETE_VERSION,
  SET_SDK_VERSION_LIST,
  EDIT_MY_SDK_VERSION_DATA,
  SET_MY_SDK_VERSION_DATA,
  SET_SDK_VERSION_RESOURCE_LIST,
  SET_SHARE_PROJECT,
  GET_MY_SCREENMODAL_DATA,
} from "../types";

const initialState = {
  defaultValues: {
    imgValue: [],
    screenList: [],
    textValue: [],
    viewValue: [],
    // name: "aa",
  },
  selectedCanvas: null,
  screens: {},
  selectedScreenId: null,

  folderStructure: [
    {
      name: "",
      isFolder: true,
      children: [],
      type: "view",
      backgroundColor: "ffffff",
      customDataType: false,
    },
  ],
  selectedFolder: {
    name: "",
    isFolder: true,
    children: [],
    type: "view",
    backgroundColor: "ffffff",
    customDataType: false,
  },
};

const defaultReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_VALUES:
      return {
        ...state,
        defaultValues: action.payload,
      };
    case SAVE_CANVAS_DATA:
      return {
        ...state,
        savedCanvasData: {
          ...state.savedCanvasData,
          [action.payload.canvasId]: action.payload.data,
        },
      };
    case SET_MY_ASSETS:
      return {
        ...state,
        myAssetList: action.payload,
      };
    case SET_MY_NEW_ASSETS:
      return {
        ...state,
        newAssetCreated: action.payload,
      };
    case SET_DELETE_MY_NEW_ASSETS:
      return {
        ...state,
        deleteMyAssets: action.payload,
      };
    case SET_SAVE_BLOCK:
      return {
        ...state,
        getMySaveBlock: action.payload,
      };

    case SET_NEW_SAVE_BLOCK:
      return {
        ...state,
        addNewSaveBlock: action.payload,
      };

    case SET_DELETE_NEW_SAVE_BLOCK:
      return {
        ...state,
        deleteMyNewSaveBlock: action.payload,
      };
    case ADD_NEW_SCREEN:
      return {
        ...state,
        addNewScreenList: action.payload,
      };

    case GET_MY_SCREEN:
      return {
        ...state,
        getScreenList: action.payload,
      };
    case SET_SELECTED_CANVAS:
      return {
        ...state,
        selectedCanvas: action.payload,
      };

    case GET_MY_SCREEN_DATA:
      return {
        ...state,
        getMyScreenData: action.payload,
      };
    case DELETE_MY_SCREEN:
      return {
        ...state,
        deleteMyScreen: action.payload,
      };
    case SET_DATA_BLOCK:
      return {
        ...state,
        mapDataBlock: action.payload,
      };
    case EDIT_MY_SCREEN_DATA:
      return {
        ...state,
        editScreenData: action.payload,
      };
    case SET_ADD_PUBLISH_SCREEN:
      return {
        ...state,
        addToPublish: action.payload,
      };
    case SET_DELETE_VERSION:
      return {
        ...state,
        deleteMyVersion: action.payload,
      };

    case SET_MYNEWDATA:
      return {
        ...state,
        folderStructure: action.payload,
      };
    case SET_MYNEWDATA2:
      return {
        ...state,
        selectedFolder: action.payload,
      };
    case SET_DUPLICATED_FOLDER:
      return {
        ...state,
        duplicatedFolder: action.payload,
      };
    case SET_GET_VERSION_LIST:
      return {
        ...state,
        screenVersionList: action.payload,
      };
    case SET_NEW_STYLE_DATA:
      return {
        ...state,
        saveMyStyles: action.payload,
      };
    case SET_GET_STYLE_DATA:
      return {
        ...state,
        getMysavedStyles: action.payload,
      };
    case SET_SDK_VERSION_LIST:
      return {
        ...state,
        getMySdkVersionList: action.payload,
      };
    case SET_MY_SDK_VERSION_DATA:
      return {
        ...state,
        updateSdkVersion: action.payload,
      };
    case SET_SDK_VERSION_RESOURCE_LIST:
      return {
        ...state,
        getSdkResourceList: action.payload,
      };
    case SET_SHARE_PROJECT:
      return {
        ...state,
        shareMyProjectData: action.payload,
      };
    case GET_MY_SCREENMODAL_DATA:
      return {
        ...state,
        getMyScreenModalData: action.payload,
      };
    case SET_FOLDER_WIDTH_HEIGHT:
      const { id, width, height } = action.payload;
      return {
        ...state,
        selectedFolder: {
          ...state.selectedFolder.id,
          width,
          height,
          id,
        },
      };

    case UPDATE_CANVAS_BACKGROUND:
      return {
        ...state,
        selectedBackgroundColor: action.payload,
      };

    default:
      return state;
  }
};

export default defaultReducers;
