import React from "react";

const TextSvg = ({ change }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_40_255)">
          <path
            d="M14 6.125V21.875"
            stroke={change ? "#fff" : "#3c4c6e"}
            strokeWidth="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.25 9.625V6.125H22.75V9.625"
            stroke={change ? "#fff" : "#3c4c6e"}
            strokeWidth="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5 21.875H17.5"
            stroke={change ? "#fff" : "#3c4c6e"}
            strokeWidth="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_40_255">
            <rect width="28" height="28" fill={change ? "#fff" : "#3c4c6e"} />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default TextSvg;
