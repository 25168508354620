import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSaveBlock,
  setFolderStructure,
  setSelectedFolder,
} from "../../redux/actions/defaultActions";
import ImageSvg from "../svgComponents/ImageSvg.js";
import TextSvg from "../svgComponents/TextSvg.js";
import ViewSvg from "../svgComponents/ViewSvg.js";
import { MoreHoriz } from "@mui/icons-material";
import Popup from "./Popup.js";
import SaveBlockPopup from "./SaveBlockPopup.js";
import ClipSvg from "../svgComponents/ClipSvg";
import EditableText from "../../Componets/Workspace/EditableText.js";
import FlashListSvg from "../svgComponents/FlashListSvg.js";
import FetchSvg from "../svgComponents/FetchSvg.js";
import PressableSvg from "../svgComponents/PressableSvg.js";
import DatePickerSvg from "../svgComponents/DatePickerSvg.js";
import ButtonsSvg from "../svgComponents/ButtonsSvg.js";
import TextFieldSvg from "../svgComponents/TextFieldSvg.js";
import BottomSheetSvg from "../svgComponents/BottomSheetSvg.js";
import TabBarsSvg from "../svgComponents/TabBarsSvg.js";
import EyeView from "../svgComponents/Eyepreview.js";
const determineInitialExpandedState = (folder) => {
  return folder?.customDataType ? false : true;
};

const Folder = ({ folder, hasUniqueId, handleChangeTab, selectedVersion }) => {
  const dispatch = useDispatch();
  const folderType = folder.type;

  const selectedFolder = useSelector((state) => state.default.selectedFolder);
  // const [expanded, setExpanded] = useState(true);
  const [expanded, setExpanded] = useState(() =>
    determineInitialExpandedState(folder)
  );
  const [activeLink, setActiveLink] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveBlockPopupVisible, setIsSaveBlockPopupVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [editText, setEditText] = useState({
    image: "Image",
    text: "Text",
    view: "View",
  });
  const { folderStructure } = useSelector((state) => state.default);
  const hasChildren = folder.children && folder.children.length > 0;

  const handleToggle = (data) => {
    if (
      folder.type !== "image" &&
      folder.type !== "text" &&
      folder.type !== "datepicker" &&
      folder.type !== "button"
      // &&
      // !folder?.customDataType
    ) {
      setExpanded(!expanded);
      setActiveLink(folder.id);
    }
    if (data?.type === "fetch") {
      handleChangeTab("set-3");
    }
    if (data?.type === "pressable") {
      handleChangeTab("set-4");
    }
    if (data?.id !== selectedFolder?.id) {
      dispatch(setSelectedFolder(folder));
    }
    setActiveLink(folder.id);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getIconForType = () => {
    switch (true) {
      case folder.type === "image" && !folder?.customDataType:
        return <ImageSvg className="common_icon" />;
      case folder.type === "text" && !folder?.customDataType:
        return <TextSvg className="common_icon" />;
      case folder.type === "textField" && !folder?.customDataType:
        return <TextFieldSvg className="common_icon" />;
      case folder.type === "view" && !folder?.customDataType:
        return <ViewSvg className="common_icon" />;
      case folder.type === "bottomSheets" && !folder?.customDataType:
        return <BottomSheetSvg className="common_icon" />;
      case folder.type === "fetch" && !folder?.customDataType:
        return <FetchSvg className="common_icon" width="28" height="28" />;
      case folder.type === "pressable" && !folder?.customDataType:
        return <PressableSvg className="common_icon" width="28" height="28" />;
      case folder.type === "flashlist" && !folder?.customDataType:
        return <FlashListSvg className="common_icon" width="28" height="28" />;
      case folder.type === "datepicker" && !folder?.customDataType:
        return <DatePickerSvg className="common_icon" width="28" height="28" />;
      case folder.type === "dropdown" && !folder?.customDataType:
        return <DatePickerSvg className="common_icon" width="28" height="28" />;
      case folder.type === "tabview" && !folder?.customDataType:
        return <TabBarsSvg className="common_icon" width="28" height="28" />;
      case folder.type === "tabviewitem" && !folder?.customDataType:
        return <TabBarsSvg className="common_icon" width="28" height="28" />;
      case folder.type === "button" && !folder?.customDataType:
        return <ButtonsSvg className="common_icon" width="28" height="28" />;
      default:
        return (
          <div className="clip_svg">
            <ClipSvg className="common_icon" />
          </div>
        );
    }
  };

  const handleTogglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
    setIsSaveBlockPopupVisible(false);
  };

  const handleToggleSaveBlockPopup = (e) => {
    setIsSaveBlockPopupVisible(!isSaveBlockPopupVisible);
  };

  const handleSaveCustomBlock = () => {
    handleTogglePopup();
    handleToggleSaveBlockPopup();
  };

  const handleEditCustomblock = () => {
    handleTogglePopup();
    handleToggleSaveBlockPopup();
  };

  const handleSaveBlock = (blockName) => {
    const myProjectId = localStorage.getItem("project_id");
    const data = {
      blocks: [
        {
          customblockname: blockName,
          blockdata: selectedFolder,
        },
      ],
    };

    dispatch(AddSaveBlock(myProjectId, data));
    handleToggleSaveBlockPopup();
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const { v4: uuidv4 } = require("uuid");

  const addSelectedFolderToParent = (folders, selectedFolder) => {
    const recursivelyDuplicateFolder = (folder) => {
      const newFolder = {
        ...folder,
        id: uuidv4(),
        children: (folder.children || []).map((child) =>
          recursivelyDuplicateFolder(child)
        ),
      };
      newFolder.children.forEach((child) => {
        child.parentId = newFolder.id;
      });
      return newFolder;
    };

    return folders?.map((folder) => {
      if (folder.id === selectedFolder.parentId) {
        const duplicatedFolder = recursivelyDuplicateFolder(selectedFolder);
        return {
          ...folder,
          children: [...folder.children, duplicatedFolder],
        };
      }
      if (folder.children && folder.children.length > 0) {
        return {
          ...folder,
          children: addSelectedFolderToParent(folder.children, selectedFolder),
        };
      }
      return folder;
    });
  };

  const onClickHandler = () => {
    const updatedFolderStructure = addSelectedFolderToParent(
      folderStructure,
      selectedFolder
    );
    dispatch(setFolderStructure(updatedFolderStructure));
  };

  // const handleDelete = (folders, idToDelete, selectedFolder) => {
  //   const deleteItem = (folders) => {
  //     for (let i = 0; i < folders.length; i++) {
  //       const folder = folders[i];
  //       if (folder.id === idToDelete) {
  //         folders.splice(i, 1);
  //         return true;
  //       }
  //       if (folder.children && folder.children.length > 0) {
  //         if (deleteItem(folder.children)) {
  //           return true;
  //         }
  //       }
  //     }
  //     return false;
  //   };

  //   const folderDeleted = deleteItem(folders);

  //   if (!folderDeleted) {
  //   }
  //   return folders;
  // };

  // const handleDelete = (folders, idToDelete, selectedFolder) => {
  //   const deleteItem = (folders) => {
  //     for (let i = 0; i < folders.length; i++) {
  //       const folder = folders[i];
  //       if (folder.id === idToDelete) {
  //         folders.splice(i, 1);
  //         return true;
  //       }
  //       if (folder.children && folder.children.length > 0) {
  //         if (deleteItem(folder.children)) {
  //           return true;
  //         }
  //       }
  //     }
  //     return false;
  //   };

  //   const folderDeleted = deleteItem(folders);

  //   if (folderDeleted && selectedFolder) {
  //     const {
  //       flashUniqueId,
  //       flashlistType,
  //       flashlistTypeValue,
  //       type,
  //       parentId,
  //     } = selectedFolder;

  //     if (
  //       flashUniqueId &&
  //       flashlistType &&
  //       flashlistTypeValue &&
  //       type === "view" &&
  //       parentId
  //     ) {
  //       // Find and empty the children of the parent folder
  //       const emptyParentChildren = (folders) => {
  //         for (let i = 0; i < folders.length; i++) {
  //           const folder = folders[i];
  //           if (folder.id === parentId) {
  //             folder.children = [];
  //             return true;
  //           }
  //           if (folder.children && folder.children.length > 0) {
  //             if (emptyParentChildren(folder.children)) {
  //               return true;
  //             }
  //           }
  //         }
  //         return false;
  //       };
  //       emptyParentChildren(folders);
  //     }
  //   }
  //   console.log(folders);
  //   return folders;
  // };

  const handleDelete = (folders, idToDelete, selectedFolder) => {
    // Function to delete all instances of a folder with the specified ID
    const deleteAllInstancesById = (folders, idToDelete) => {
      for (let i = folders.length - 1; i >= 0; i--) {
        const folder = folders[i];
        if (folder.id === idToDelete) {
          folders.splice(i, 1);
        } else if (folder.children && folder.children.length > 0) {
          deleteAllInstancesById(folder.children, idToDelete);
        }
      }
    };

    // Function to delete items by type
    const deleteItemsByType = (folders, typeToDelete) => {
      for (let i = folders.length - 1; i >= 0; i--) {
        const folder = folders[i];
        if (folder.id === typeToDelete) {
          folders.splice(i, 1);
        } else if (folder.children && folder.children.length > 0) {
          deleteItemsByType(folder.children, typeToDelete);
        }
      }
    };

    // Delete all instances of the folder with the specified ID
    deleteAllInstancesById(folders, idToDelete);

    if (selectedFolder) {
      const {
        flashUniqueId,
        flashlistType,
        flashlistTypeValue,
        type,
        parentId,
        id,
      } = selectedFolder;

      if (flashUniqueId && flashlistType && flashlistTypeValue) {
        if (id) {
          localStorage.setItem("crnt_response", "response");
          deleteItemsByType(folders, id);
        }
      }
    }

    return folders;
  };

  const editTextHandleChange = (e) => {
    setEditText({ ...editText, [e.target.name]: e.target.value });
  };

  const updateNameRecursively = (folders, folderId, newName) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, name: newName };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateNameRecursively(
          folder.children,
          folderId,
          newName
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  const handleNameChange = (itemId, newName) => {
    const updatedFolder = { ...selectedFolder, name: newName };
    setSelectedFolder(updatedFolder);

    const updatedFolderStructure = updateNameRecursively(
      folderStructure,
      itemId,
      newName
    );

    if (updatedFolder.name === newName) {
      dispatch(setFolderStructure(updatedFolderStructure));
    } else {
      console.log("Name update failed in the selected folder.");
    }
  };

  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  const listItemRef = useRef();

  const handleScroll = () => {
    const listItemRect = listItemRef.current.getBoundingClientRect();
    const top = listItemRect.top + window.scrollY;
    const left = listItemRect.left + window.scrollX;

    setPopupPosition({ top, left });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        className={`folder ${expanded ? "expanded" : "collapsed"} folder-type-${
          folder.type
        } ${hasChildren ? "has-children" : "no-children"}`}
      >
        <div
          className={`folder-name ${
            selectedFolder?.id === folder?.id
              ? "active_links"
              : "no_active_link"
          }`}
          onClick={() => handleToggle(folder)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ color: "#3C4C6E", fontSize: "12px", fontWeight: "500" }}
          ref={listItemRef}
        >
          <span className="icon">{getIconForType()}</span>
          <div style={{ flexBasis: "100%" }}>
            <EditableText
              key={folder.id}
              init={folder.name}
              name={folder.id}
              editTextHandleChange={editTextHandleChange}
              onSave={(newName) => handleNameChange(folder.id, newName)}
            />
          </div>

          {isHovered && (
            <>
              <div
                className="eye_viewdots"
                style={{
                  flexBasis: "20%",
                  position: "absolute",
                  zIndex: "1024",
                  right: "12px",
                }}
              >
                <div onClick={handleTogglePopup}>
                  <MoreHoriz
                    className="view_dots"
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
                <EyeView />
              </div>
            </>
          )}
          {isPopupVisible && folder.id === selectedFolder?.id && (
            <>
              <Popup
                onClose={handleTogglePopup}
                folderType={folderType}
                onSaveCustomBlock={handleSaveCustomBlock}
                onEditCustomBlock={handleEditCustomblock}
                toggleEditing={toggleEditing}
                hasUniqueId={hasUniqueId}
                onDuplicate={onClickHandler}
                listItemRef={listItemRef}
                popupPosition={popupPosition}
                onDelete={() => {
                  const updatedFolderStructure = handleDelete(
                    folderStructure,
                    folder.id,
                    selectedFolder
                  );
                  dispatch(setFolderStructure(updatedFolderStructure));
                }}
              />
            </>
          )}
          {isSaveBlockPopupVisible && folder.id === selectedFolder?.id && (
            <SaveBlockPopup
              onSave={handleSaveBlock}
              onClose={handleToggleSaveBlockPopup}
              listItemRef={listItemRef}
              popupPosition={popupPosition}
            />
          )}
        </div>
        {expanded &&
          hasChildren &&
          folder.type !== "image" &&
          folder.type !== "text" &&
          folder.type !== "datepicker" &&
          folder.type !== "button" &&
          folder.type !== "dropdown" &&
          folder.type !== "textField" && (
            <div className="dotted-border">
              {folder.children
                .filter((child) => child.flashMap !== false)
                .filter((child) => {
                  if (child.versionAccess) {
                    const versions = child.versionAccess.split("/");
                    return versions.includes(selectedVersion);
                  }
                  return true;
                })
                .map((child, index) => (
                  <div key={`child-${index}`}>
                    <Folder
                      folder={child}
                      uniqueId
                      handleChangeTab={handleChangeTab}
                      selectedVersion={selectedVersion}
                    />
                  </div>
                ))}
            </div>
          )}
      </div>
    </div>
  );
};

const ViewFolder = ({
  folder,
  isDraftPreview,
  handleChangeTab,
  selectedVersion,
}) => {
  const { selectedFolder } = useSelector((state) => state.default);

  const mapFolderStructure = (folders) => {
    return (
      folders &&
      folders?.map((folder) => {
        const { children, ...rest } = folder;
        return {
          ...rest,
          children: children ? mapFolderStructure(children) : [],
        };
      })
    );
  };

  const mappedFolderStructure =
    mapFolderStructure(folder, selectedFolder) || [];
  return (
    <>
      {mappedFolderStructure?.map((rootFolder, index) => (
        <div key={`child-${index}`}>
          <Folder
            folder={rootFolder}
            isDraftPreview={isDraftPreview}
            handleChangeTab={handleChangeTab}
            selectedVersion={selectedVersion}
          />
        </div>
      ))}
    </>
  );
};

export default ViewFolder;
