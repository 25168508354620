import React from "react";

const BottomSheetSvg = ({ change }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2976_115)">
          <path
            d="M23.4375 4.6875H6.5625C5.52697 4.6875 4.6875 5.52697 4.6875 6.5625V36.5625C4.6875 37.598 5.52697 38.4375 6.5625 38.4375H23.4375C24.473 38.4375 25.3125 37.598 25.3125 36.5625V6.5625C25.3125 5.52697 24.473 4.6875 23.4375 4.6875Z"
            fill={change ? "#fff" : "#3c4c6e"}
            stroke="#94A3B8"
          />
          <path
            d="M23.4375 15.9375H6.5625C5.52697 15.9375 4.6875 16.777 4.6875 17.8125V47.8125C4.6875 48.848 5.52697 49.6875 6.5625 49.6875H23.4375C24.473 49.6875 25.3125 48.848 25.3125 47.8125V17.8125C25.3125 16.777 24.473 15.9375 23.4375 15.9375Z"
            fill="#94A3B8"
            stroke="#94A3B8"
          />
        </g>
        <defs>
          <clipPath id="clip0_2976_115">
            <rect width="30" height="30" fill={change ? "#fff" : "#3c4c6e"} />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BottomSheetSvg;
